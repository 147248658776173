import React, { useState, useEffect } from "react";
// import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service"
import "./analytics.scss";
import { Tab, Box, Grid } from "@mui/material";
import { SortingColumnsType } from "../../../../src/components/table-component/table"
import AddAnalytics from "./add_analytics_brand";
import AnalyticsDashboard from './components/analytics-dashboard';
import { MostEngagingPosts } from './components/most-engaging-posts';
import { MostEngagingInfluencer } from './components/most-engaging-influencer';
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import ROI from "./roi";
import DetailedInsightsInfluencer from "./components/detailed-insights-influencer";
import DetailedInsightsPost from "./components/detailed-insights-post";
import dayjs from "dayjs";
import CommonPopUp from "../../../components/popup/common-popup";


interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
  getSubscriptionDetail: any
}

const analyticsService = new AnalyticsService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_plays: "Plays",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  video_plays: "Plays",
  total_likes: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "Post",
    columnSize: "35%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "Influencer",
    columnSize: "35%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];


const DetailedAnalytics: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataIgNumericData, setAnalyticsCumulativeDataIgNumericData] = useState<any>([]);
  const [analyticsCumulativeDataIgMediaData, setAnalyticsCumulativeDataIgMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [creativeData, setCreativeData] = useState()
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [smId, setSmId] = useState(0)
  const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState<any>(0);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState(false);
  const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
  const [buttonClass, setButtonClass] = useState<string>('btn btn-primary disabled');
  const [openModal, setOpenModal] = useState(false);


  // -----------------Open Model-----------------------
  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();

  // -------------
  const [anchorEl, setAnchorEl] = useState(null);

  // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };



  // -----------------------------------




  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  // -----------------------------------------------------------------------------------------------

  const handleOpenModalAnalytics = (campaign_id: number, campaign_platform: string) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);
  }
  const [openModalROI, setOpenModalROI] = useState<boolean>(false);


  useEffect(() => {
    analyticsData();
    getPostLinkData();
  }, []);

  const analyticsData = async () => {
    let campaign_id = props?.selectCampaign?.campaign_id;
    const res: any = await analyticsService.brandAnalytics({ campaign_id: campaign_id, sm_id: null });
    if (res) {
      setAnalyticsCumulativeDataIgMediaData(res.data.analytics_data_ig_media[0])
      if (res?.data?.analytics_data_ig_numeric.length > 0) {
        setAnalyticsCumulativeDataIgNumericData(res.data.analytics_data_ig_numeric[0])
      }
      setPostFilterData(res.data.post_data_content)
      setInfluencerFilterData(res.data.influencer_data_content)
      setGraphData(res.data.graph_data_array)
      setCreativeData(res.data.no_of_creatives)
      setTotalCost(res.data.amount_spent)
      setTotalPost(res.data.total_posts)
    }
  }

  const getPostLinkData = async () => {
    const campaignId = props?.selectCampaign?.campaign_id
    const res = await analyticsService.getAnalyticsLinks(campaignId)

    if (res.data.status) {
      setAddedLinksDetails(res.data.rows)

      //Today Date
      let currentDate = dayjs().format("YYYY-MM-DD HH:mm");
      // let currentDate = "2024-08-09 09:50"
      // Add 72 hours to the refreshed_at timestamp
      let refreshedAtTimestamp = dayjs(addedLinksDetails[0]?.refreshed_at).add(72, 'hour');
      // Format the new timestamp as "YYYY-MM-DD HH:mm"
      const newRefreshTimeStamp = refreshedAtTimestamp.format("YYYY-MM-DD HH:mm");

      if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary')
      }
      else if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 2) {
        setButtonClass('btn btn-primary')
      }
      else if (addedLinksDetails[0]?.refreshed_count === 3 || addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary disabled')
      }
    }
  }

  

  const refreshAnalytics = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id
    const res = await analyticsService.analyticsRefreshInight({ campaign_id })

    if (res.data.status) {
      setOpenModal(true)
    }
    getPostLinkData();
  }
  

  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Plays Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Views',
        data: graphData.map((entry: any) => entry.video_plays),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: graphData.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
  const erOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'ER Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.date),
      format: '{value:.2f}'
    },
    yAxis: {
      title: {
        text: 'Average ER %',
      },

    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Average ER %',
        data: graphData.map((entry: any) => parseFloat(entry.er.toFixed(2))),
      },
    ],
    tooltip: {
      pointFormat: 'Avg ER: {point.y}%',
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };

  const handleOpenUpgradeBrandModal = (value: boolean) => {
    setOpenUpgradeBrandModal(true)
  };

  function handleClosePopUps() {
    setOpenUpgradeBrandModal(false)
    setOpenModal(false)
  }

  return (
    <>
      {openModalAddPost && (
        <AddAnalytics open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          campaign_id={campaignId}
          post_platform={campaignPlatform}
          sm_id={smId}
          totalPost={totalPost}
          getSubscriptionDetail={props.getSubscriptionDetail}
          handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          campaignType={props.selectCampaign.campaign_type}
        />
      )}
      {openModal && (
        <CommonPopUp
          modalTitle={''}
          modalContent=""
          openModal={openModal}
          onClose={handleClosePopUps}
          className="modal-medium mx-auto"
        >
          <div className="">
            <div>
              <p>It takes a few minutes to get the data.Till we do the task, 
              sit back and relax or just have a cup of tea.</p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                    <a className="btn btn-primary ms-2 btn-mui" onClick={handleClosePopUps}>
                      Ok
                    </a>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
      <div className="brand-analytics detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className="row project-detail-sec">
              <div className="col-md-12">

                <div className="button-div">
                  <span className="btn btn-outline-primary add"
                    onClick={(e) => {
                      handleOpenModalAnalytics(props.selectCampaign.campaign_id, props.selectCampaign.campaign_platform)
                    }} >
                    Add Post
                  </span>

                  {props.getSubscriptionDetail && props.getSubscriptionDetail.plan_id === 9 && (
                    <button className={buttonClass} onClick={refreshAnalytics}>
                      Refresh Insight
                    </button>
                  )}

                  <a href="#" className="" onClick={() => setOpenModalROI(true)}> ROI Generator</a>
                </div>
                <div className="upper-grid">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                      <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataIgMediaData} campaign_platform={props?.selectCampaign?.campaign_platform} analyticsCumulativeDataNumericData={analyticsCumulativeDataIgNumericData} totalCost={totalCost} totalPost={totalPost} />
                    </Grid>
                  </Box>
                </div>
              </div>
            </div>
          </div>

        </div>
        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">
                    <TabContext value={value}>

                      <TabList className='nav sub-tabs'
                        onChange={handleChange}
                      >
                        <Tab
                          className='nav-link'
                          label={`Total Views`}
                          value="1"
                          icon={<span className="material-symbols-rounded">play_arrow</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`ER`}
                          value="4"
                          icon={<span className="material-symbols-rounded">percent</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                      </TabList>

                      <TabPanel value="1" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />

                      </TabPanel>
                      <TabPanel value="2" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel value="3" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                      <TabPanel value="4" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={erOptions} />
                      </TabPanel>
                    </TabContext>

                  </div>

                  <>
                    <div className='d-flex flex-column'>
                      <strong className='me-auto mb-1'>Most Engaging Posts</strong>
                      <>
                        {(() => {
                          const sortedData = [...postFilterData].sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
                          return (<MostEngagingPosts sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                          );
                        })()}
                      </>
                      {/* )} */}
                    </div>
                    {(
                      <div className="analytics-most-engaging">
                        <div className='d-flex flex-column'>
                          <strong className='mb-1'>Most Engaging Influencers</strong>
                          {(() => {
                            const sortedData = influencerFilterData.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                            return <MostEngagingInfluencer sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                          })()}

                        </div>

                      </div>
                    )}
                  </>

                  <div className="toggle-button-div2">
                    <div className="d-flex flex-wrap">
                      <strong>Detailed Insights</strong>
                    </div>
                    <TabContext value={value2}>

                      <TabPanel value="1" className='px-0'>
                        <div className="toggle-button-div3">
                          <TabContext value={value4}>
                            <>
                              <TabList
                                onChange={handleChange4}
                                className="filter-tab-list nav sub-tabs mb-3"
                              >
                                <Tab className='nav-link'
                                  label={`Posts`}
                                  value="1"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                                <Tab className='nav-link'
                                  label={`Influencers`}
                                  value="2"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                              </TabList>
                            </>
                            <TabPanel value="1" className='px-0 pt-0'>
                              <div className="post-table">
                                <>
                                  <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                    orderByColumnName=""
                                    inputDataSource={postFilterData}
                                    sortingColumnType={PostDetailedData}
                                    renderData={postFilterData}
                                    setRenderData={(e: any) => setPostFilterData(e)}
                                    selectCampaign={props?.selectCampaign} />
                                </>

                              </div>
                            </TabPanel>
                            <TabPanel value="2" className='px-0 pt-0'>
                              <div className="influencer-table">

                                <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                  orderByColumnName=""
                                  inputDataSource={influencerFilterData}
                                  sortingColumnType={InfluencerDetailedData}
                                  renderData={influencerFilterData}
                                  setRenderData={(e: any) => setInfluencerFilterData(e)}
                                  selectCampaign={props?.selectCampaign} />
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </TabPanel>
                    </TabContext>

                  </div >
                </>
              )
            }

          })()

        }
        {openUpgradeBrandModal && (
          <UpgradeBrandPopUp
            openUpgradeBrandModal={openUpgradeBrandModal}
            onClose={() => handleClosePopUps()}
            popupMinWidth='630px'
          >
          </UpgradeBrandPopUp>
        )}
      </div >
      {openModalROI && <ROI open={openModalROI} likes={analyticsCumulativeDataIgNumericData.total_likes} er={analyticsCumulativeDataIgNumericData.total_er} plays={analyticsCumulativeDataIgNumericData.total_plays} views={0} comments={0} user_id={props.UserReducer.user_id} account_id={props.UserReducer.account_id} brand_id={props.selectCampaign.brand_id} influencer_id={props.UserReducer.influencer_id} campaign_id={props.selectCampaign.campaign_id} platform={"Instagram"} onClose={() => setOpenModalROI(false)} />}
    </>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedAnalytics);

export { connectToRedux as DetailedAnalytics };
