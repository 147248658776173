import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import { ResponseType } from 'custom-type';


const http = new HttpService();

export class AgreementService {
    // async influencerAgreement(data: any): Promise<any> {
    //     debugger
    //     try {
    //         const path = BASE_URL + "/influencer/agreement";
    //         const response = await http.postRequest(path, data, 'chooseFile');
    //         return response;
    //     } catch (error) {
    //         throw new Error(`Influencer Agreement Service  :: ${error}`);
    //     }
    // }

    async getAgreementData(data: any): Promise<ResponseType> {
        const path = BASE_URL + "/influencer/agreement/data";
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getAgreementData Service :: ${error}`);
        }
    }

    async getAgreementDetails(data: any): Promise<ResponseType> {
        const path = BASE_URL + "/influencer/agreement/details";
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getAgreementDetails Service :: ${error}`);
        }
    }

    async influencerInsert(data: any) {
        const path = BASE_URL + "/influencer/agreement/insert";
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Influencer Insert Service  :: ${error}`);
        }
    }

    async influencerAgreement(data: any): Promise<ResponseType> {
        const path = BASE_URL + "/influencer/agreement";
        try {
            const response = await http.postRequest(path, data, 'file');
            return response;
        } catch (error) {
            throw new Error(`Influencer Agreement Service  :: ${error}`);
        }
    }

    async signedAgreementInsert(data: any) {
        const path = BASE_URL + "/influencer/agreement/signed/insert";
        try {
            const response = await http.postRequest(path, data, 'file');
            return response;
        } catch (error) {
            throw new Error(`signedAgreementInsert Service  :: ${error}`);
        }
    }

    async getSignedAgreementDetails(data: any): Promise<ResponseType> {
        const path = BASE_URL + "/influencer/agreement/signed/details";
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getSignedAgreementDetails Service :: ${error}`);
        }
    }

    async getCampaignAllAGreement(data: any): Promise<ResponseType> {
        const path = BASE_URL + "/agreement/home";
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getCampaignAllAGreement Service :: ${error}`);
        }
    }
}