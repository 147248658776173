import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { createPortal } from 'react-dom';
import {useNavigate } from 'react-router-dom';
import CommonPopUp from '../../../components/popup/common-popup';
import { Select, Radio, RadioGroup, FormControl, FormControlLabel, MenuItem, Button, InputLabel } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { NavLink } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CreateCampaign } from "../../../redux/action";
import { createCampaignInitialState } from "../../../redux/reducer/reducer";
import {
  Tabs,
  Tab,
  ToggleButtonGroup,
  ToggleButton,
  tabsClasses,
  Grid,
} from '@mui/material';

import { CommonSection } from '../../../components/common-section/common-section';
import { Methods } from '../../../core/utility';
import { Loading } from '../../../redux/action';
import { DiscoverService, LocalService, ListService } from '../../../core/services';
import { LineChartDropDown } from '../../../components/charts/line-chart';
import { Platforms } from '../../../core/enums';
import TopInfluencer from './top-influencer';
import { BrandDashboardService } from '../../../core/services/dashboard-brand.service';
import { CommonService } from '../../../core/services/common.service';
import { AnalyticsCampaignModal } from '../plans/campaings/internal-campaigns/analytics-campaign-modal';
import './dashboard.scss';
import HeadingStyleSecondary from '../../../components/heading-style/heading-style-secondary';
import IconYouTube from '../../../components/icons/icon-youtube';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';
import UpgradeBrandPopUp from '../../../components/popup/upgrade-brand-popup';
import { defaultChartData, defaultCampaign, defaultEmptyChartData } from './default_chart_data';
import { MenuPropsSelect } from '../../../core/utility/style';
// to update userReducer
import { UserLogin } from '../../../redux/action';
import { UserService } from '../../../core/services';

import ShowWelcomePopUp from '../../../components/popup/show-welcome';
const discoverService: DiscoverService = new DiscoverService();
const localService: LocalService = new LocalService();
const methods = new Methods()
const brandDashboardService = new BrandDashboardService();
const listService: ListService = new ListService();
const commonService:CommonService = new CommonService();
const userService: UserService = new UserService();

interface CardBody {
  title: string;
  url?: string;
}

const cardBodies: CardBody[] = [
  {
    title: 'Discover',
    url: '/brand/discover',
  },
  {
    title: 'Post a Campaign',
    url: '/brand/campaign/createplan',
  },
  {
    title: 'View all Plans',
    url: '/brand/plans',
  },
  {
    title: `Track Campaign`,
  },
];

type ClickedType = {
  campaign_id: number;
  proposals: number;
  creatives: number;
  likes: number;
  comments: number;
  plays: number;
  views?: number;
  ER: number;
}

const initialCampaign: ClickedType = {
  campaign_id: 0,
  proposals: 0,
  creatives: 0,
  likes: 0,
  comments: 0,
  plays: 0,
  ER: 0,
}

const monthArray = [
  { _id: 1, month: '1 month', value: 1 },
  { _id: 2, month: '3 months', value: 3 },
  { _id: 3, month: '6 months', value: 6 },
  { _id: 4, month: '1 year', value: 12 },
];

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  CreateCampaignReducer?: (data: any) => void;
  updateUser: any;
}

const BrandDashboard: React.FC<Props> = (props: Props) => {
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [topInfluencerData, setTopInfluencerData] = useState<any>([]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState<any>({ start_date: dayjs().subtract(12, 'month').format('YYYY-MM-DD HH:mm:ss'), end_date: dayjs().format('YYYY-MM-DD HH:mm:ss') });
  const [clickedData, setClickedData] = useState<ClickedType | null>(initialCampaign);
  const [stockChartData, setStockChartData] = useState<any>([]);
  const [platFormType, setPlatFormType] = useState<string>('Instagram');
  const [campaignId, setCampaignId] = useState(0);
  const [alignment, setAlignment] = React.useState(12);
  const [campaingNames, setCampaignNames] = useState<any>([]);
  const [chartCategories, setChartCategories] = useState<any>([]);
  const [monthRange, setMonthRange] = useState(12);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [radiovalue, setRadioValue] = useState(1);
  const [showPlanNeed, setShowPlanNeed] = useState(false)
  const [planList, setPlanList] = useState<any>([]);
  const [createSelectedPlan, setCreateSelectedPlan] = useState("");
  const [defaultChart, setDefaultChart] = useState("")
  const [emptyChart, setEmptyChart] = useState(false);
  const [planId, setPlanId] = useState<number>();
  const [showWelcomePopUp, setShowWelcomePopUp] = useState(false)
  const navigate = useNavigate()

  const brand_id = props.UserReducer?.brand_id;

  const user_id = props.UserReducer?.user_id;

  // For handling Campaign Tab
  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };
  // For Opening Modal for Campaign Type Analytics
  const handleOpenCampainModal = (): void => {
    setShowCampaignModal(true);
  }
  
  // For Geting Top Influencers Data
  const getTopInfluencerData = async () => {
    try {
      const response = await brandDashboardService.brandDashboardTopInfluencerData(brand_id);
      if (response.status === 1) {
        setTopInfluencerData(response.data);
      }
    } catch (error: any) {
      throw new Error('getTopInfluencerData', error);
    }
  }
  

  const showWelcomePopUpCount = async()=>{
    try {
      const response = await commonService.checkPopup({user_id: props.UserReducer?.user_id, account_id: props.UserReducer?.account_id, popup_name: 'new_platform_welcome'});
      if (response.data.status) {
        setShowWelcomePopUp(true)
      }
    } catch (error: any) {
      throw new Error('getTopInfluencerData', error);
    }
  }

  async function updateUserReducer() {
    let latestUserReducerData = await userService.getUpdatedUserReducer(props.UserReducer);
    if (![undefined, null, ''].includes(latestUserReducerData) && latestUserReducerData.status === 'Blocked' ){
      localService.logout()
    }
    else if (![undefined, null, ''].includes(latestUserReducerData)) {
      props.updateUser(latestUserReducerData)
    }
  }

  useEffect(() => {
    updateUserReducer();
    showWelcomePopUpCount();
    getTopInfluencerData();
    getBrandDashboard();
    getBrandCampaignNames();
  }, [brand_id, selectedDateRange, platFormType])

  useEffect(() => {
    if (campaignId || campaignId===0) {
      getBrandDashboardInsight()
    }
  }, [campaignId]);

  useEffect(() => {
    handleDateRangeChange()
  }, [monthRange])

  useEffect(() => {
    props.loading(true);
    try {
      listService.planList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      })
        .then((res: any) => {
          props.loading(false);
          try {
            if (res.data.fetchedRows > 0) {
              setPlanList(res.data.rows);
              setRadioValue(2);
              setCreateSelectedPlan(res.data.rows[0].plan_id);
            } else {
            }
          } catch (err) {
            throw new Error(`Data Align Error :: ${err}`);
          }
        })
        .catch((e) => {
          props.loading(false);
          throw new Error(`PlanService planList function error :: ${e}`);
        });
    } catch (error) {
      throw new Error(`Plan list error :: ${JSON.stringify(error)}`);
    } finally {
    }
  }, [props.UserReducer.brand_id, props.UserReducer.user_id]);


  async function getBrandDashboardInsight() {
    try {
      const response = await brandDashboardService.brandDashboardCamapignInsight(campaignId, platFormType);
      if (response.status === 1) {
        GetChartData(campaignId === 0 ? defaultChartData:(response?.data?.chart_values.length > 0 ? response?.data?.chart_values:defaultEmptyChartData ))
        setEmptyChart(true)
        setClickedData({
          campaign_id: campaignId,
          proposals: response?.data?.campaign_insight?.accepted_proposal_count,
          creatives: response?.data?.campaign_insight?.accepted_creative_count,
          likes: response?.data?.campaign_insight?.total_likes,
          comments: response?.data?.campaign_insight?.total_comments,
          plays: response?.data?.campaign_insight?.total_plays,
          ER: response?.data?.campaign_insight?.er,
          views: response?.data?.campaign_insight?.total_views,
        });
      }
    } catch (err) {
      console.log('getBrandDashboardInsight', err)
    }
  }

  const handleAlignment = (event: any, newAlignment: any) => {
    setAlignment(newAlignment);
  };

  const GetChartData = (data: any) => {
    try {
      if (data.length > 0) {
        const sortDate = data?.slice().sort((a: any, b: any) => {
          const dateA: any = new Date(a.DATE);
          const dateB: any = new Date(b.DATE);
          return dateA - dateB;
        });
        const labels = sortDate.map((item: any) => item.DATE);
        const datasets = Object.keys(data[0])
          .filter((key: any) => key !== 'DATE' && key !== 'earliest_created_at')
          .map(key => ({
            label: key === 'er' ? "Engagement Rate" : key?.replace(/_/g, ' ').toLowerCase().split(' ').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            data: data.map((item: any) => item[key])
          }));
        const finalData = datasets.map((dataset: any) => ({
          name: dataset.label,
          data: dataset.data,
        }));
        setChartCategories(labels);
        setStockChartData(finalData);
      } else {
        setChartCategories([]);
        setStockChartData([]);
      }
    } catch (err) {

    }
  }

  async function getBrandDashboard() {
    try {
      const response = await brandDashboardService.getbrandDashboard(
        brand_id, selectedDateRange.start_date, selectedDateRange.end_date, platFormType
      );
      if (response.status === 1) {
        setDashboardData(response?.data?.rows[0]);
      }
    } catch (error) {
      console.log(`getBrandDashboard error :: ${error}`);
    }
  }

  const getBrandCampaignNames = async () => {
    try {
      const response = await brandDashboardService.brandDashboardCamapignNames(brand_id, platFormType);
      if (response.data.status === 1) {
        response?.data?.rows?.unshift(defaultCampaign);
        // Directly using the modified array
        setCampaignNames(response?.data?.rows);
        setCampaignId(response?.data?.rows[0]?.campaign_id);
      }else{
        setCampaignNames([defaultCampaign]);
        setCampaignId(0);
      }
    } catch (err) {
      console.log(`getBrandCampaignNames ::: ${err}`)
    }
}


  const handleDateRangeChange = () => {
    setSelectedDateRange((prevData: any) => ({
      ...prevData,
      start_date: dayjs().subtract(monthRange, 'month').format('YYYY-MM-DD HH:mm:ss'),
      end_date: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }))
  }

  const handleNavigate = (id: number | string, platform_type: string) => {
    if (platform_type === 'instagram') {
      window.open(`/brand/profile-insight?sm_id=${id}`, "_blank");
    } else {
      window.open(`/brand/profile-insight-yt?sm_id=${id}`, "_blank");
    }
  };

  async function handleProfileView(event: any, platform_type: string, sm_id: number) {
    try {
      props.loading(true);
      const response = await discoverService.canViewProfile({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        sm_id: sm_id
      })
      if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
        handleNavigate(sm_id, platform_type);
      }
      else if (!response.data.status && response.data.message.includes('upgrade')) {
        localService.toastify(response.data.message, 'info', 1000);
      }
      else if (!response.data.status) {
        localService.toastify(response.data.message, 'error', 1000);
      }
    } catch (error) {
      console.log(`getFilteredInfluencerCountYT error :: ${JSON.stringify(error)}`);
    } finally {
      props.loading(false);
    }
  };

  const handlePlatformType = (e: any) => {
    const value = e.target.value
    setPlatFormType(value);
  }

  
  const onCloseSelectedPlan = () => {
    setShowPopup(!showPopup)
    setCreateSelectedPlan(planList[0]?.plan_id);
  }

  const handlePlanNeed = () => {
    setShowPlanNeed(prev => !prev)
  }

  const handleContinue = () => {
    if (radiovalue === 2) {
      //get Plan details
      if ((createSelectedPlan + "").length > 0) {
        handleAddCampaign(createSelectedPlan);
      } else {
        // toast.info("Please Select Plan.");
        localService.toastify("Please select plan", "info", 1000);
      }
    } else {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer(createCampaignInitialState);
      }
      navigate("/brand/campaign/createplan");
    }
  };

  const handleAddCampaign = (plan_id: any) => {
    listService.add_Campaign({
      brand_id: props.UserReducer.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: plan_id,
    }).then((res: any) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_id: res?.data?.status.campaign_id,
          },
          createPlan: res?.data?.rows[0],
          navigation: {
            ...createCampaignInitialState.navigation,
            campaign_create: true,
          },
          completeStep: {
            ...createCampaignInitialState.completeStep,
            createPlan: true,
          },
        });

        navigate("/brand/campaign/createcampaign");
      }
    });
  };

 
  return (
    <section className='dashboard-container'>
      <div className='container  card-main d-flex'>
        <div className='ag-format-container'>
          <div className='ag-courses_box'>
            {cardBodies.map((CardBody, index) => (
              <div className='ag-courses_item' key={index}>

                {CardBody.url ? (
                  CardBody.url === '/brand/campaign/createplan' ?
                  <div className='ag-courses-item_link' onClick={() => setShowPopup(!showPopup)}>
                    <div className='ag-courses-item_bg'></div>
                    <div className='ag-courses-item_title'>{CardBody.title}</div>
                  </div> 
                  :
                  <NavLink to={CardBody.url} className='ag-courses-item_link'>
                    <div className='ag-courses-item_bg'></div>
                    <div className='ag-courses-item_title'>{CardBody.title}</div>
                  </NavLink>
                ) : (
                 <div className='ag-courses-item_link' onClick={handleOpenCampainModal}>
                    <div className='ag-courses-item_bg'></div>
                    <div className='ag-courses-item_title'>{CardBody.title}</div>

                  </div>
                )}
              </div>

            ))}
            {showCampaignModal && (
              <AnalyticsCampaignModal
                {...props}
                onChange={(event) => setShowCampaignModal(event)}
              />)}
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-flex flex-wrap'>
            <fieldset id="platfrom_radio_id" className="input-checkbox-box-container"
              onChange={(e: any) => handlePlatformType(e)}
            >
              {Platforms.map((data, index) =>
                <Fragment key={index}>
                  <input name="platfrom" value={data.label} id={`id_radio_platform_${index}`} type="radio" defaultChecked={data.label === 'Instagram'} />
                  <label className='input-checkbox-box btn btn-outline-primary btn-rounded' htmlFor={`id_radio_platform_${index}`} >
                    {data.value === 'instagram' && <InstagramIcon1  width={20}/>}
                    {data.value === 'youtube' && <IconYouTube width={25}/>}
                    <span>{data.label}</span>
                  </label>
                </Fragment>
              )}
            </fieldset>
            <CommonSection title='Overview' actionType='section-w-left-right'>
            </CommonSection>
            <div className='camp-duration-data d-flex justify-content-between w-100'>
              <div className='d-flex'>
                <div className='camp-duration-range'>
                  <ToggleButtonGroup
                    className='toggle'
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label='text alignment'
                    size='small'
                  >
                    {monthArray?.map((month: any) => {
                      return (
                        <ToggleButton key={month._id} value={month.value} onClick={(e: any) => setMonthRange(e.target.value)}>
                          {month.month}
                        </ToggleButton>
                      )
                    })}
                  </ToggleButtonGroup>
                </div>
                <div className='camp-duration-date'>
                  <Grid container columnSpacing={2}>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          className=' input-style-date'
                          label='Start Date'
                          format='DD-MM-YYYY'
                          value={dayjs(selectedDateRange.start_date)
                          }
                          onChange={(e: any) => setSelectedDateRange((prevData: any) => ({
                            ...prevData,
                            start_date: e.format('YYYY-MM-DD HH:mm:ss')
                          }))}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          className=' input-style-date'
                          label='End Date'
                          format='DD-MM-YYYY'
                          value={dayjs(selectedDateRange.end_date)}
                          onChange={(e: any) => setSelectedDateRange((prevData: any) => ({
                            ...prevData,
                            end_date: e.format('YYYY-MM-DD HH:mm:ss')
                          }))}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <span>
                Amount Spent <strong>₹{methods.formatNumberINR(dashboardData?.total_spent || 0) || 0}</strong>
              </span>
            </div>
          </div>
        </div>
        <div className='brand-dashboard-card'>
          <div className='dashboard-insight mb-3'>
            <Grid container spacing={2} columns={14}>
              {dashboardData &&
                Object.entries(dashboardData).map(([key, value]: any, index: any) => {
                  const lastUnderscoreIndex = key.lastIndexOf('_');
                  const modifiedKey = lastUnderscoreIndex !== -1 ? key.substring(lastUnderscoreIndex + 1) : key;
                  if (key === 'total_spent' || key === 'url_ids' || key === 'total_posts' || key === 'total_with_views' || key === 'total_without_views') {
                    return null;
                  }
                  return (
                    <Grid item xs={12} md={4} lg={2} key={index}>
                      <div className='dashboard-insight-card'>
                        <span>{methods.convertNumber(+value) || 'NA'}{modifiedKey === 'ER' ? '%' : ''}</span>
                        <br />
                        <p>
                          {modifiedKey}
                        </p>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
        <div className='sub-section-2 mb-3'>
          <HeadingStyleSecondary title="All Campaigns" className='fs-20 d-block' />
          <Tabs
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            className='mb-3 nav sub-tabs nav-rounded'
            TabIndicatorProps={{ hidden: true }}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.MuiTabScrollButton-root': {
                  borderRadius: '20px',
                  height: '24px',
                  border: '1px solid #000',
                  width: '24px',
                  transition: 'box-shadow 0.3s, transform cubic-bezier(0.2,0,0,1)',
                  position: 'relative',
                  zIndex: 1,
                  '&:hover': {
                    boxShadow: '0 0 5px #ddd',
                    transform: 'scale(1.04)'
                  },
                },
              },
            }}
          >

            {campaingNames.length > 0 && campaingNames?.map((data: any, index: any) => (
              <Tab
                  key={index}
                  label={<>{(data?.campaign_title.length > 30) ? (
                    <div className='scrolling-text-sec'>
                      <div className='inner-scrolling-text-container'>
                        <span className="inner-scrolling-text camp-title">
                          {data?.campaign_title}
                        </span>
                      </div>
                    </div>
                    
                  )
                    : <span className="inner-scrolling-text camp-title">
                      {data?.campaign_title}
                    </span>
                  }
                  </>}
                  onClick={() =>
                    {setCampaignId(
                      data.campaign_id
                    );setPlanId(data.plan_id)}
                  }
                  className={`${selectedTab === index ? 'nav-link active' : "nav-link"}`}
                />
              ))}
          </Tabs>
          <div className='card-main mb-3'>
            <Grid container spacing={2} columns={12}>
              {clickedData &&
                Object.entries(clickedData).map(([key, value], index) => {
                  if (key !== 'campaign_id') {
                    const lastUnderscoreIndex = key.lastIndexOf('_');
                    const modifiedKey = lastUnderscoreIndex !== -1 ? key.substring(lastUnderscoreIndex + 1) : key;

                    if (key === 'views' && platFormType === 'Instagram') {
                      return null
                    }
                    if (key === 'plays' && platFormType === 'Youtube') {
                      return null
                    }

                    return (
                      <Grid item xs={12} md={4} lg={2} key={index}>
                        <div className='run-camp-card d-flex bg-f2f2f2'>
                          <p>

                            {modifiedKey}
                          </p>
                          <span>{(modifiedKey === 'ER' ? (value ? `${value} %` : 'NA') : methods.convertNumber(+value)) || 'NA'}</span>
                        </div>
                      </Grid>
                    );
                  }
                  return null;
                })}
            </Grid>

          </div>


          <LineChartDropDown dataSource={stockChartData} categories={chartCategories} dropdown={true} exporting={false} emptyChart={emptyChart} planId={planId} campaignId={campaignId}chartType='spline' />
        </div>
        <CommonSection className='mb-3 d-block' title='Top Performing Influencers' actionType='' />
        {platFormType === 'Instagram' ?
          (
            <TopInfluencer data={topInfluencerData.length > 0 ? topInfluencerData?.insta_influencer?.sort((a: any, b: any) => (a.er > b.er) ? 1 : -1):topInfluencerData} type='Instagram' handleProfileView={handleProfileView} />
          )
          :
          (
            <TopInfluencer data={topInfluencerData.length > 0 ? topInfluencerData?.yt_influencer?.sort((a: any, b: any) => (a.er_without_view > b.er_without_view) ? 1 : -1):topInfluencerData} type='Youtube' handleProfileView={handleProfileView} />
          )
        }
      </div>
      {showPopup && (
           createPortal(<CommonPopUp
            modalTitle="Create campaign"
            modalContent=""
            openModal={showPopup}
            onClose={() => onCloseSelectedPlan()}
            className="popup-select-plan modal-medium">
            <div className="create_new_campaign_popup">
              <div className="row">
                <div className="col">
                  <span className="select_plan_campaign fw-600">
                    Select a plan for the Campaign
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <FormControl size="small">
                    <RadioGroup
                      onChange={(e: any) => setRadioValue(+e.target.value)}
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={radiovalue}
                      name="radio-buttons-group"
                    >
                      <div className='d-flex flex-wrap'>
                        <FormControlLabel
                          value={1}
                          checked={radiovalue === 1}

                          control={<Radio />}
                          label="New Plan"
                        />
                        <span className='btn btn-text-link align-self-center fs-12' onClick={handlePlanNeed} style={{ cursor: 'pointer' }}>Why is a plan needed?</span>
                        {showPlanNeed && (
                          <p className='fs-14'>Brands can create plans for their diverse products/services, each aiming to achieve different objectives such as driving awareness, performance, or engagement.</p>
                        )}
                      </div>

                      <FormControlLabel
                        value={2}
                        disabled={planList.length < 1}
                        control={<Radio />}
                        defaultChecked={planList.length > 0 && radiovalue === 2}
                        checked={planList.length > 0 && radiovalue === 2}
                        label="Existing Plan"
                      />

                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {(planList.length > 0 && (radiovalue === 2)) &&
                <div className="row mb-3">
                  <div className="col">
                    <FormControl
                      // disabled={(radiovalue !== 2 && (planList.length < 1)) ? true : false}
                      fullWidth
                      size="small"
                      className="pln_popup_select input-common input-style-select"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createSelectedPlan}
                        label="Select Plan"
                        onChange={(e) => setCreateSelectedPlan(e.target.value)}
                        inputProps={{
                          MenuProps: {
                            disableScrollLock: true,
                          },
                        }}
                        MenuProps={MenuPropsSelect}
                      >
                        {planList.length > 0 ? (
                          planList.map((res: any, index: number) => {
                            return (
                              <MenuItem key={index} value={res.plan_id}>
                                {res.name}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem key={1} value={1}>
                            {"No Data"}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
              <div className="col text-end plan_camp_btn_group popup-footer">
                <Button
                  className="btn btn-outline-primary btn-mui"
                  onClick={() => onCloseSelectedPlan()}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary btn-mui ms-2"
                  onClick={handleContinue}
                  variant="contained"
                >
                  Continue
                </Button>
              </div>
            </div>
          </CommonPopUp>, document.body)

      )}
      {showWelcomePopUp && (
            <ShowWelcomePopUp
            openUpgradeBrandModal={showWelcomePopUp}
            onClose={() => setShowWelcomePopUp(false)}
            popupMinWidth='630px'
          />

      )}
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    campaignReducer: state.CreateCampaignReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
    updateUser: (data: any) => dispatch(UserLogin(data)),
  };
};
const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandDashboard);
export { connectToRedux as BrandDashboard };
