import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../../../../../redux/action";
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, useMediaQuery } from "@mui/material";
import {
  ApplyCampaignService,
  LocalService,
} from "../../../../../../core/services";
import "./creatives.scss";
import { DeliverableTool, Profile } from "../proposals/proposal-components";
import ContentLoading from "../../../../../../components/loader/loading";
import CreativeContainer from "./creative-container";
import { createPortal } from "react-dom";
import eventEmitter from "../../../../../../core/services/event-emitter";
import GenericSwipeableDrawer from "../../../../../../components/generic-swipe-drawer/generic-swiper-drawer";
import MaterialSymbol from "../../../../../../components/icons/material-symbol";
import dayjs from "dayjs";
import { DummyCampaignImage } from "../../../../../../config/config";

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  selectCampaign: any;

}

interface Influencer {
  influencer_id: number;
  account_id: number;
  username: string;
  ig_id: number;
  sm_id: number;
  proposal_id: number;
}


type CommentTypes = { comments: string, from: string, created_at: string, status: string, influencer_id: string | number, account_id: string | number };

const localService = new LocalService();
const applyCampaignFlow = new ApplyCampaignService();

const BrandCreatives: React.FC<Props> = (props: Props) => {
  const [activeInfluencerDetail, setActiveInfluencerDetail] =
    useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [commentsList, setCommentsList] = useState<CommentTypes[]>([]);
  const [creatives, setCreatives] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false)
  const [openApprovedCreativeModal, setOpenApprovedCreativeModal] = useState(false)
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);

  const [creativeId, setCreativeId] = useState<string | null | undefined>(undefined);
  const [proposalId, setProposalId] = useState<string | null | undefined>(undefined)
  const [receiverAccountId, setReceiverAccountId] = useState(0);
  const [receiverInfluencerId, setRecieverInfluencerId] = useState(0);
  const [approvedCreative, setApprovedCreative] = useState<any>([])
  const [openAcceptPopup, setOpenAcceptPopup] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const searchParams = useSearchParams();
  const [totalCreative, setTotalCreative] = useState<any>([])
  const [influencerDetail, setInfluencerDetail] = useState<any>([])
  const [getCreativeAfterAction, setGetCreativeAfterAction] = useState(false)

  const [expanded, setExpanded] = useState<number | false>(false);
  const [originalInfluencers, setOriginalInfluencers] = useState<Influencer[]>([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState<Influencer[]>([]);
  const [unreadCreative, setUnreadCreative] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiStatus, setApiStatus] = useState<any>(null);
  const [resDataAvailable, setResDataAvailable] = useState<boolean>(false);

  const [FilteredProfile, setFilteredProfile] = useState<Influencer[]>([]);
  const [filterCreative, setFilterCreative] = useState<any>({})

  const [panel_SMID, setPanel_SMID] = useState({
    panel:0,
    sm_id: 0,
    proposal_id:0
  });

  const campaignDetails = useMemo(() => {
    return {
      campaignId: searchParams[0].get("campaignID"),
      planId: searchParams[0].get("planID"),
      platformType: searchParams[0].get("Platform"),
    };
  }, []);
  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: `${campaignDetails?.campaignId}` });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };

  const [influencers, setInfluencers] = useState({
    Nano: [],
    Micro: [],
    "Micro Plus": [],
    Macro: [],
    "Macro Plus": [],
    Mega: [],
    "Mega Plus": [],
    All: [],
  });

  useEffect(() => {

  }, [
    props.UserReducer.user_id,
    props.UserReducer.account_id,
    activeInfluencerDetail
  ]);
  useEffect(() => {
    getBrandCreativeComments();
    getTotalCreative();
  }, [openModal, filterCreative]);
  // const [allCreatives, setAllCreatives] = useState(null)

  async function creativeInfList() {
    props.loading(true)
    const res = await applyCampaignFlow.creativeInfList({
      campaign_id: campaignDetails.campaignId,
    })

    try {
      if (res.data.status.status && res?.data?.rows?.length > 0) {
        props.loading(false)
        if (res?.data?.rows?.length > 0) {
          let inf: any = {
            Nano: [],
            Micro: [],
            'Micro Plus': [],
            Macro: [],
            'Macro Plus': [],
            Mega: [],
            'Mega Plus': [],
            All: res.data.rows,
          };
          res.data.rows.forEach((res: any) => {
            if (inf[res.infType]) {
              inf[res.infType].push(res);
            }
          });
          setInfluencers(inf);
          setApiStatus(null);
          setFilteredInfluencers(res.data.rows)
          setOriginalInfluencers(res.data.rows);
          setResDataAvailable(true)
        }
      }
      else {
        props.loading(false);
        // localService.toastify("No Creatives Present");
      }
    }
    catch (error) {
      props.loading(false)
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }
  useEffect(() => {
    creativeInfList();
    getDeliverableHeader();
    getUnreadCreatives();
  }, [props]);



  const handleAcceptReject = async (data: any, action: string) => {
    try {
      setLoading(true);
      const response = await applyCampaignFlow.brandCreativeAcceptReject({
        action: action,
        creative_id: data.creative_id,
      });
      setGetCreativeAfterAction(!getCreativeAfterAction);
      getTotalCreative();
      setOpenModal(false)
      if (action === 'Rejected') {
        setOpenRejectPopup(false);
        getTotalCreative();
        document
          .getElementsByTagName('body')[0]
          .classList.remove('custom-drawer-active');
      }
      if (action === 'Approved') {
        setOpenAcceptPopup(false);
        document
          .getElementsByTagName('body')[0]
          .classList.remove('custom-drawer-active');
      }
      getbrandCreatives(panel_SMID?.panel, panel_SMID?.sm_id, panel_SMID?.proposal_id)
      const bodyClass = document.getElementsByTagName('html')[0];
      if (bodyClass) {
        bodyClass.classList.remove("bodyActive");
      }
      creativeInfList()
    } catch (error) {
      throw new Error(`handleAcceptReject error :: ${error}`);
    }

    setLoading(false);
  };

  const handleSendComment = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true)
    let chat = new FormData(e.target).get('text_message') as string;
    try {
      if (chat) {
        const response: any = await applyCampaignFlow.sendCreativeComments({
          creative_id: creativeId,
          proposal_id: proposalId,
          comment: chat,
          account_name: props.UserReducer.brand_name,
          user_id: props.UserReducer?.user_id ?? 0,
          account_id: props.UserReducer?.account_id ?? 0,
          influencer_id: props.UserReducer?.influencer_id ?? 0,
          receiver_influencer_id: receiverInfluencerId ?? 0,
          sm_id: 0,
          // receiver_account_id: receiverAccountId ?? 0
        });
        if (response.data.status.status) {
          //update Comment refresh
          e.target.reset();
          getBrandCreativeComments();

        } else {
          localService.toastify(response.status.message);
        }
      }
    } catch (error) {
      throw new Error(`handleSendComment :: ${error}`);
    }
    finally {
      setIsSubmitting(false); 
    }
  };


  const getBrandCreativeComments = async () => {
    try {
      setChatLoading(true)
      if (activeInfluencerDetail) {
        const response: any = await applyCampaignFlow.getBrandCreativeComments(creativeId || 0);
        
        if (response.data.status == 1 ) {
          creatives && creatives?.filter((creative: any) => {
            if (creative.creative_id == creativeId) {
              setFilterCreative(creative) 
            }
          });
          
          setCommentsList(response.data.rows);
          setChatLoading(false)
        } else {
          // setCreativeCommentList(null)
        }
      }
    } catch (error) {
      setChatLoading(false)
      throw new Error(`handleSelectCampaign function error :: ${error}`);
    }
    finally{
      setChatLoading(false)
    }
  };

  //set Creative Id
  const handleFeedbackModal = (creative_id: any, proposal_id: any) => {
    setOpenModal(true);
    setCreativeId(creative_id);
    setProposalId(proposal_id);
    const filteredProfile = filteredInfluencers.filter((profile: any) => {
      return profile?.sm_id === panel_SMID.sm_id
    })
    setFilteredProfile(filteredProfile)
    setFilterCreative(filteredProfile)
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.add("bodyActive");
    }
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    // Clear modal data on close
    setCreativeId(null);
    setProposalId(null);
    setFilteredProfile([]);
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.remove("bodyActive");
    }
  }

  const handleApprovedCreativeModal = (id: any) => {
    setOpenApprovedCreativeModal(true)
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.add("custom-drawer-modal-open");
    }
  }
  const handleApprovedCreativeCloseModal = () => {
    setOpenApprovedCreativeModal(false)
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.remove("custom-drawer-modal-open");
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      if (openModal === true) {
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
          bodyClass.classList.remove("bodyActive");
        }
        setOpenModal(false)
      }
    });
  }, [openApprovedCreativeModal]);

  const getTotalCreative = async () => {
    const res = await applyCampaignFlow.getTotalCreatives({
      campaign_id: campaignDetails.campaignId,
    });
    if (res?.data) {
      setTotalCreative(res.data.proposal_detail)
      setInfluencerDetail(res.data.influencer_detail)
      setApprovedCreative(res.data.approved_creatives_info)
    }

  }
  useEffect(() => {
    getTotalCreative()
  }, [panel_SMID.proposal_id])

  const [acceptRejectPopupId, setAcceptRejectPopupId] = useState<any>()
  // ACCEPT OR REJECT
  const handleAcceptProposal = (id: any) => {
    setAcceptRejectPopupId(id)
    setOpenModal(false)
    setOpenAcceptPopup(true);
    getTotalCreative()

    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleRejectProposal = (id: any) => {
    setAcceptRejectPopupId(id)
    setOpenModal(false)
    setOpenRejectPopup(true);
    getbrandCreatives(panel_SMID?.panel, panel_SMID?.sm_id, panel_SMID?.proposal_id)
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleAcceptPopupClose = () => {
    setOpenAcceptPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleRejectPopupClose = () => {
    setOpenRejectPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const approvedCreativeLength = totalCreative?.approved_count;
  const rejectedCreativeLength = totalCreative?.rejected_count;
  const pendingCreativeLength = totalCreative?.pending_count;


  const getbrandCreatives = async (panel: number, sm_id: number, proposal_id: number) => {
    try {
      setLoading(true);
      const response: any = await applyCampaignFlow.getbrandCreatives({
        campaign_id: campaignDetails.campaignId,
        sm_id: sm_id,
      });
      if (response.data.status.status) {
        setLoading(false);
        setCreatives(response.data.rows);
        setExpanded(panel);
        setPanel_SMID({ panel: panel, sm_id: sm_id, proposal_id: proposal_id });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching brand creatives:', error);
    }
  };
  const getUnreadCreatives = async () => {
        ApplyCampaignService.getUnreadProposalCreative({
            user_id: props.UserReducer.user_id,
            account_id: props.UserReducer.account_id,
            campaign_id: props.selectCampaign.campaign_id,
        })
            .then((res: any) => {
                if (res.data.status.status) {
                    setUnreadCreative(res.data.rows.unread_creatives);

                    if(res.data.rows.unread_creatives.length == 0){
                      eventEmitter.emit('callUnreadProposalsCreatives');
                    }
                }
            })
            .catch((error) => {
              setUnreadCreative([]);
            }).finally(() => {});
}

  const handleChange = async (panel: number, sm_id: number, proposal_id: number, account_id: number,influencer_id:number) => {
    setActiveInfluencerDetail(sm_id)
    setReceiverAccountId(account_id)
    setRecieverInfluencerId(influencer_id)
    setLoading(true)
    setPanel_SMID({ panel: panel, sm_id: sm_id, proposal_id: proposal_id })
    try {
      if (expanded === panel) {
        setExpanded(false);
      }
      else {
        try {
          setLoading(true);
          getbrandCreatives(panel, sm_id, proposal_id)
        } catch (error) {
          setLoading(false)
          throw new Error(`getCreative Brand :: ${error}`);
        }
      }

    } catch (error) {
      console.error('Error fetching proposal details:', error);
    } finally {
      if(unreadCreative.some((e: any) => e.sm_id === sm_id)){
        getUnreadCreatives();
        }   
      setLoading(false);
    }
  }


  const handleFilteredInfluencer = (event: any) => {
    const value = event.toLowerCase();
    setExpanded(false)
    if (value === 'all') {
      setFilteredInfluencers(originalInfluencers);
    } else {
      const filtered = originalInfluencers.filter((influencer: any) =>
        influencer?.infType?.toLowerCase() === value?.toLowerCase()
      );
      setFilteredInfluencers(filtered);
    }
  };

  useEffect(() => {
    getbrandCreatives(panel_SMID?.panel, panel_SMID?.sm_id, panel_SMID?.proposal_id)
  }, [panel_SMID?.proposal_id])

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const drawerAnchor = isSmallScreen ? 'bottom' : 'right';

  // const handleDrawerOpen = () => {
  //   setOpenModal(true);
  //   const bodyClass = document.getElementsByTagName('html')[0];
  //   if (bodyClass) {
  //     bodyClass.classList.add("bodyActive");
  //   }
    
  // };

  const handleDrawerClose = () => {
    setOpenModal(false);
    setCreativeId(undefined);
    setProposalId(undefined);
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.remove("bodyActive");
    }
  };
  
  const drawerContent = (
    
    <div className="container">
      <div className='row'>
        <div className='col-md-12 d-flex justify-content-between pb-4'>
          {FilteredProfile.map((profile: any) => {
            return <><Profile profileData={profile} creativePopup={true} showDate={false} creativeStatus={false} deliverableHeader={deliverableHeader} />
            </>
          })}
          {filterCreative?.status === 'Pending' && (
            <div className='d-flex align-items-end'>
              <button onClick={() =>
                handleAcceptReject(filterCreative, "Approved")
              } className='btn btn-outline-primary btn-mui'>Approve</button>
              <button onClick={() =>
                handleAcceptReject(filterCreative, "Rejected")
              } className='btn btn-outline-primary  btn-mui ms-2'>Reject</button>
            </div>
          )}
        </div>
      </div>
      <div className='creative-detail'>
        <div className="creative-detail-img">

          {filterCreative?.file_type === "image" && (
            <>{loading ? <Skeleton /> :<img
              src={filterCreative?.url}
              className="selected-creative"
              alt="creative"
            />}</>
          )} 
          
          {filterCreative && filterCreative?.file_type === "video" && (
            <>
              {loading ? <Skeleton /> :
                <><video
                  className="selected-creative"
                  controls
                >
                  <source src={filterCreative?.url} type="video/mp4"></source>
                </video>
                </>
              }
            </>
          )}
        </div>
        <div className="chatbox showbox pro-inf-chat-container">
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body">
                <div className="chat-view">
                  <ul className="parent-ul">
                    
                    {chatLoading ? <ContentLoading width="40" height="40" /> : commentsList.map((data: any, index: number) => {
                      const currentCommentDate = dayjs(data.created_at).format('DD MMM, YYYY');
                      const previousCommentDate = index > 0 ? dayjs(commentsList[index - 1].created_at).format('DD MMM, YYYY') : null;

                      return (
                        <React.Fragment key={index}>
                          {currentCommentDate !== previousCommentDate && (
                            <span className="proposal-status" style={{ display: 'inline-flex', margin: '0 auto' }}>{chatLoading ? <Skeleton /> : currentCommentDate}</span>
                          )}
                          <li className={`mt-3 child-li ${data.influencer_id === props.UserReducer.influencer_id ? 'reply to' : 'sender from'}`}>
                            <span> {chatLoading ? <Skeleton /> : data.comments}</span>
                            <>{FilteredProfile.map((profile: any) => {
                              const imageUrl = profile?.gcp_profile_image_url || DummyCampaignImage.default_campaign_image;
                              return <img className="profile-image-size" src={data.influencer_id === props?.UserReducer?.influencer_id ? props?.selectCampaign.campaign_cover_url : imageUrl} alt="Profile" />;
                            })}</>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="send-box conversion-section">
                <form onSubmit={handleSendComment}>
                  {/* <input type="file" id="file-input" className="send-box-attachment" />
                  <label className="send-box-attach-icon" htmlFor="file-input">
                    <MaterialSymbol iconName="attach_file" fontweight="200" />
                    <span></span>
                  </label> */}
                  <input
                    type="text"
                    name="text_message"
                    className="form-control"
                    aria-label="message…"
                    placeholder="Write message…"
                  />
                  <button type="submit" className='send-btn' disabled={isSubmitting}>
                    <MaterialSymbol className={`send-icon`} iconName='send' fontweight='200' />
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    
  );

  return (
    <div className="brand_creative_main_container" style={{ height: `${apiStatus ? "300px" : ""}`, display: `${apiStatus ? "flex" : ""}`, justifyContent: `${apiStatus ? "center" : ""}`, alignItems: `${apiStatus ? "center" : ""}` }}>
      {!resDataAvailable && <p className="no-list-comment mt-5" >{"You haven't received any creatives yet."}</p>}

      <div className="proposal-container">
        {/* PROPOSAL CONTAINER LEFT */}
        <div className="proposal-container-left">
          {influencers && filteredInfluencers?.length > 0 && <DeliverableTool data={influencers} filteredInfluencer={(influencer: any) => handleFilteredInfluencer(influencer)} />}

        </div>

        {/* PROPOSAL CONTAINER RIGHT */}
        <div className="proposal-container-right">
          {resDataAvailable &&
            <div className='d-flex flex-column flex-md-row pb-2'>
              <span className={approvedCreative.length > 0 ? "btn btn-outline-primary btn-sm" : "btn btn-disabled btn-sm"} onClick={handleApprovedCreativeModal}>View all approved creatives ({approvedCreativeLength})</span>
              <div className='ms-md-auto creative-status-indicator'>
                <ul className="campaign-legend">
                  <li className="campaign-legend_pending" data-attr={pendingCreativeLength && pendingCreativeLength}>Pending</li>
                  <li className="campaign-legend_accepted" data-attr={approvedCreativeLength && approvedCreativeLength}>Approved</li>
                  <li className="campaign-legend_rejected" data-attr={rejectedCreativeLength && rejectedCreativeLength}>Rejected</li>
                </ul>
              </div>
            </div>
          }
          {filteredInfluencers && filteredInfluencers?.map((inf, index) => (
            <Accordion className='accordion-container' key={inf.influencer_id} expanded={expanded === index} onChange={() => handleChange(index, inf.sm_id, inf.proposal_id, inf.account_id,inf.influencer_id)} 
            >
            <AccordionSummary>
              <div className="proposal-header">
              {unreadCreative.length > 0 && (unreadCreative.map((influencer_data: any, index:any) => (
            (inf.sm_id === influencer_data.sm_id) && 
    
            <span className='unread-notification-icon'></span>
            
            )))}
                <div className="proposal-header-left">
                    <Profile profileData={inf} creativeStatus={true} deliverableHeader={deliverableHeader} userReducer={props.UserReducer}/>
                </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {loading && expanded === index && creatives ? (
                  <ContentLoading width='80' height='80' />
                ) : (
                  <>{creatives && (<CreativeContainer
                    creatives={creatives}
                    setCreatives={setCreatives}
                    loading={loading}
                    comments={comments}
                    profileData={FilteredProfile}
                    smID={panel_SMID?.sm_id}
                    proposalId={inf?.proposal_id}
                    setComments={setComments}
                    handleFeedbackModal={(creativeId, proposalId) => handleFeedbackModal(creativeId, proposalId)}
                    handleAcceptProposal={handleAcceptProposal}
                    handleRejectProposal={handleRejectProposal}
                    openAcceptPopup={openAcceptPopup}
                    handleAcceptPopupClose={handleAcceptPopupClose}
                    openRejectPopup={openRejectPopup}
                    handleRejectPopupClose={handleRejectPopupClose}
                    openApprovedCreativeModal={openApprovedCreativeModal} handleApprovedCreativeCloseModal={handleApprovedCreativeCloseModal} handleAcceptReject={handleAcceptReject}
                    openModal={openModal}
                    activeInfluencerDetail={activeInfluencerDetail}
                    creativeId={creativeId && creativeId}
                    acceptRejectPopupId={acceptRejectPopupId}
                    approvedCreative={approvedCreative}
                    apiStatus={apiStatus}
                    handleCloseModal={handleCloseModal}
                    commentsList={commentsList}
                    userReducer={props.UserReducer}
                    handleSendComment={handleSendComment}
                  />
                  )}</>)
                }
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      {createPortal(
        
        <GenericSwipeableDrawer
          id={`creative-${creativeId}`}
          className='creative-drawer-popup'
          drawerAnchor={drawerAnchor}
          open={openModal}
          onClose={handleDrawerClose}
          onOpen={()=>handleFeedbackModal(creativeId, proposalId)}
          title=''
          content={drawerContent}
        />, document.body)}
      
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    selectCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandCreatives);

export { connectToRedux as BrandCreatives };
