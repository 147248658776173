import { Autocomplete, Checkbox, Chip, FormControlLabel, FormGroup, Grid, createFilterOptions, TextField, Stack } from '@mui/material';
import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import FilterDropdown from '../../../components/filter-dropdown/filter-dropdown';
import { NewLayout, CustomGenderButton } from './custom-designs';
import { PublicService } from '../../../core/services';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { InfluencerGender, InfluencerTypes, Min_Engagement_Rate, InfluencerAgeRange, ChannelViews } from '../../../core/enums';
import { Methods } from '../../../core/utility';
import { MenuPropsAutoComplete } from '../../../core/utility/style';

const methods = new Methods();
const publicService: PublicService = new PublicService();

// function formatNumber(value: number) {
//     if (value >= 5000001) {
//         return '5M+'
//     } else if (value >= 5000000) {
//         return '5M'
//     } else if (value >= 1000000) {
//         return '1M'
//     } else if (value >= 1000) {
//         return (value / 1000).toFixed(0) + 'K'
//     }
//     return value.toString();
// };


type CategoryTypes = {
    category_id: number;
    category: string;
    platform: string;
    price_per: number;
}

type LocationTypes = {
    location_id: number,
    priority: number,
    city: string,
    state: string;
    country: string;
    city_code: string;
    state_code: string;
    country_code: string;
}


type FormDataType = {
    influencer_type_id: number[];
    category_id: number[];
    min_enagement_rate: typeof Min_Engagement_Rate;
    channel_view: typeof ChannelViews;
    custom_range: { min_range: number, max_range: number }
    influencer_metrics: {
        location_id: number[];
        gender: ('M' | 'F' | '')[];
        age_range: typeof InfluencerAgeRange;
    }
}

const initialFormData: FormDataType = {
    influencer_type_id: [],
    category_id: [],
    min_enagement_rate: [],
    channel_view: [],
    custom_range: { min_range: 0, max_range: 0 },
    influencer_metrics: {
        location_id: [],
        gender: [],
        age_range: []
    }
};


interface Props {
    clearData: boolean;
    setFilterData: (data: FormDataType) => void;
    platform: string;
    isBasicUser: boolean;
    handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
}

interface Item {
    category_id: number;
}


export const InfluencerFilters: React.FC<Props> = ({ setFilterData, platform, ...arg }: Props) => {
    const [category, setCategory] = useState<CategoryTypes[]>([]);
    const [locations, setLocation] = useState<LocationTypes[]>([]);
    const [formData, setFormData] = useState<FormDataType>(initialFormData);


    useEffect(() => {
        PublicService.getGenre().then(res => {
            setCategory(res.data.rows);
        }).catch(err => console.log(err));


        publicService.getLocation().then(res => {
            setLocation(res.data.rows);
        }).catch(err => console.log(err));


    }, []);

    useEffect(() => {
        setFilterData(formData);
    }, [formData]);

    useEffect(() => {
        if (arg.clearData) {
            // Reset filter data here
            setFormData(initialFormData);
        }
    }, [arg.clearData])


    const filter_AutoComplete_Value = (selectedIds: any[], dataSource: any[], filterObjectKey: string) => {
        return dataSource.filter((data: any) => selectedIds.indexOf(data[filterObjectKey]) !== -1)
    };

    /**
     * Handles the checkbox change event.
     * @param event - The synthetic event object.
     */
    function handleCheckbox(event: SyntheticEvent) {
        const targets = (event.target as HTMLInputElement);
        // if([7,6,5,4].includes(Number(targets.value)) && arg.isBasicUser && arg.handleOpenUpgradeBrandModal){
        //     arg.handleOpenUpgradeBrandModal(true);
        // }else{
            if (targets.checked) {
                setFormData(prev => ({ ...prev, custom_range: { ...prev.custom_range, max_range: 0, min_range: 0 }, influencer_type_id: [...prev.influencer_type_id, parseInt(targets.value)] }))
            } else if (!targets.checked) {
                setFormData(prev => ({ ...prev, influencer_type_id: formData.influencer_type_id.filter((id) => id !== parseInt(targets.value)) }))
            }
        // }
    }
    function removeElement(index: number, objectKey: ('influencer_type_id' | "category_id" | "min_enagement_rate" | 'channel_view')) {
        const temp = formData[objectKey].slice();
        temp.splice(index, 1);
        setFormData(prev => ({ ...prev, [objectKey]: temp }));
    }

    function removeInfluencerMetrics(index: number, objectKey: ('location_id' | 'age_range' | 'gender')) {
        const temp = formData.influencer_metrics[objectKey];
        temp.splice(index, 1)
        setFormData(prev => ({ ...prev, influencer_metrics: { ...prev.influencer_metrics, [objectKey]: temp } }))
    }


    /* AutoComplete Filter Options */

    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();

    /**
     * Filters the options based on the provided state.
     * @param options - The options to filter.
     * @param state - The current state.
     * @returns The filtered options.
     */
    const filterOptions = (options: any, state: any) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    const handleCategoryChange = (event: React.ChangeEvent<{}>, value: Item[]) => {
        if (arg.isBasicUser && value.length > 2 && arg.handleOpenUpgradeBrandModal){
            arg.handleOpenUpgradeBrandModal(true);
        }else{
            setFormData(prev => ({ ...prev, category_id: value.map(item => item.category_id) }));
        }
    };

    return (
        <NewLayout label={'Influencer'} size={'large'}>
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }} >
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <FilterDropdown label={platform === 'instagram' ? "Followers" : "Subscribers"}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        size="small"
                                        id=""
                                        label="Min"
                                        value={formData.custom_range.min_range}
                                        onChange={(event) => {
                                            const value = parseInt(event.target.value);
                                            setFormData(prev => ({ ...prev, influencer_type_id: [], custom_range: { ...prev.custom_range, min_range: isNaN(value) ? 0 : value } }));
                                        }}
                                        className="input-style"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        size="small"
                                        id=""
                                        label="Max"
                                        value={formData.custom_range.max_range}
                                        onChange={(event) => {
                                            const value = parseInt(event.target.value);
                                            setFormData(prev => ({ ...prev, influencer_type_id: [], custom_range: { ...prev.custom_range, max_range: isNaN(value) ? 0 : value } }));
                                        }}
                                        className="input-style"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} className="pt-2">
                                <FormGroup>
                                    {InfluencerTypes.map((item, index) => (
                                        <FormControlLabel
                                            // className={arg.isBasicUser && [7,6,5,4].includes(item.id) ? 'custom-checkbox opacity-50' : 'custom-checkbox'}
                                            className='custom-checkbox'
                                            key={index}
                                            onChange={handleCheckbox}
                                            control={<Checkbox style={{ padding: '0' }}
                                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                checkedIcon={<CheckBox fontSize="small" />}
                                                name="checked"
                                                value={item.id}
                                                checked={formData.influencer_type_id.includes(item.id)}
                                            />}
                                            label={`${item.influencer_type} (${methods.formatNumber(item.min).includes('M+') ? methods.formatNumber(item.max) : methods.formatNumber(item.min) + '-' + methods.formatNumber(item.max)})`}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        </FilterDropdown>
                    </Grid>
                    <Grid className={arg.isBasicUser ? 'user-basic': ""} item lg={3} md={6} sm={12} xs={12}>
                        {arg.isBasicUser && <span className='pro-tag' style={{top: '34px', right:'35px'}}>PRO</span>}
                        <Autocomplete
                            size='small'
                            className={arg.isBasicUser ? 'bg-white' : 'bg-white'}
                            id="age_range_id"
                            limitTags={1}
                            multiple={true}
                            disableCloseOnSelect={true}
                            options={InfluencerAgeRange}
                            value={formData.influencer_metrics.age_range}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            onChange={(event, value) => (arg.isBasicUser && arg.handleOpenUpgradeBrandModal ? arg.handleOpenUpgradeBrandModal(true) : setFormData(prev => ({ ...prev, influencer_metrics: { ...prev.influencer_metrics, age_range: value } })))}
                            getOptionLabel={(option) => [
                                (option.min_range === 0) ? '<18' : (option.min_range === 65) ? '65+' : option.min_range,
                                (option.min_range === 0 || option.min_range === 65) ? null : '-' + option.max_range
                            ].join('')}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.id}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {[
                                        (option.min_range === 0) ? '<18' : (option.min_range === 65) ? '65+' : option.min_range,
                                        (option.min_range === 0 || option.min_range === 65) ? null : ' - ' + option.max_range
                                    ]}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Age Range" />
                            )}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Autocomplete
                            multiple={true}
                            limitTags={1}
                            className='bg-white'
                            size='small'
                            id="category_id"
                            disableCloseOnSelect={true}
                            value={filter_AutoComplete_Value(formData.category_id, category, "category_id")}
                            onChange={(event, value) => handleCategoryChange(event,value)}
                            options={category}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            getOptionLabel={(option) => option.category}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.category_id}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.category}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Category" />
                            )}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12} className='mt-3 mt-md-0'>
                        <CustomGenderButton
                            onChange={(e: any) => setFormData(prev => ({ ...prev, influencer_metrics: { ...prev.influencer_metrics, gender: e } }))}
                            dataSource={InfluencerGender} label='Primary Gender' clearData={arg?.clearData} formData={formData}
                            isBasicUser={arg.isBasicUser} 
                            section='influencer-gender'
                            handleOpenUpgradeBrandModal={arg.handleOpenUpgradeBrandModal}
                        />
                    </Grid>
                    {platform === 'instagram' &&
                        <Grid className={arg.isBasicUser ? 'user-basic': ""} item lg={3} md={6} sm={12} xs={12}>
                            {arg.isBasicUser && <span className='pro-tag' style={{top:'34px', right:'35px'  }}>PRO</span>}
                            <Autocomplete
                                className='bg-white'
                                size='small'
                                id="min_engagement_rate_id"
                                limitTags={1}
                                multiple={true}
                                disableCloseOnSelect={true}
                                options={Min_Engagement_Rate}
                                value={formData.min_enagement_rate}
                                ListboxProps={MenuPropsAutoComplete.PaperProps}
                                onChange={(event, value) => (arg.isBasicUser && arg.handleOpenUpgradeBrandModal ? arg.handleOpenUpgradeBrandModal(true) : setFormData(prev => ({ ...prev, min_enagement_rate: value })))}
                                getOptionLabel={(option) => [option.min_range >= 100 ? '100 & Above' : option.min_range, option.min_range >= 100 ? null : '-' + option.max_range].join(' ')}
                                renderOption={(props, option, { selected }) => (
                                    <li  {...props} key={option.id}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {[option.min_range >= 100 ? '100 & Above' : option.min_range, option.min_range >= 100 ? null : ' - ' + option.max_range]}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Min. Engagement Rate %" />
                                )}
                            />
                        </Grid>
                    }
                    {platform === 'youtube' &&
                        <Grid className={arg.isBasicUser ? 'user-basic': ""} item lg={3} md={6} sm={12} xs={12}>
                            {arg.isBasicUser && <span className='pro-tag' style={{top:'34px', right:'35px'  }}>PRO</span>}
                            <Autocomplete
                                className='bg-white'
                                size='small'
                                id="channel_view_id"
                                limitTags={1}
                                multiple={true}
                                disableCloseOnSelect={true}
                                options={ChannelViews}
                                value={formData.channel_view}
                                onChange={(event, value) => (arg.isBasicUser && arg.handleOpenUpgradeBrandModal ? arg.handleOpenUpgradeBrandModal(true) : setFormData(prev => ({ ...prev, channel_view: value })))}
                                getOptionLabel={(option) => option.label}
                                ListboxProps={MenuPropsAutoComplete.PaperProps}
                                renderOption={(props, option, { selected }) => (
                                    <li  {...props} key={option.id}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <Fragment>
                                            {option.label}
                                        </Fragment>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Channel Views" />
                                )}
                            />
                        </Grid>
                    }

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Autocomplete
                            size='small'
                            id="location_id"
                            className='bg-white'
                            limitTags={1}
                            multiple={true}
                            disableCloseOnSelect={true}
                            value={filter_AutoComplete_Value(formData.influencer_metrics.location_id, locations, "location_id")}
                            onChange={(event, value) => setFormData(prev => ({ ...prev, influencer_metrics: { ...prev.influencer_metrics, location_id: value.map(item => item.location_id) } }))}
                            options={locations}
                            filterOptions={filterOptions}
                            ListboxProps={MenuPropsAutoComplete.PaperProps}
                            getOptionLabel={(option) => [option?.city, option?.state, option?.country].join(', ')}
                            renderOption={(props, option, { selected }) => (
                                <li  {...props} key={option.location_id}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {[option?.city, option?.state, option?.country].join(', ')}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Locations" />
                            )}
                        />
                    </Grid>
                </Grid>
            </form>
            <div className='extend-chips'>
                {/* FOLLOWERS CHIPS */}
                {formData?.influencer_type_id.length > 0 && (
                    <div className='sel-category'>
                        <span>Followers</span>
                        <Stack direction="row" spacing={1}>
                            {formData.influencer_type_id.map((id, index) => {
                                const influencerType = InfluencerTypes.find(type => type.id === id);
                                return influencerType ? (
                                    <Chip
                                        key={index}
                                        label={influencerType.influencer_type}
                                        onDelete={() => removeElement(index, 'influencer_type_id')}
                                    />
                                ) : null;
                            })}
                        </Stack>
                    </div>
                )}
                {/* GENDER CHIPS */}
                {formData?.influencer_metrics?.gender.length > 0 && (
                    <div className='sel-category'>
                        <span>Primary Gender</span>
                        <Stack direction="row" spacing={1}>
                            {formData.influencer_metrics?.gender.map((gender, index) => (
                                <Chip
                                    key={index}
                                    label={
                                        gender === "M" ? "Male" :
                                            gender === 'F' ? "Female" :
                                                gender === '' ? 'Any' : null
                                    }
                                    onDelete={() => removeInfluencerMetrics(index, 'gender')}
                                />
                            ))}
                        </Stack>
                    </div>
                )}
                {/* CATEGORY CHIPS */}
                {formData.category_id.length > 0 &&
                    <div className='sel-category'><span>Category</span>
                        <Stack direction="row" spacing={1}>
                            {formData.category_id.map((data, index) => {
                                let filter = category.filter(fil => fil.category_id === data);
                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`category_${index}_id`}
                                        label={`${filter[0].category}`}
                                        onDelete={(e) => removeElement(index, 'category_id')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>
                }
                {/* MIN. ENGAGEMENT RATE CHIPS */}
                {formData.min_enagement_rate.length > 0 &&
                    <div className="sel-category" ><span className=''>Min. Engagement Rate %</span>
                        <Stack direction="row" spacing={1}>
                            {formData.min_enagement_rate.map((data, index) => {
                                let filter = Min_Engagement_Rate
                                    .filter(fil => fil.min_range === data.min_range && fil.max_range === data.max_range);

                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`min_engagement_${index}_id`}
                                        // label={`${[filter[0].min_range >= 100 ? '100 & Above' : filter[0].min_range, filter[0].min_range >= 100 ? null : '-' + filter[0].max_range]}`}
                                        label={[filter[0].min_range >= 100 ? '100 & Above' : filter[0].min_range, filter[0].min_range >= 100 ? null : '-' + filter[0].max_range].join(' ')}
                                        onDelete={(e) => removeElement(index, 'min_enagement_rate')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>}
                {/* CHANNEL VIEWS */}
                {formData?.channel_view.length > 0 && (
                    <div className='sel-category'>
                        <span>Channel Views</span>
                        <Stack direction="row" spacing={1}>
                            {formData.channel_view?.map((channel_view, index) => (
                                <Chip
                                    key={index}
                                    label={channel_view.label}
                                    onDelete={() => removeElement(index, 'channel_view')}
                                />
                            ))}
                        </Stack>
                    </div>
                )}
                {/* AGE RANGE CHIPS */}
                {formData.influencer_metrics.age_range.length > 0 &&
                    <div className='sel-category'><span>Age Range</span>
                        <Stack direction="row" spacing={1}>
                            {formData.influencer_metrics.age_range.map((data, index) => {
                                let filter = InfluencerAgeRange
                                    .filter(fil => fil.min_range === data.min_range && fil.max_range === data.max_range);
                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`age_range_${index}_id`}
                                        // label={`${[filter[0].min_range >= 100 ? '100 & Above' : filter[0].min_range, filter[0].min_range >= 100 ? null : '-' + filter[0].max_range]}`}
                                        label={[
                                            (filter[0].min_range === 0) ? '<18' : (filter[0].min_range === 65) ? '65+' : filter[0].min_range,
                                            (filter[0].min_range === 0 || filter[0].min_range === 65) ? null : '-' + filter[0].max_range
                                        ].join('')}
                                        // label={[filter[0].min_range >= 100 ? '100 & Above' : filter[0].min_range, filter[0].min_range >= 100 ? null : '-' + filter[0].max_range].join(' ')}
                                        onDelete={(e) => removeInfluencerMetrics(index, 'age_range')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>}
                {/* LOCATIONS CHIPS */}
                {formData.influencer_metrics.location_id.length > 0 &&
                    <div className='sel-category'><span>Location</span>
                        <Stack direction="row" spacing={1}>
                            {formData.influencer_metrics.location_id.map((data, index) => {
                                let filter = locations
                                    .filter(fil => fil.location_id === data);
                                if (filter.length > 0) {
                                    return <Chip
                                        key={index}
                                        id={`location_id_${index}_id`}
                                        label={[filter[0].city, filter[0].state, filter[0].country].join(', ')}
                                        onDelete={(e) => removeInfluencerMetrics(index, 'location_id')} />
                                };

                                return null
                            })}
                        </Stack>
                    </div>}
            </div>
        </NewLayout >

    )
};


