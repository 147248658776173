import React, { useEffect, useState } from "react";
import { SortingColumnsType, TableComponent } from "../../../../components/table-component/table";
import { IconButton, SwipeableDrawer, TableCell, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Loading } from "../../../../redux/action";
import { connect } from "react-redux";
import MaterialSymbol from "../../../../components/icons/material-symbol";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { AgreementService } from "../../../../core/services/influencer-agreement.services";

interface Props {
    UserReducer: any;
    loading: (data: boolean) => void;
    open: any
    onOpen: any;
    onClose: any;
    closeDrawer: any;
    campaignId?: any;
}

const agreementService = new AgreementService();

const AgreementHome: React.FC<Props> = (props: Props) => {
    const columnDisplayName = {
        influencer_name: "Influencer Name",
        sent: "Sent",
        received: "Received",
        action: "Action"
    };

    const sortingColumnType: SortingColumnsType[] = [
        {
            name: "influencer_name",
            columnSize: "160px",
        },
        {
            name: "sent",
            columnSize: "60px",
        },
        {
            name: "received",
            columnSize: "60px",
        },
        {
            name: "action",
            columnSize: "30px",
        }
    ];
    const dataArray = [
        {
            id: 2,
            influencer_name: "Ranvir Singh",
            sent_date: "20 Aug 2024",
            received_date: "22 Aug 2024",
        },
        {
            id: 1,
            influencer_name: "Arunya Sood",
            sent_date: "19 Aug 2024",
            received_date: "",
        }
    ];

    const [renderData, setRenderData] = useState<any>([]);
    const [campaignAgreementData, setCampaignAgreementData] = useState<any[]>([])
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );
    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';

    const getCampaignAgreements = async () => {
        try {
            const response = await agreementService.getCampaignAllAGreement({
                campaign_id: props.campaignId
            });
            if (response.data.status.status) {
                setCampaignAgreementData(response.data.rows)
                // setPdfile(response.data.rows[0].agreement_url)
            }
        } catch (error) {
            console.error('Error fetching campaign agreement data:', error);
        }
    };

    useEffect(() => {
        getCampaignAgreements()
    }, [props.campaignId])

    return (
        <SwipeableDrawer
            anchor={drawerAnchor}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
            transitionDuration={300}
            className='popup-drawer agreement-drawer'
            disableSwipeToOpen={false}
            hysteresis={0.8}
            ModalProps={{
                keepMounted: true,
            }}
        >     <div className='popup-drawer-header py-3'>
                <span className='align-self-center'>Agreements</span>

                <IconButton
                    edge='end'
                    color='inherit'
                    onClick={props.onClose}
                    aria-label='close'
                >
                    <MaterialSymbol iconName='close' fontweight='200' />
                </IconButton>
            </div>
            <div className="agreement-home px-4 mt-3">
                {/* <TableComponent
                    columnDisplayName={columnDisplayName}
                    orderByColumnName=""
                    sortingColumnType={sortingColumnType}
                    inputDataSource={campaignAgreementData}
                    renderData={renderData}
                    setRenderData={setRenderData}
                    tableMinWidth={300}
                >
                    {renderData.map((data: any, index: number) => {
                        return (
                            <TableRow key={index} hover role="checkbox">

                                <TableCell>{data?.influencer_name}</TableCell>
                                <TableCell>
                                    <div className="d-flex flex-column">
                                        <DescriptionOutlinedIcon />
                                        <span style={{ color: '#787878', fontWeight: '400', fontSize: '12px' }}>{data?.sent_date}</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="d-flex flex-column">
                                        {data?.received_date ? <DescriptionOutlinedIcon /> : '-'}
                                        <span style={{ color: '#787878', fontWeight: '400', fontSize: '12px' }}>{data?.received_date}</span>
                                    </div>
                                </TableCell>
                                <TableCell>{<Tooltip title="Send Reminder" arrow><NotificationsActiveOutlinedIcon color={data?.received_date ? 'disabled' : 'inherit'} /></Tooltip>}</TableCell>

                            </TableRow>
                        );
                    })}
                </TableComponent> */}
                <TableComponent
                    columnDisplayName={columnDisplayName}
                    orderByColumnName=""
                    sortingColumnType={sortingColumnType}
                    inputDataSource={campaignAgreementData}
                    renderData={renderData}
                    setRenderData={setRenderData}
                    tableMinWidth={300}
                >
                    {renderData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                No Agreement Available
                            </TableCell>
                        </TableRow>
                    ) : (


                        renderData.map((data: any, index: number) => {
                            const sentDate = data?.agreement_created_at ? new Date(data?.agreement_created_at).toLocaleDateString('en-GB') : '-';
                            const receivedDate = data?.signed_agr_created_at ? new Date(data?.signed_agr_created_at).toLocaleDateString('en-GB') : '-';

                            return (
                                <TableRow key={index} hover role="checkbox">
                                    {/* Influencer Name */}
                                    <TableCell>{data?.influencer_name || '-'}</TableCell>

                                    {/* Sent Date with Agreement URL */}
                                    <TableCell>
                                        <div className="d-flex flex-column">
                                            {data?.agreement_url ? (
                                                <a href={data.agreement_url} target="_blank" rel="noopener noreferrer">
                                                    <DescriptionOutlinedIcon htmlColor="black" />
                                                </a>
                                            ) : (
                                                <DescriptionOutlinedIcon color="disabled" />
                                            )}
                                            <span style={{ color: '#787878', fontWeight: '400', fontSize: '12px' }}>
                                                {sentDate}
                                            </span>
                                        </div>
                                    </TableCell>

                                    {/* Received Date */}
                                    <TableCell>
                                        <div className="d-flex flex-column">
                                            {data?.signed_agr_url ? (
                                                <a href={data.signed_agr_url} target="_blank" rel="noopener noreferrer">
                                                    <DescriptionOutlinedIcon htmlColor="black" />
                                                </a>
                                            ) : (
                                                <DescriptionOutlinedIcon color="disabled" />
                                            )}  <span style={{ color: '#787878', fontWeight: '400', fontSize: '12px' }}>
                                                {receivedDate}
                                            </span>
                                        </div>
                                    </TableCell>

                                    {/* Reminder Icon */}
                                    <TableCell>
                                        <Tooltip title="Send Reminder" arrow>
                                            <NotificationsActiveOutlinedIcon color={data?.received_date ? 'disabled' : 'inherit'} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        }))}
                </TableComponent>


            </div>
        </SwipeableDrawer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementHome);

export { connectToRedux as AgreementHome };


