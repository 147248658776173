import React, { Fragment, useState } from 'react';
import CommonPopUp from './common-popup';
import { Methods } from '../../core/utility';
import { UploadService, LocalService } from '../../core/services';

const methods: Methods = new Methods();
const localService = new LocalService();

interface FileUploadPopupProps {
    openModal: boolean;
    onClose: (closed: boolean) => void;
    acceptedFileTypes: string;
    onSend: (data: any) => void;
    path: string;
    fileMinimumSize?: number; // in KB
    fileMaximumSize?: number; // in KB
}

const FileUploadPopup: React.FC<FileUploadPopupProps> = ({ openModal, onClose, onSend, acceptedFileTypes, path, fileMinimumSize = 0, fileMaximumSize = 50 * 1024 * 1024 }) => {
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [src, setSelectedImage] = useState<any>(null);
    const [error, setError] = useState({ status: false, message: '' });

    const handleClosePreviewImage = () => {
        setFile(null);
    };

    // upload image on aws
    const uploadFile = () => {
        if (file != null && src != null) {
            setLoading(true);
            const form = new FormData();
            form.append('file', file, file.name);
            form.append('path', path);

            try {
                UploadService.UploadMedia(form)
                    .then((res) => {
                        if (res.data.rows.status) {
                            localService.toastify(res.data.rows.message,
                                'success',
                                1000
                            );
                            onSend(res?.data?.rows);
                            onClose(false);
                        } else {
                            localService.toastify(
                                res.data.rows.message,
                                'error',
                                1000
                            );
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                    });
            } catch (err) {
                setLoading(false);
                localService.toastify(
                    'uploading error :: ==> ' + JSON.stringify(err),
                    'error',
                    1000
                );
                throw new Error(`uploadFile Error in component :: ${err}`);
            } finally {
            }
        }
    };

    // select image
    const getImageHandle = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileSize = file.size / 1024;
            if (fileSize < fileMinimumSize || fileSize > fileMaximumSize) {
                setError({
                    status: false,
                    message: `Your file size should be between ${methods.decimalFormat(
                        fileMinimumSize
                    )}KB and ${methods.decimalFormat(fileMaximumSize)}KB`,
                });
                return;
            }

            setFile(file);
            setSelectedImage(URL.createObjectURL(file));
            setError({ status: true, message: '' });
        }
    };

    return (
        <CommonPopUp
            modalTitle="Upload"
            modalContent=""
            openModal={openModal}
            onClose={() => onClose(false)}
            className="modal-medium mx-auto"
        >
            <div className='upload-doc-sec'>
                <Fragment>
                    {file &&
                        <div className='creative-image-preview-sec'>

                            <span className='material-symbols-rounded icon-filled close-preview' onClick={handleClosePreviewImage}>cancel</span>
                            {(file.type !== "application/pdf" && file.type !== "application/msword" && file.type !== "video/mp4") &&
                                <img src={URL.createObjectURL(file)} alt="" className='creative-image-preview' />
                            }
                            <div className='uploaded-pdf d-flex flex-column align-items-center '>
                                {(file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && 
                                    <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>draft</span>
                                }
                                {file.type === "application/pdf" && 
                                    <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>picture_as_pdf</span>
                                }
                                {(file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf") &&
                                    <>{file.name}</>
                                }
                            </div>
                            {file.type === 'video/mp4' &&
                                <video
                                    style={{ cursor: 'pointer' }}
                                    height={400}
                                    width={400}
                                    className='creative-image-preview'
                                    controls
                                >
                                    <source src={URL.createObjectURL(file)} type='video/mp4'></source>
                                </video>
                            }
                        </div>
                    }

                    {!file &&
                        <div className="upload-btn">
                            <input type="file" accept={acceptedFileTypes} onChange={getImageHandle}
                            />
                            <span className='material-symbols-rounded'>
                                upload
                            </span> <strong>Choose File</strong>
                        </div>
                    }
                    {error && <span className='error'>{error.message}</span>}
                    <span className='fs-14 pb-5'>accepts: {acceptedFileTypes}</span>
                    <button
                        className='upload-file-btn btn btn-primary'
                        disabled={!file || loading}
                        onClick={uploadFile}
                    >
                        {loading ? 'Uploading...' : 'Upload'}
                    </button>
                </Fragment>
            </div>
        </CommonPopUp>
    );
};

export default FileUploadPopup;
