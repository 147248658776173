import React, { useEffect, useState, useRef } from "react";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import "./influencers-agreements.scss";
import { Grid, TextField } from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { Loading } from '../../../../redux/action';
import { connect } from 'react-redux';
import { LocalService, PublicService } from "../../../../core/services";
import { AgreementService } from "../../../../core/services/influencer-agreement.services";
import CommonPopUp from "../../../../components/popup/common-popup";
import { LoadingButton } from "@mui/lab";


const localService = new LocalService();
const agreementService = new AgreementService();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
  influencer?: any;
  sendAgreement?: boolean
  generatePdf?: boolean
  campaignId?: any
  influencerId?: any
  onSaveAgreement?: () => void;
}

const GenerateAgreement: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [editTemplate, setEditTemplate] = React.useState(false);
  const [uploadTemplate, setUploadTemplate] = React.useState(false);
  const [availableAgreementData, setAvailableAgreementData] = useState<any[]>([])
  const [chooseFile, setChooseFile] = useState<File | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const getTargetElement = () => document.getElementById('agreement');

  const options = {
    // default is `save`
    method: "build" as const,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait' as const,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg' as const,
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };

  
  /*.................................Send Agreement ..............................*/

  const today = new Date().toLocaleDateString('en-IN', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  // Agreement form Handle
  const agreement_dates = new Date().toISOString().slice(0, 10)
  const [agreementDetails, setAgreementDetails] = useState<any>({
    agreement_date: new Date().toISOString().slice(0, 10),
    influencer_name: "",
    brand_name: "",
    brand_address: "",
    deliverables: "",
    agreement_url: "",
    agreement_path: "",
    contract_period: "NA",
    service_terms: "NA",
    usage_terms: "NA",
    payment_terms: "NA",
    other_terms: "NA"
  });

  useEffect(() => {
    getAgreementData()
    if (props.sendAgreement) {
      getAvailableAgreement()
      setOpenDialog(true);
    }
  }, [props.sendAgreement]);

  // Fetch available agreements based on campaign and influencer IDs
  const getAvailableAgreement = async () => {
    try {
      const response = await agreementService.getAgreementDetails({
        campaign_id: props.campaignId,
        influencer_id: props.influencer.influencer_id,
      });
      if (response.data.status.status) {
        setAvailableAgreementData(response.data.rows)
      }
      if (response.data.rows.length > 0) {
        localService.toastify("Your current file will be replaced", 'info', 10000)
      }
    } catch (error) {
      console.error('Error fetching agreement details:', error);
    }
  };

  // Fetch agreement data and set it in state
  const getAgreementData = async () => {
    try {
      const response = await agreementService.getAgreementData({
        campaign_id: props.campaignId,
        influencer_id: props.influencer.influencer_id,
      });
      if (response.data.status.status) {
        const data = response.data.rows[0];
        setAgreementDetails({
          agreement_date: new Date().toISOString().slice(0, 10),
          influencer_name: data.name || "",
          brand_name: data.brand_name || "",
          brand_address: props.UserReducer.address || "",
          deliverables: data.campaign_description || "",
          contract_period: data.contract_period || "",
          service_terms: data.service_terms || "",
          usage_terms: data.usage_terms || "",
          payment_terms: data.payment_terms || "",
          other_terms: data.other_terms || ""
        });
      }
    } catch (error) {
      console.error('Error fetching agreement data:', error);
    }
  };

  // Mapping of deliverable items to their formatted string
  const deliverable = props.influencer.deliverables

  type DeliverableItem = {
    deliverable_item: string;
    quantity: number;
    price: number;
    step_status: string;
  };

  const combinedMappings: { [key: string]: (item: DeliverableItem) => string } = {
    ig_reel: (item) => `${item.quantity} Reels: ₹${item.price}`,
    ig_static: (item) => `${item.quantity} Static (${item.quantity} images): ₹${item.price}`,
    ig_story: (item) => `${item.quantity} Story: ₹${item.price}`,
    yt_shorts: (item) => `${item.quantity} YouTube Shorts: ₹${item.price}`,
    yt_dedicated_video: (item) => `${item.quantity} Dedicated Videos: ₹${item.price}`,
    yt_integrated_video: (item) => `${item.quantity} Integrated Videos: ₹${item.price}`,
    digital_rights: (item) => `Digital Rights (${item.quantity} days): ₹${item.price}`,
    available_for_exclusivity: (item) => `Exclusivity (${item.quantity} days): ₹${item.price}`,
  };

  const formatCampaignDetails = (deliverables: DeliverableItem[]): { deliverablesFormatted: string, commercialsFormatted: string, totalCost: number } => {
    if (!deliverables || deliverables.length === 0) return { deliverablesFormatted: 'NA', commercialsFormatted: 'NA', totalCost: 0 };

    const filteredDeliverables = deliverables.filter((item) => item.step_status !== 'Rejected');

    const hasPackageCost = filteredDeliverables.some((item) => item.deliverable_item.includes('Package Cost'));

    const hasZeroPrice = filteredDeliverables.some((item) => item.price === 0);

    const deliverablesFormatted = filteredDeliverables
      .map((item) => {
        const key = item.deliverable_item;
        if (key in combinedMappings) {
          return combinedMappings[key](item).split(':')[0]; // Get only the deliverable part
        }
        return '';
      })
      .filter(Boolean)
      .join('<br /> ');

    // const commercialsFormatted = filteredDeliverables
    //   .map((item) => combinedMappings[item.deliverable_item]?.(item))
    //   .filter(Boolean)
    //   .join('<br />');
    const commercialsFormatted = (hasPackageCost || hasZeroPrice)
    ? `&nbsp`
    : filteredDeliverables
        .map((item) => combinedMappings[item.deliverable_item]?.(item))
        .filter(Boolean)
        .join('<br />');

    const totalCost = filteredDeliverables.reduce((acc, item) => acc + item.price, 0);
      
    return {
      deliverablesFormatted: deliverablesFormatted || 'NA',
      commercialsFormatted: commercialsFormatted || 'NA',
      totalCost,
    };
  };

  const { deliverablesFormatted, commercialsFormatted, totalCost } = formatCampaignDetails(deliverable);

  // Adding agreement form data 
  let formStatus: boolean = false
  async function insertFormData() {
    try {
      const influencer = props.influencer || {};
      const data = {
        agreement_dates: agreement_dates || '',
        influencer_name: agreementDetails.influencer_name || '',
        brand_name: agreementDetails.brand_name || '',
        brand_address: agreementDetails.brand_address || '',
        campaign_id: props.campaignId || '',
        influencer_id: influencer.influencer_id || '',
        deliverables: deliverable,
        contract_period: agreementDetails.contract_period || 'NA',
        service_terms: agreementDetails.service_terms || 'NA',
        usage_terms: agreementDetails.usage_terms || 'NA',
        payment_terms: agreementDetails.payment_terms || 'NA',
        other_terms: agreementDetails.other_terms || 'NA'
      };

      const response = await agreementService.influencerInsert(data);
      const toastType = response.data.status.status ? 'success' : 'error';
      if (response.data.status.status) {
        formStatus = true
      }
    } catch (error) {
      console.error('Error inserting form data:', error);
    }
  }

  // Generate and append PDF 
  // async function generateAndAppendPDF() {
  //   const formData = new FormData();
  //   try {
  //     if (chooseFile) {
  //       formData.append('file', chooseFile);
  //     } else {
  //       const targetElement = getTargetElement();
  //       if (targetElement && targetRef.current) {
  //         targetRef.current.className = 'modified-agreement mt-2 overflow-auto';

  //         const pdfInstance = await generatePDF(getTargetElement, options);
  //         const blob = pdfInstance.output('blob');
  //         const generatedFile = new File([blob], 'agreement.pdf', { type: 'application/pdf' });

  //         setChooseFile(generatedFile);
  //         formData.append('file', generatedFile);
  //       } else {
  //         throw new Error("Target element or reference is null");
  //       }
  //     }

  //     formData.append('path', `campaign_agreements/${props.influencer.influencer_id}/brand`);
  //     formData.append('campaign_id', props.campaignId || '');
  //     formData.append('influencer_id', props.influencer.influencer_id || props.influencerId || '');

  //     const finalResponse = await agreementService.influencerAgreement(formData);
  //     const finalToastType = finalResponse.data.status.status ? "success" : "error";
  //     localService.toastify(finalResponse.data.status?.message, finalToastType, 1000);

  //   } catch (error) {
  //     console.error('Error generating and appending PDF:', error);
  //   } finally {
  //     formStatus = false;
  //   }
  // }

  const autofillEmptyFields = () => {
    const updatedDetails = { ...agreementDetails };
  
    Object.keys(updatedDetails).forEach((key) => {
      if (!updatedDetails[key]) {
        updatedDetails[key] = 'NA';
      }
    });
  
    setAgreementDetails(updatedDetails);
  };
  
  async function generateAndAppendPDF() {
    // Autofill empty fields with 'NA'
    autofillEmptyFields();
  
    // Wait for the state update
    await new Promise(resolve => setTimeout(resolve, 0));
  
    const formData = new FormData();
    try {
      if (chooseFile) {
        formData.append('file', chooseFile);
      } else {
        const targetElement = getTargetElement();
        if (targetElement && targetRef.current) {
          targetRef.current.className = 'modified-agreement mt-2 overflow-auto';
  
          const pdfInstance = await generatePDF(getTargetElement, options);
          const blob = pdfInstance.output('blob');
          const generatedFile = new File([blob], 'agreement.pdf', { type: 'application/pdf' });
  
          setChooseFile(generatedFile);
          formData.append('file', generatedFile);
        } else {
          throw new Error("Target element or reference is null");
        }
      }
  
      formData.append('path', `campaign_agreements/${props.influencer.influencer_id}/brand`);
      formData.append('campaign_id', props.campaignId || '');
      formData.append('influencer_id', props.influencer.influencer_id || props.influencerId || '');
  
      const finalResponse = await agreementService.influencerAgreement(formData);
      const finalToastType = finalResponse.data.status.status ? "success" : "error";
      localService.toastify(finalResponse.data.status?.message, finalToastType, 1000);
  
    } catch (error) {
      console.error('Error generating and appending PDF:', error);
    } finally {
      formStatus = false;
    }
  }
  

  // Save agreement details by inserting form data and generating PDF
  async function saveAgreementDetails() {
    setLoading(true);
    try {
      if (!formStatus) {
        await insertFormData();
      }
      if (formStatus) {
        await generateAndAppendPDF();
      }
    } catch (error) {
      console.error(`saveAgreementDetails error :: ==> ${error}`);
    } finally {
      formStatus = false;
      setLoading(false);
      setChooseFile(null);
      setOpenDialog(false);
      setEditTemplate(false)
      setUploadTemplate(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseTemplate = () => {
    setEditTemplate(false)
  };
  const handleCloseUpload = () => {
    setUploadTemplate(false)
  };

  // Handle file selection and updating state
  const handleChooseFileChange = (e: any) => {
    const selectedChooseFile = e.target.files[0];
    if (selectedChooseFile) {
      setChooseFile(selectedChooseFile);
    }
  };

  // Remove chosen file and update state
  const handleRemoveChooseFile = () => {
    setChooseFile(null);
  };

  return (
    <>
      <div className='container'>
        <div className="influencer-agreement-main-section">

          <CommonPopUp
            modalTitle="Agreement"
            // modalContent={modalContent}
            openModal={editTemplate}
            onClose={handleCloseTemplate}
            popupMinWidth='630px'
            className="modal-xlarge popup-agreement agreement-section"
          >
            {/* <div className="agreement-heading my-3">
            <HeadingStyle title="Agreement" />
          </div> */}
            <div className="agreement-section">
              <div className="agreement-title">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Grid gap={2}>
                      <div ref={targetRef} className="agreement mt-2 overflow-auto" id="agreement">
                        <div className="terms-conditions p-5">
                          <h3 className="text-center">INFLUENCER TERMS AGREEMENT</h3>
                          <p>{today}</p>
                          <table className="agreement-table">
                            <thead>
                              <tr>
                                <th>Contract Period</th>
                                <td>
                                  <TextField
                                    className=""
                                    multiline
                                    fullWidth
                                    placeholder="e.g. 25th October 2020 to 30th September 2021"
                                    size="small"
                                    name="contract_period"
                                    value={agreementDetails.contract_period}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        contract_period: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                {/* <td>{agreementDetails.brand_name}</td> */}
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    name="brand_name"
                                    value={agreementDetails.brand_name}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        brand_name: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Address of Advertiser</th>
                                {/* <td>{props.UserReducer.address}</td> */}
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    name="brand_address"
                                    value={agreementDetails.brand_address}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        brand_address: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Influencer Name</th>
                                {/* <td>{agreementDetails.influencer_name}</td> */}
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    name="influencer_name"
                                    value={agreementDetails.influencer_name}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        influencer_name: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Service Term</th>
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    placeholder="e.g. 12 months from the day of agreement"
                                    name="service_terms"
                                    value={agreementDetails.service_terms}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        service_terms: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Usage Term</th>
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    placeholder="e.g. Brand can Upload/Repost/Promote the video"
                                    name="usage_terms"
                                    value={agreementDetails.usage_terms}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        usage_terms: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Deliverables for Campaign</th>
                                <td><div dangerouslySetInnerHTML={{ __html: deliverablesFormatted }} />
                                </td>
                              </tr>
                              {props.influencer.campaign_type != 'Barter' && (
                                <tr>
                                  <th>Commercials for Campaign</th>
                                  <td>
                                    {commercialsFormatted != '&nbsp' && (
                                      <div dangerouslySetInnerHTML={{ __html: commercialsFormatted }} />
                                    )}
                                    <strong>{totalCost > 0 ? `Total Cost: ₹${totalCost}` : 'NA'}</strong>
                                  </td>
                                </tr>
                              )}
                              
                              {props.influencer.campaign_type != 'Barter' && (
                                <tr>
                                  <th>Payment Terms</th>
                                  <td>
                                    <TextField
                                      id="outlined-multiline-flexible"
                                      className=""
                                      multiline
                                      fullWidth
                                      placeholder="e.g. For every campaign 50% advance before go live and 50% within 30 days of this activity. "
                                      name="payment_terms"
                                      value={agreementDetails.payment_terms}
                                      onChange={(e) =>
                                        setAgreementDetails({
                                          ...agreementDetails,
                                          payment_terms: e.target.value,
                                        })
                                      }
                                    />
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th>Any Other Terms</th>
                                <td>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    className=""
                                    multiline
                                    fullWidth
                                    name="other_terms"
                                    placeholder="e.g. exclusivity period, non-compete clause"
                                    value={agreementDetails.other_terms}
                                    onChange={(e) =>
                                      setAgreementDetails({
                                        ...agreementDetails,
                                        other_terms: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div>
                            <br />
                            <p>
                              <strong>{agreementDetails.brand_name}</strong> and Influencer shall jointly be referred to as the “Parties” and severally as the “Party”.
                            </p>
                            <p><strong>Where As</strong></p>
                            <ol type="A">
                              <li>
                                Influencer is an expert in his domain and posts images, videos, text on social media (“Content”).
                              </li>
                              <li>
                                Advertiser is the brand for whom the Content is being produced.
                              </li>
                              <li>
                                Advertiser and Influencer wish to enter into a tie-up arrangement for a campaign for branded content on social media. Influencer(s) agree to provide the Content in accordance with the terms of this Agreement, read in conjunction with the standard Terms And Privacy Policy.
                              </li>
                            </ol>

                            <p><strong>1. Content Details:</strong></p>
                            <p>
                              During the Service Term, Influencer shall post Branded posts on the agreed-upon social media, details of which are mentioned in this Agreement. The Influencer shall be required to apply and maintain the highest standards of personal conduct and integrity and comply with all policies and procedures governing this Agreement. The Influencer shall be liable to post the content at the committed times failing which the content will not be approved. The Influencer will take prior approval of the content before posting the same. The Influencer shall post the Content as per the committed time, which shall be mutually agreed upon between the Parties. However, if due to any force majeure event or otherwise Advertiser fails to accommodate the Influencer in the time allotted then Influencer’s sole remedy for such failure shall be to allot another slot or cancel the activity of the Influencer as mutually agreed between the Parties.
                            </p>

                            <p><strong>2. Payment details:</strong></p>
                            <p>
                              Payment will be made by means of account transfer as per the above-mentioned payment terms of the invoice being raised by the Influencer, exclusive of applicable taxes but subject to deductions of any withholding taxes such as TDS per extant provisions under the Income Tax, 1961. The aforesaid payment shall be paid in the manner as mentioned in this Agreement. Advertiser’s payment obligation will be expressly subject to the completion of Influencer’s performance as per the terms of this Agreement.
                            </p>
                            
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>

                            <p><strong>3. Indemnity:</strong></p>
                            <p>
                              Each Party shall, at its own expense, indemnify, save and hold harmless, the other Party and its successors, licensees, assigns, agents, representatives, and affiliates from and against any and all claims, demands, causes of action, obligations, liability, loss, damage, costs, and expenses (including reasonable attorneys' fees), incurred or sustained by reason of or arising out of any breach or alleged breach of any of the warranties, representations, obligations or agreements herein made by such defaulting Party, or from any reliance upon any such warranties, representations, obligations, or agreements. Notwithstanding, the liability of the Artist shall be limited to the amounts received till such date by the Advertiser.
                            </p>

                            <p><strong>4. Promotion:</strong></p>
                            <p>
                              During the Usage Term and subject to receipt of the entire Consideration, the Advertiser shall be entitled to advertise and promote the content of Influencer(s) and the Performance. Influencer(s) acknowledge that Advertiser will rely on the terms hereof in all such promotions and advertising and in the content that has been created. Influencer(s) hereby acknowledge and agree that Advertisers may use their names, photographs, likeness, facsimile signature, and any other promotional materials in all such promotions, advertising, or other activities used to increase views of the content published.
                            </p>

                            <p><strong>5. INTELLECTUAL PROPERTY RIGHTS:</strong></p>
                            <ol>
                              <li>
                                The Parties agree and acknowledge that this Agreement does not grant to the Influencer any right, title, or interest in any Intellectual Property Rights owned by the Advertiser, and the Influencer shall not use the Intellectual Property Rights owned by the Advertiser in any manner whatsoever, except as provided hereunder and only with prior written permission of the Advertiser.
                              </li>
                              <li>
                                The Influencer acknowledges and agrees and shall ensure that:
                                <ul>
                                  <li>
                                    The Influencer absolutely and irrevocably assigns to the Advertiser and the respective Advertiser(s), to the exclusion of other Persons, all the rights, title, and interest, including the Intellectual Property Rights that the Influencer has or may in the future become entitled to, in the Content created pursuant to this agreement, in any media, medium, manner, means, technology, or delivery system, or combination of them, which currently exists or which is developed in the future. The Service Provider and the Influencer shall ensure that subject to the provisions of this ARTICLE 5.2 such rights solely and exclusively belong to the Advertiser and the respective Advertiser(s) and include the right to sub-assign, as if the same was created as a work for hire.
                                  </li>
                                  <li>
                                    Where the aforesaid assignment of rights is not possible, the Influencer grants the Advertiser and the respective Advertiser(s), an exclusive, worldwide, irrevocable, licensable (through multiple tiers) right to use or exercise any and all the rights, including the Intellectual Property Rights that the Influencer has or may in the future become entitled to, in the Content created pursuant to this agreement, in any media, medium, manner, means, technology, or delivery system, or combination of them, which currently exists or which is developed in the future.
                                  </li>
                                  <li>
                                    The Influencer, wherever possible, irrevocably and unconditionally waives any and all moral rights or any rights of similar nature under any law in any jurisdiction in and to any intellectual property created pursuant to this Agreement. Further to clarify, pursuant to this ARTICLE, Advertiser(s) shall have the right to use, explore, exploit and utilize the said Content created pursuant to this agreement worldwide and during the Term of this Agreement.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Notwithstanding anything contained elsewhere to the contrary, any Intellectual Property Rights in the content created pursuant to this agreement, will not prevent the Influencer from using such content on their social media accounts, technology or delivery system, or combination of them, which currently exists or which is developed in the future or as part of their portfolio or for any other personal reason.
                              </li>
                            </ol>

                            <p><strong>6. Consideration:</strong></p>
                            <p>
                              The Influencers acknowledge that the Consideration payable by the Advertiser to the Influencer is a good, valuable, and adequate consideration, to be bound by the terms and conditions of this Agreement including the assignment of rights in the intellectual property as set out in this ARTICLE and adhering to the restrictions set out elsewhere in this Agreement.
                            </p>

                            <p><strong>7. Termination:</strong></p>
                            <p>
                              Advertiser shall have the right to terminate this Agreement, with cause, by giving seven (7) days’ notice to the Artist. Influencer may choose to exit this arrangement, by furnishing to the advertiser, a prior written notice of termination of the Agreement of at least seven (7) days. The Agreement shall be effective and in full force until One (1) month Service Term unless terminated as per the terms of this Agreement. Upon such termination, the Artist shall be entitled to the proportionate consideration for the services rendered till such date.
                            </p>
                            <p>
                              Artists shall be entitled to terminate the said Agreement within seven (7) days and restrained Advertiser to utilize his Services in the event the Advertiser fails to pay him 100% consideration as per the terms of this Agreement.
                            </p>

                            <p><strong>8. Assignment:</strong></p>
                            <p>
                              No rights or liabilities under this AGREEMENT shall be assigned by Influencer without the prior written consent of the advertiser. It is understood that the relationship between the Parties is solely on a principal-to-principal basis. Neither Party shall acquire, by virtue of any provision of this AGREEMENT or otherwise, any right, power, or capacity to act as an agent or commercial representative of the other Party for any purpose whatsoever except as provided in this AGREEMENT.
                            </p>
                              
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>

                            <p><strong>9. Governing Law:</strong></p>
                            <p>
                              This AGREEMENT shall be governed and construed in accordance with the laws of India. Any dispute arising out of or relating to this AGREEMENT shall be subject to the exclusive jurisdiction of courts at Bangalore.
                            </p>

                            <p><strong>10. Confidentiality:</strong></p>
                            <p>
                              The Expert shall ensure that he/she will not speak/share/propagate/disperse any negative and derogatory comments or sentiments about the advertiser or its affiliates in their own or any other social platform or publication, in any manner whatsoever for a period of 2 years from the date of expiry of the Campaign period.
                            </p>

                            <p>
                              In witness whereof, the parties have executed this AGREEMENT effective as of the effective date set forth above. This AGREEMENT may be signed in counterparts and via facsimile or digital means, including electronic signatures.
                            </p>

                            <p><strong>Influencer name:</strong></p>
                            <p>Signature:</p>
                            <p>Name:</p>
                            <p>Designation:</p>
                            <p>Date:</p>

                            <p><strong>For and on behalf of {agreementDetails.brand_name} :</strong></p>


                            <p>Name:</p>
                            <p>Signature:</p>
                            <p>Designation: </p>
                            <p>Date:</p>
                          </div>


                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

              </div>
              <div className='popup-footer d-flex justify-content-end'>
                <div className="">
                  <LoadingButton
                    className="btn btn-outline-primary"
                    loading={loading}
                    loadingPosition="start"
                    onClick={() => {
                      setEditTemplate(false)
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    className="btn btn-primary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    onClick={saveAgreementDetails}
                  >
                    Send
                  </LoadingButton>
                </div>
              </div>
            </div>
          </CommonPopUp>
        </div>
        <CommonPopUp
          modalTitle="Send Agreement"
          modalContent=""
          openModal={openDialog}
          onClose={handleCloseDialog}
          className="modal-medium"
        >
          <div className="d-flex justify-content-center mb-5">
            <button
              className="btn btn-primary"
              onClick={() => setEditTemplate(true)}
            >
              Edit Template
            </button>
            <Divider
              orientation="vertical"
              flexItem
            >
              <span>or</span>
            </Divider>
            <button
              className="btn btn-primary"
              onClick={() => setUploadTemplate(true)}
            >
              Upload
            </button>
          </div>
        </CommonPopUp>

        <CommonPopUp
          modalTitle="Upload"
          modalContent=""
          openModal={uploadTemplate}
          onClose={handleCloseUpload}
          className="modal-medium mx-auto"
        >
          <div className='upload-doc-sec'>
            <>
              {chooseFile &&
                <div className='creative-image-preview-sec'>

                  <span className='material-symbols-rounded icon-filled close-preview' onClick={handleRemoveChooseFile} >cancel</span>
                  <div className='d-flex flex-column'>
                    <span className='material-symbols-rounded text-center mb-2' style={{ fontSize: '60px' }}>picture_as_pdf</span>
                    {chooseFile && chooseFile.name}
                  </div>
                </div>
              }
              {!chooseFile &&
                <div className="upload-btn">
                  <input type="file" accept="application/pdf" onChange={handleChooseFileChange} />
                  <span className='material-symbols-rounded'>
                    upload
                  </span> <strong>Upload</strong>
                </div>
              }
              <span className='fs-14 pb-5'>accepts: .pdf</span>
              <LoadingButton
                className='upload-file-btn btn btn-primary'
                loading={loading}
                loadingPosition='start'
                // disabled={disableSubmitButton}
                // onClick={() => {
                //   // setShowChooseFile(true);
                //   // setOpenModal(false)
                //   // setAgreementSection(false);
                // }}
                onClick={() => {
                  saveAgreementDetails();
                  setEditTemplate(false);
                  setOpenDialog(false);
                  setUploadTemplate(false);
                }}            >
                Send
              </LoadingButton>
            </>
          </div>
        </CommonPopUp>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateAgreement);

export { connectToRedux as GenerateAgreement };
