import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import * as Util from '../utility';

// const http = new HttpService();

export class ChatService {
    private http: HttpService;
    private methods: Util.Methods;

    constructor() {
        this.http = new HttpService();
        this.methods = new Util.Methods();
    };


    async getUnReadMessage(data: {}) {
        const path = BASE_URL + `/v1/messages/unread?${this.methods.objConvertIntoParam(data)}`;
        try {
            const response = await this.http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerList Service Error :: ${error}`);
        }
    };

    /** 
     * This function get Influencers List in Proposal Section. its v1
    */
    async getChatInfluencerList(data: {}) {
        const path = BASE_URL + `/v1/proposal/influencers?${this.methods.objConvertIntoParam(data)}`;
        try {
            const response = await this.http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerList Service Error :: ${error}`);
        }
    };


    /**
 * Asynchronously retrieves the influencer conversion data based on the provided data object.
 * 
 * @param data - The data object containing the necessary parameters for the request.
 * @returns A Promise that resolves with the response data from the API.
 * @throws Error if there is an issue with the HTTP request or processing the response.
 */

    async getInfluencerConversion(data: {}) {
        const path = BASE_URL + `/v1/proposal/influencer/conversion`;
        try {
            const response = await this.http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerList Service Error :: ${error}`);
        }
    };

    async insertProposalMessage(data: Object) {
        const path = BASE_URL + `/v1/proposal/influencer/message`;
        try {
            const response = await this.http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerList Service Error :: ${error}`);
        }
    };

    async getChatCampaignList(data: {}) {
        const path = BASE_URL + `/v1/proposal/campaigns?${this.methods.objConvertIntoParam(data)}`;
        try {
            const response = await this.http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerList Service Error :: ${error}`);
        }
    };

}
