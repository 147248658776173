import React, { useState } from 'react';
import '../../brand/settings/main-settings.scss';
import { Outlet, useNavigate, NavLink } from 'react-router-dom';
import HeadingStyle from '../../../components/heading-style/heading-style';
import { Tab, Tabs } from '@mui/material';

export const AgencySetting: React.FC = () => {
    let navigate = useNavigate();
    const [selection, setSelection] = useState();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelection(newValue);
        navigate(`${newValue}`);
    };
    return (
        <>
            <div className='profile_settings_container'>
                <div className='container'>
                    <HeadingStyle
                        title='Settings'
                        className='pb-4 d-block'
                    />
                    <div className='row profile_settings_main_container'>
                        <div className='col-auto profile_settings_left_side d-none d-md-flex'>
                            <ul className='profile_settings_menu'>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks'}
                                        to={'personalinfo'}
                                    >
                                        Personal Info
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks'}
                                        to={'security'}
                                    >
                                        Security
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks'}
                                        to={'subscription'}
                                    >
                                        Subscription
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className={'profile_settings_navlinks'}
                                        to={'companytradedetails'}
                                    >
                                        Company Details
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                        <Tabs
                            value={selection}
                            onChange={handleChange}
                            aria-label='nav tabs example'
                            variant='scrollable'
                            scrollButtons='auto'
                            allowScrollButtonsMobile
                            className='d-flex d-md-none profile-menu-mobile tab-underline'
                        >
                            <Tab
                                className='tab-underline-menu'
                                label='Personal Info'
                                value='security'
                            />
                            <Tab
                                className='tab-underline-menu'
                                label='Security'
                                value='personalinfo'
                            />

                            <Tab
                                className='tab-underline-menu'
                                label='Subscription'
                                value='subscription'
                            />
                            <Tab
                                className='tab-underline-menu'
                                label='Company Details'
                                value='companytradedetails'
                            />
                        </Tabs>

                        <div className='col profile_settings_right_side'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
