import React, { useEffect, useState } from 'react';
import { TableComponent, SortingColumnsType } from '../../../../../../components/table-component/table';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './../verification.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';

import { FormValidation } from '../../../../../../core/utility';
// importing admin dashboard and local services
import { AdminDashboardService } from '../../../../../../core/services/admin-dashboard.service';
import { LocalService } from '../../../../../../core/services';
// important common popup component
import CommonPopUp from '../../../../../../components/popup/common-popup';

// To connect to redux
import { Loading } from '../../../../../../redux/action';
import { connect, useDispatch } from 'react-redux';
import dayjs from "dayjs";
import HeadingStyle from '../../../../../../components/heading-style/heading-style';
import { Editor } from '../../../../../../components';

// Create an instances of AdminDashboardServices, LocalServices and formValidations
const adminDashboardService = new AdminDashboardService();
const localService = new LocalService();
const formValidation = new FormValidation();

const columnDisplayName = {
  brand_name: 'Brand Name',
  full_name: 'Campaign Owner',
  login_email: 'Owner Email',
  login_mobile: 'Owner Phone',
  campaign_title: 'Campaign Name',
  campaign_visibility: 'Campaign Visibility',
  posted_at: 'Submitted On',
  action: 'Action',
}

const sortingColumnType: SortingColumnsType[] = [
  {
    name: 'brand_name',
    columnSize: '180px',
  },
  {
    name: 'full_name',
    columnSize: '180px',
  },
  {
    name: 'login_email',
    columnSize: '180px',
  },
  {
    name: 'login_mobile',
    columnSize: '180px',
  },
  {
    name: 'campaign_title',
    columnSize: '180px',
  },
  {
    name: 'campaign_visibility',
    columnSize: '180px',
  },
  {
    name: 'posted_at',
    columnSize: '180px',
  },
  {
    name: 'action',
    columnSize: '320px',
  },
]

interface Props {
  loading: (data: any) => any;
  userData: any;
}

const CampaignVerification: React.FC<Props> = (props: Props) => {

  const [renderData, setRenderData] = useState<any>([])

  // Review campaign data
  const [reviewCampaignData, setReviewCampaignData] = useState<any>([]);

  // Modal form data
  const [formData, setFormData] = useState({
    reviewed_by: props.userData.user_id,
    remark: '',
    campaign_id: '',
    status: '',
    campaign_title: '',
    campaign_visibility: '',
    campaign_owner: '',
    posted_at: '',
  });

  // Set form error fields
  const [formError, setFormErrors] = useState({
    remark: { status: true, message: '' },
  });

  const handleError = (event: any) => {
    // Validate form
    // validating name
    const validateRemark = formValidation.GetNameValidate(formData.remark);
    setFormErrors({
      ...formError,
      remark: validateRemark,
    });
  };

  // set Modal variables and usestate
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const handleOpenModal = () => setOpenModal(true);
  function handleCloseModal() {
    setOpenModal(false);
    setFormData({
      ...formData,
      remark: '',
      campaign_id: '',
      status: '',
      campaign_title: '',
      campaign_visibility: '',
      campaign_owner: '',
      posted_at: '',
    });
  }


  const [reviewed_campaign, setCampaignReviewed] = useState(false);
  const [campaignDetail, setCampaignDetail] = useState<any>([]);
  const [openCampaignModal, setOpenCampaignModal] = useState(false);
  const [performEditCancel, setPerformEditCancel] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = (event: any, buttonId: any) => {
    switch (buttonId) {
      case 'publishButton':
        // setCampaignStatus('Live');
        setCampaignReviewed(false)
        setModalTitle('Please Confirm');
        setModalContent(
          `Click 'Live' to post the campaign '${event.target.dataset.campaign_title}', created By advertiser: ${event.target.dataset.campaign_owner}.`
        );
        setFormData({
          ...formData,
          reviewed_by: props.userData.user_id,
          campaign_id: event.target.dataset.campaign_id,
          status: 'Live',
        });
        break;
      case 'rejectButton':
        // setCampaignStatus('Rejected');
        setCampaignReviewed(false)
        setModalTitle('Please Confirm');
        setModalContent(
          `Click 'Reject' to reject the campaign '${event.target.dataset.campaign_title}', created By advertiser: ${event.target.dataset.campaign_owner}.`
        );
        setFormData({
          ...formData,
          reviewed_by: props.userData.user_id,
          campaign_id: event.target.dataset.campaign_id,
          status: 'Rejected',
        });
        break;
      default:
        setCampaignReviewed(true)
        setModalTitle('Reviewed Campaign Details');
        setModalContent('')
        const dataset = event.target.dataset;
        setFormData({
          ...formData,
          reviewed_by: dataset.reviewed_by,
          campaign_id: dataset.campaign_id,
          status: dataset.status,
          remark: dataset.remark,
          campaign_title: dataset.campaign_title,
          campaign_visibility: dataset.campaign_visibility,
          campaign_owner: dataset.campaign_owner,
          posted_at: dataset.posted_at,
        });
    }
    // Open the modal
    handleOpenModal();
  };
  // end of modal

  // Submit form logic
  const handleConfirmation = async (e: any) => {
    try {
      e.preventDefault();
      // Validate form
      // validating name
      const validateRemark = formValidation.GetNameValidate(formData.remark);
      setFormErrors({
        ...formError,
        remark: validateRemark,
      });
      if (validateRemark.status) {
        try {
          const response = await adminDashboardService.updateReviewCampaigns(
            formData
          );
          if (response.data.status) {
            localService.toastify(response.data.message, 'success', 1000);
            getReviewCampaignsData();
          } else {
            localService.toastify(response.data.message, 'error', 1000);
          }
        } catch (error) {
          console.log(
            'Update Review Campaign Error :: ',
            JSON.stringify(error)
          );
        }
        // Close the modal after handling form submission
        setOpenModal(false);
      }
    } catch (error) {
      console.log('Update Review Campaign Error :: ', JSON.stringify(error));
      // Close the modal after handling form submission
      setOpenModal(false);
    }
  };

  // Separate function to get review campaigns to call it multiple times on form submit;
  async function getReviewCampaignsData() {
    try {
      props.loading(true);
      const res = await adminDashboardService.getReviewCampaigns();
      setReviewCampaignData(res?.data?.rows || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setReviewCampaignData([]);
    } finally {
      props.loading(false);
    }
  }

  useEffect(() => {
    getReviewCampaignsData();
  }, []);

  async function getToCampaign(campaignId: number) {
    try {
      const res = await adminDashboardService.getCampaignDetail(campaignId);
      if (res.status) {
        setCampaignDetail([res.data[0]])
        setOpenCampaignModal(true)
        // navigate('/campaign-detail', { state: { campaignDetail: res.data[0] } });
      } else {
        // navigate(`/brand/orders/confirmed?payment_id=${payment_id}&campaign_id=${props.campaignReducer.createCampaign.campaign_id}`);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setCampaignDetail([]);
      setOpenCampaignModal(false)
    } finally {
    }
  }

  function handleCloseCampaignDetailModal() {
    setOpenCampaignModal(false);
  }



  return (
    <div className='campaign-verification-main'>
      {openModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={openModal}
          onClose={() => handleCloseModal()}
          className="modal-medium mx-auto"
        >
          {reviewed_campaign
            ?
            (
              <div>
                <ul>
                  <li><strong>Campaign Status: </strong>{formData.status}</li>
                  <li><strong>Campaign Title: </strong>{formData.campaign_title}</li>
                  <li><strong>Campaign Visibility: </strong>{formData.campaign_visibility}</li>
                  <li><strong>Campaign Created By: </strong>{formData.campaign_owner}</li>
                  <li><strong>Reviewed By: </strong>{formData.reviewed_by}</li>
                  <li><strong>Published On: </strong>{formData.posted_at ? dayjs(formData.posted_at).format('LL') : ''}</li>
                  <li><strong>Remarks: </strong>{formData.remark}</li>
                </ul>
              </div>
            )
            :
            (<form onSubmit={handleConfirmation}>
              <TextField
                fullWidth
                label='Remark'
                name='remark'
                variant='standard'
                value={formData?.remark}
                error={!formError?.remark?.status}
                helperText={formError?.remark?.message}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    remark: e.target.value,
                  })
                }
                onBlur={handleError}
              />
              <div className='col-lg-auto text-end mt-3'>
                <Button
                  size='small'
                  type='submit'
                  variant='outlined'
                  className={
                    formData.status == 'Live'
                      ? 'btn btn-outline-primary'
                      : 'btn btn-outline-error'
                  }
                >
                  {formData.status === 'Rejected' ? 'Reject' : 'Live'}
                </Button>
              </div>
            </form>)
          }
        </CommonPopUp>
      )}
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className='col-auto nav sub-tabs mb-3'>
        <ul>
          <li className=''>
            <NavLink
              className='nav-link campaign-verification-tab'
              to={'/brand/admin-dashboard/campaign-verification'}
            >
              Campaign verification
            </NavLink>
          </li>
          <li className=''>
            <NavLink
              className='nav-link talent-verification-tab'
              to={'/brand/admin-dashboard/talent-verification'}
            >
              Talent verification
            </NavLink>
          </li>
        </ul>
      </div>
      <TableComponent
        pagination={true}
        columnDisplayName={columnDisplayName}
        inputDataSource={reviewCampaignData}
        orderByColumnName='influencer_name'
        sortingColumnType={sortingColumnType}
        renderData={renderData}
        setRenderData={e => setRenderData(e)}>
        {
          renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} hover role='checkbox'>
                <TableCell>{data.brand_name}</TableCell>
                <TableCell>{data.full_name}</TableCell>
                <TableCell>{data.login_email}</TableCell>
                <TableCell>{data.login_mobile}</TableCell>
                <TableCell><a href="javascript:void(0);" onClick={() => getToCampaign(data.campaign_id)}>{data.campaign_title}</a></TableCell>
                <TableCell>{data.campaign_visibility}</TableCell>
                <TableCell>{data.posted_at ? dayjs(data.posted_at).format('LL') : ''}</TableCell>
                <TableCell>
                  <div className='d-flex'>
                    {
                      (data.remark != '' && data.status != 'Under Review')
                        ?
                        <div>
                          <Button
                            className='btn btn-primary btn-sm'
                            data-campaign_id={data.campaign_id}
                            data-campaign_owner={data.login_email}
                            data-campaign_title={data.campaign_title}
                            data-campaign_visibility={data.campaign_visibility}
                            data-remark={data.remark}
                            data-posted_at={data.posted_at}
                            data-status={data.status}
                            data-reviewed_by={data.reviewed_by}
                            onClick={(e) =>
                              handleButtonClick(e, 'statusButton')
                            }
                          >
                            {`${data.status === 'Rejected' ? 'Rejected' : 'Posted'} on: ` + (data.posted_at ? dayjs(data.posted_at).format('LL') : '')}
                          </Button>
                        </div>
                        :
                        <div>
                          <Button
                            className='btn btn-primary btn-sm'
                            data-campaign_id={data.campaign_id}
                            data-campaign_owner={data.login_email}
                            data-campaign_title={data.campaign_title}
                            data-campaign_visibility={data.campaign_visibility}
                            onClick={(e) =>
                              handleButtonClick(e, 'publishButton')
                            }
                          >
                            Publish
                          </Button>
                          <Button
                            className='btn btn-outline-error btn-sm'
                            style={{ marginLeft: '10px' }}
                            data-campaign_id={data.campaign_id}
                            data-campaign_owner={data.login_email}
                            data-campaign_title={data.campaign_title}
                            data-campaign_visibility={data.campaign_visibility}
                            onClick={(e) =>
                              handleButtonClick(e, 'rejectButton')
                            }
                          >
                            Reject
                          </Button>
                        </div>
                    }
                  </div>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableComponent>


      {openCampaignModal && (
        <>
          <CommonPopUp
            className={`modal-lg  mx-auto`}
            openModal={openCampaignModal}
            modalTitle="Campaign Details:"
            onClose={() => handleCloseCampaignDetailModal()}>
            <div className="upgrade-premium-list">
            <Grid container rowSpacing={2} spacing={2} padding={2}>
              {campaignDetail && campaignDetail.map((detail: any) => (
                <div className='campaign-detail' key={detail.id}>
                  <h2 className='mt-0 mb-3'>Campaign Details</h2>
                  <p><strong>Campaign Title:</strong> {detail.campaign_title}</p>
                  <p><strong>Description:</strong> <div dangerouslySetInnerHTML={{ __html: detail.campaign_description }} /></p>
                    {/* <Grid item xs={12}>
                      <Editor
                        editorValues={
                          detail.campaign_description
                        }
                        getEditorValue={(e) =>
                          // setFormData({
                          //   ...formData,
                          //   campaign_description: e,
                          // })
                        detail.campaign_description
                        }
                      />
                    </Grid> */}
                  <p><strong>Platform:</strong> {detail.campaign_platform}</p>
                  <p><strong>Influencer Gender:</strong> {detail.influencer_gender}</p>
                  <p><strong>Budget:</strong> {detail.budget}</p>
                  {/* <Grid item xs={12} md={6}>
                      <TextField
                        label='Budget'
                        fullWidth
                        size='small'
                        variant='outlined'
                        value={detail.budget}
                      // onChange={(e) => handleChange(index, 'budget', e.target.value)}
                      />
                  </Grid> */}
                  <p><strong>Objective:</strong> {detail.campaign_objective}</p>
                  <p><strong>Type:</strong> {detail.campaign_type}</p>
                  <p><strong>Visibility:</strong> {detail.campaign_visibility}</p>
                  <p><strong>Cost Model:</strong> {detail.campaign_cost_model}</p>
                  <p><strong>Proposal Date:</strong> {detail.proposal_dt}</p>
                  <p><strong>Go Live Date:</strong> {detail.go_live_dt}</p>
                  {/* <Grid item xs={12} md={6}>
                      <TextField
                        label='Proposal Date'
                        type='date'
                        fullWidth
                        size='small'
                        variant='outlined'
                        value={detail.proposal_dt}
                        // onChange={(e) => handleChange(index, 'proposal_dt', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        label='Go Live Date'
                        type='date'
                        size='small'
                        fullWidth
                        variant='outlined'
                        value={detail.go_live_dt}
                        // onChange={(e) => handleChange(index, 'go_live_dt', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid> */}
                  <p><strong>Status:</strong> {detail.status}</p>
                  {detail.campaign_cover_url && (
                    <div>
                      <p><strong>Cover Image:</strong></p>
                      <img src={detail.campaign_cover_url} alt="Campaign Cover" style={{ maxWidth: '350px', maxHeight: '250px', border: '1px solid black', borderRadius: '10px', objectFit: 'contain' }} />
                    </div>
                  )}
                  <p><strong>Comments:</strong> {detail.comments}</p>
                  <p><strong>Posted At:</strong> {detail.posted_at}</p>
                  {/*<p><strong>Reviewed By:</strong> {detail.reviewed_by}</p>
                  <p><strong>Reviewed On:</strong> {detail.reviewed_on}</p> */}
                  <p><strong>Remark:</strong> {detail.remark}</p>
                  {/* <p><strong>Category:</strong> {detail.category}</p> */}
                  <p><strong>Brand:</strong> {detail.brand}</p>
                  <p><strong>Product Name:</strong> {detail.product_name}</p>
                  <p><strong>Product Description:</strong> {detail.product_description}</p>
                  <p><strong>Product Cost:</strong> {detail.product_cost}</p>
                  {detail.product_image_url && (
                    <div >
                      <p><strong>Product Image:</strong></p>
                      <img src={detail.product_image_url} alt="Product" style={{ maxWidth: '350px', maxHeight: '250px', border: '1px solid black', borderRadius: '10px', objectFit: 'contain' }} />
                    </div>
                  )}
                  <p><strong>Product Link:</strong> {detail.product_link}</p>
                </div>
              ))}
            </Grid> 
            </div>
            <div className="popup-footer">
              <div className="row justify-content-end">
                {/* {!performEditCancel && (
                  <div className="col-auto px-0 ms-2">
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setPerformEditCancel(true)}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  </div>
                )}
                {performEditCancel && (
                  <>
                    <div className="col-auto px-0">
                      <Button
                        className=" btn btn-outline-primary"
                        onClick={() => setPerformEditCancel(false)}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-auto px-0 ms-2">
                      <Button
                        className="btn btn-outline-primary"
                        onClick={() => setPerformEditCancel(true)}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </div>
                  </>
                )} */}
                <p><em>"For edits or changes, please contact the Tech team."</em></p>

              </div>
            </div>
          </CommonPopUp>
        </>
      )}

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignVerification);
export { connectToRedux as CampaignVerification };
