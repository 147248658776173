// import { HashRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from 'react';
import TrackingTags from './TrackingTags';
import { Header, AppLoader } from './components';
import { RoutesComponent } from './routes/route';
import { ToastContainer } from 'react-toastify';
// import './assets/styles/style.css';
// import './assets/styles/scss/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { BASE_URL_UI } from './config/config';
// import { PublicService } from './core/services';
import { FbConversionTrack } from './core/services/FbConversionTrackEvents';

// const publicService: PublicService = new PublicService();
const fbConversionEvents: FbConversionTrack = new FbConversionTrack();

// const trackPageView = async () => {
//     try {
//         const url = window.location.href;
//         const response = await publicService.recordFbConversionPageView({ url: url });
//         // console.log('Response:', response);
//     } catch (error) {
//         console.error('Error sending POST request:', error);
//     }
// };

const App: React.FC = () => {
    // Tracking FB conversion page view event.
    const location = useLocation();

    useEffect(() => {
        fbConversionEvents.trackPageView();
    }, [location]);
    // End of tracking FB conversion page view event.
    return (
        <>
            {
                String(BASE_URL_UI).includes("clanconnect.ai") &&
                <TrackingTags />
            }
            <AppLoader />
            <Header />
            <ToastContainer
                autoClose={2000}
                style={{ width: '250px' }}
                position='top-right'
            />
            <div className='main-content-sec'>
                <RoutesComponent />
            </div>
        </>

    );
};

export default App;
