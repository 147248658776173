import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Loading } from "../../../redux/action";
import {
  PublicService,
  LocalService,
  SignUpService,
} from "../../../core/services";
import { DefaultAccountLogo } from "../../../config/config";
import { FormValidation } from "../../../core/utility";
import { MenuPropsSelect } from "../../../core/utility/style";
import FileUploadPopup from '../../../components/popup/file-upload-popup';
import brand_clan_logo from '../../../assets/images/brand_clan_logo.png'

const formValidation: FormValidation = new FormValidation();
const localService: LocalService = new LocalService();
const signupService: SignUpService = new SignUpService();

interface Props {
  userDetails: any;
  loading: (loading: boolean) => void;
  updateShowBrand: (value: boolean) => void;
  data: any;
  popUpOpen:any;
  account_id:any;
}

const AddBrand: React.FC<Props> = ({ userDetails,popUpOpen,data,account_id, ...otherprops }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [industryList, setIndustryList] = useState<any>([]);
  const path = `accounts/${account_id}/brands`;
  const [formData, setFormData] = useState({
    user_id: userDetails?.user_id  || "",
    account_id: userDetails?.account_id || "",
    company_name: data?.brand_organization || '',
    brand_id: data?.brand_id ||"" ,
    influencer_id: data?.influencer_id||"" ,
    // full_name: data?.full_name ||"",
    industry_id: data?.industry_id||"",
    industry_name: data?.brand_category||"" ,
    logo_url: data?.logo_url || brand_clan_logo,
    website: data?.website || "",
  });

  const [formError, setFormError] = useState({
    company_name: { status: true, message: "" },
    // full_name: { status: true, message: "" },
    industry_id: { status: true, message: "" },
    website: { status: true, message: "" },
  });

  useEffect(() => {
    
    setFormData((prevData) => ({
      ...prevData,
      company_name: data?.brand_organization || '',
      brand_id: data?.brand_id ||"" ,
      influencer_id: data?.influencer_id||"" ,
      // full_name: data?.full_name ||"",
      industry_id: data?.industry_id||"",
      industry_name: data?.brand_category||"" ,
      logo_url: data?.logo_url || brand_clan_logo,
      website: data?.website || "",
    }));

    otherprops.loading(true);
    PublicService.industryList()
      .then((res) => {
        setIndustryList(res?.data?.rows);
        otherprops.loading(false);
      })
      .catch((error) => {
        otherprops.loading(false);
        console.log(
          `SignUp Brand industryList error :: ${JSON.stringify(error)}`
        );
      });

    setFormError({
      ...formError,
      company_name: { status: true, message: "" },
      // full_name: { status: true, message: "" },
      industry_id: { status: true, message: "" },
      website: { status: true, message: "" },
    })
  }, [data]);

  const handleIndustry = (e: any) => {
    const {
      target: { value },
    } = e;

    let industry_name = industryList.filter(
      (industry: any) => industry.industry_id === value
    )[0].industry;

    setFormData((prevData) => ({
      ...prevData,
      industry_id: value,
      industry_name: industry_name,
    }));
  };

  const handleClose =(e:any)=>{
    setFormData({
      ...formData,
      company_name: "",
      brand_id: null,
      influencer_id:"",
      // full_name:"",
      industry_id:"",
      industry_name:"",
      logo_url:"",
      website:"",
    });

    setFormError({
      ...formError,
      company_name: { status: true, message: "" },
      // full_name: { status: true, message: "" },
      industry_id: { status: true, message: "" },
      website: { status: true, message: "" },
    });

    popUpOpen(false)

  }

  async function addBrand() {
    try {
      //validation
      let isValidWebsite:any;
      const { company_name, 
        // full_name, 
        industry_id, website } = formData;
      const isValidCompanyName = formValidation.GetNameValidate(company_name);
      // const isValidFullName = formValidation.GetNameValidate(full_name);
      const isValidIndustry = industry_id
        ? { status: true, message: "" }
        : { status: false, message: "Industry is required" };
      if(formData.website != ''){
        isValidWebsite = formValidation.GetWebsiteValidate(website);
      }else{
        isValidWebsite = {status: true, message: ""}
      }
      setFormError((prevState) => ({
        ...prevState,
        company_name: isValidCompanyName,
        // full_name: isValidFullName,
        industry_id: isValidIndustry,
        website: isValidWebsite,
      }));
      if (
        isValidCompanyName.status &&
        // isValidFullName.status &&
        isValidIndustry.status &&
        isValidWebsite.status
      ) {
        try {
          setLoading(true);
          const response = await signupService.addBrand(formData);
          const checkStatus = response.data.status.status ? "success" : "error";
          localService.toastify(response.data.message, checkStatus, 1000);
          if (checkStatus === "success") {
            otherprops.updateShowBrand(false);
            setFormData({
              ...formData,
              company_name: "",
              brand_id: "",
              influencer_id:"",
              // full_name:"",
              industry_id:"",
              industry_name:"",
              logo_url:"",
              website:"",
            });
            popUpOpen(false)
            
          }
        } catch (error) {
          throw new Error(`Add Brand Error :: ${JSON.stringify(error)}`);
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(`addbrand failed: ${error}`);
    }
  }


  async function updateBrand() {
    try {
      //validation
      let isValidWebsite:any;
      const { company_name, 
        // full_name, 
        industry_id, website } = formData;
      const isValidCompanyName = formValidation.GetNameValidate(company_name);
      // const isValidFullName = formValidation.GetNameValidate(full_name);
      const isValidIndustry = industry_id
        ? { status: true, message: "" }
        : { status: false, message: "Industry is required" };
      if(formData.website != ''){
        isValidWebsite = formValidation.GetWebsiteValidate(website);
      }else{
        isValidWebsite = {status: true, message: ""}
      }
      // set error in state
      setFormError((prevState) => ({
        ...prevState,
        company_name: isValidCompanyName,
        // full_name: isValidFullName,
        industry_id: isValidIndustry,
        website: isValidWebsite,
      }));

      if (
        isValidCompanyName.status &&
        // isValidFullName.status &&
        isValidIndustry.status &&
        isValidWebsite.status
      ) {
        try {
          setLoading(true);
          const response = await signupService.updateBrand(formData);
          if (response.data.status.status) {
            otherprops.updateShowBrand(false);
            setFormData({
              ...formData,
              company_name: "",
              brand_id: "",
              influencer_id:"",
              // full_name:"",
              industry_id:"",
              industry_name:"",
              logo_url:"",
              website:"",

            });

            popUpOpen(false)

          }
        } catch (error) {
          throw new Error(`Update Brand Error :: ${JSON.stringify(error)}`);
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(`Update Brand Failed: ${error}`);
    }
  }

  return (
    <div className="addbrand-container">
      <Grid container spacing={2} >
        <Grid item xs={12} >
          <TextField
            className="w-100"
            size="small"
            id="company_name_id"
            name="company_name"
            placeholder="Company Name"
            label="Company Name"
            variant="outlined"
            error={!formError.company_name.status}
            helperText={formError.company_name.message}
            value={formData?.company_name}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                company_name: e.target.value,
              }))
            }
          />
        </Grid>
        {/* <Grid item xs={12} >
          <TextField
            className="w-100"
            size="small"
            id="full_name_id"
            name="full_name"
            placeholder="Your Full Name"
            label="Full Name"
            variant="outlined"
            error={!formError.full_name.status}
            helperText={formError.full_name.message}
            value={formData?.full_name}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                full_name: e.target.value,
              }))
            }
          />
        </Grid> */}
        <Grid item xs={12} >
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Industry Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Industry Type"
              value={formData.industry_id}
              error={!formError.industry_id.status}
              name="industry"
              onChange={handleIndustry}
              MenuProps={MenuPropsSelect}
            >
              {industryList?.length > 0 ? (
                industryList?.map((res: any) => {
                  return (
                    <MenuItem
                      key={res.industry_id}
                      id={res.industry_id}
                      value={res.industry_id}
                    >
                      {res.industry}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem>No Data</MenuItem>
              )}
            </Select>
            {!formError.industry_id.status && (
              <span className="error" style={{ margin: '4px 14px 0px' }}>
                {formError.industry_id.message}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} >
          <TextField
            className="w-100"
            size="small"
            id="id_website"
            name="website"
            value={formData.website}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                website: e.target.value,
              }))
            }
            placeholder="www.yourwebsite.com"
            label="Website (Optional)"
            variant="outlined"
            error={!formError.website.status}
            helperText={formError.website.message}
          />
        </Grid>
        <Grid item xs={12}>
            {' '}
              <div className='cover_image_section '>
                {showUploadPopup && (
                  <FileUploadPopup
                    openModal={showUploadPopup}
                    onClose={(e) => setShowUploadPopup(false)}
                    onSend={(e: any) => {
                     setFormData((prevData) => ({
                        ...prevData,
                        logo_url: e.url,
                        }));
                        }}
                    path={path}
                    acceptedFileTypes=".jpg, .jpeg, .png, .pdf"
                  />
                )}
                <img
                  aria-label='cropped image'
                  className='campaign_cover_image'
                  alt='cover'
                  src={
                    formData.logo_url === ''
                    ? brand_clan_logo
                    : formData.logo_url
                    }
                />
                <Button
                className='btn btn-outline-primary upload_button'
                onClick={() => setShowUploadPopup(true)}
                variant='contained'
                startIcon={<FileUploadIcon />}
                >
                   Upload Logo
                </Button>
              </div>
          </Grid>
        <Grid item xs={12} className='d-flex justify-content-end'>
          <>
            <Button
              className="btn btn-outline-primary me-2"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </>
          <>
            { 
              formData?.brand_id != "" ?
                (
                  <LoadingButton
                    className="btn btn-primary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    type="button"
                    onClick={updateBrand}
                  >Update Brand</LoadingButton>
                )
                :
                (
                  <LoadingButton
                    className="btn btn-primary"
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    type="button"
                    onClick={addBrand}
                  >Add Brand</LoadingButton>
                )
            }
          </>

        </Grid>
      </Grid>

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.SwitchLayer2Reducer.switchUser,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(AddBrand);

export { connectToRedux as AddBrand };
