
import React,{ useEffect } from 'react';
import './live-dashboard.scss';
import { useLocation } from 'react-router-dom';
import { LiveDashboardService } from '../../core/services/live-dashboard.service';
import { useNavigate } from 'react-router-dom';

const LiveDashboard: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('sessionId');

  const [liveData, setLiveData] = React.useState<any>([]);

  useEffect(() => {
    // Fetch live data initially
    LiveDashboardService.getLiveDashboardData(sessionId)
      .then((res: any) => {
        const data = res?.data?.rows || [];
        if (data.length === 0) {
          navigate('/');
        } else {
          setLiveData(data);
        }
    })
      .catch((e: any) => setLiveData([]));

    // Set up interval to fetch live data every 5 seconds
    const intervalId = setInterval(() => {
        LiveDashboardService.getLiveDashboardData(sessionId)
        .then((res: any) => {
            const data = res?.data?.rows || [];
            if (data.length === 0) {
              navigate('/');
            } else {
              setLiveData(data);
            }
        })
        .catch((e: any) => setLiveData([]));
    }, 5000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    {liveData.length > 0 &&
    <section className="subscriber-count-sec">
    <div className="container">
      <div className="total-user-and-active-subscription">
        <div className="subscriber-count-total">
          Total Users
          <strong id="total-users-count">{liveData[0]?.total_users}</strong>
        </div>
        <div className="subscriber-count-total">
          Active Subscriptions
          <strong id="total-active-subscription-count">{liveData[0]?.active_subscriptions}</strong>
        </div>
      </div>
      <div className="row w-100 mb-4" >
        <strong className="title-w-bdr"><span>Signup</span></strong>
        <div className="row w-100 mx-auto">
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Brand</span><strong id="brand-count">{liveData[0]?.total_brand}</strong>
            </div>
            <div className="other-signup-data-split">
              <span>Free <strong id="brand-count-free">{liveData[0]?.total_brand-liveData[0]?.brand_paid}</strong></span>
              <span>Paid<strong id="brand-count-paid">{liveData[0]?.brand_paid}</strong></span>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Influencer</span><strong id="influencer-count">{liveData[0]?.total_influencer}</strong>
            </div>
            <div className="other-signup-data-split">
              <span>Free <strong id="influencer-count-free">{liveData[0]?.total_influencer-liveData[0]?.influencer_paid}</strong></span>
              <span>Paid<strong id="influencer-count-paid">{liveData[0]?.influencer_paid}</strong></span>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Agency</span><strong id="agency-count">{liveData[0]?.total_agency}</strong>
            </div>
            <div className="other-signup-data-split">
              <span>Free <strong id="agency-count-free">{liveData[0]?.total_agency-liveData[0]?.agency_paid}</strong></span>
              <span>Paid<strong id="agency-count-paid">{liveData[0]?.agency_paid}</strong></span>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Talent Partner</span><strong id="talent-count">{liveData[0]?.total_talent_partner}</strong>
            </div>
            <div className="other-signup-data-split">
              <span>Free <strong id="talent-count-free">{liveData[0]?.total_talent_partner-liveData[0]?.talent_partner_paid}</strong></span>
              <span>Paid<strong id="talent-count-paid">{liveData[0]?.talent_partner_paid}</strong></span>
            </div>
          </div>
        </div>
      </div>
       {/* Total Subscriptions */}
      <div className="row w-100 mb-5">
        <strong className="title-w-bdr"><span>Active Subscriptions</span></strong>
        <div className="row w-100 mx-auto">
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Brand</span><strong id="total-subscription-count-brand">{liveData[0]?.active_brand}</strong>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Influencer</span><strong id="total-subscription-count-influencer">{liveData[0]?.active_influencer}</strong>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Agency</span><strong id="total-subscription-count-agency">{liveData[0]?.active_agency}</strong>
            </div>
          </div>
          <div className="other-signup-data">
            <div className="other-signup-data-main">
              <span>Talent Partner</span><strong id="total-subscription-count-talent">{liveData[0]?.active_talent_partner}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  }
</>
  );
};

export default LiveDashboard;