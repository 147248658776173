import React, { useEffect, useState } from 'react';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import HeadingStyle from '../../../components/heading-style/heading-style';
import { Tab, Tabs } from '@mui/material';
import './main-settings.scss';
import { connect } from 'react-redux';
import { Loading } from '../../../redux/action';
// interface LinkTabProps {
//   label?: string;
//   href?: string;
// }
interface Props {
  userData: any;
}

const InfluencerSetting: React.FC<Props> = (props: Props) => {

  return (
    <div className='profile_settings_container'>
      <div className='container'>
        <HeadingStyle
          title='Profile Settings'
          className='pb-4 d-block'
        />
        <div className='row profile_settings_main_container'>
          <Tabs
            aria-label='nav tabs example'
            variant='scrollable'
            scrollButtons='auto'
            allowScrollButtonsMobile
            className='nav sub-tabs nav-rounded d-flex d-md-none profile-menu-mobile tab-underline'
            TabIndicatorProps={{ hidden: true }}
          >
            <NavLink
              className='nav-link'
              to={'personal-info'}
            >
              Personal Info
            </NavLink>
            <NavLink
              className='nav-link'
              to={'socialconnect'}
            >
              Social Connect
            </NavLink>
            <NavLink
              className='nav-link'
              to={'companydetails'}
            >
              Company Details
            </NavLink>
            <NavLink
              className='nav-link'
              to={'shippingaddress'}
            >
              Address
            </NavLink>
            <NavLink
              className='nav-link'
              to={'security'}
            >
              Security
            </NavLink>
            <NavLink
              className='nav-link'
              to={'subscription'}
            >
              Subscription
            </NavLink>
            <NavLink
              className='nav-link'
              to={'paymentmethods'}
            >
              Banking Details
            </NavLink>
            <NavLink
              className='nav-link'
              to={'notifications'}
            >
              Notification
            </NavLink>
          </Tabs>
          <div className='col-md-3 profile_settings_left_side d-none d-md-flex list-section-sidebar'>
            {
              props.userData?.account_type !== 'Talent Partner' ? (
                <ul className='profile_settings_menu'>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'personal-info'}
                    >
                      Personal Info
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'socialconnect'}
                    >
                      Social Connect
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'companydetails'}
                    >
                      Company Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'shippingaddress'}
                    >
                      Address
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'security'}
                    >
                      Security
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'subscription'}
                    >
                      Subscription
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'subscription-history'}
                    >
                      Subscription history
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'paymentmethods'}
                    >
                      Banking Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'notifications'}
                    >
                      Notification
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className='profile_settings_menu'>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'brand/settings/personal-info'}
                    >
                      Personal Info
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'companydetails'}
                    >
                      Company Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'brand-list'}
                    >
                      Talent List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'subscription'}
                    >
                      Subscription
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'subscription-history'}
                    >
                      Subscription history
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'paymentmethods'}
                    >
                      Banking Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={'profile_settings_navlinks list-section-sidebar-link'}
                      to={'notifications'}
                    >
                      Notification
                    </NavLink>
                  </li>
                </ul>
              )}
          </div>

          <div className='col-md-9 profile_settings_right_side'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerSetting);
export { connectToRedux as InfluencerSetting };
