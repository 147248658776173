import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  LocalService,
  SubscriptionService,
} from '../../../../core/services';
import UseAnimations from 'react-useanimations';
import download from 'react-useanimations/lib/download'
import { CryptoJS } from '../../../../core/utility';
import { BASE_URL_UI } from '../../../../config/config';

// const localService: LocalService = new LocalService();
const subscriptionService: SubscriptionService = new SubscriptionService();
// Create an instance of the CryptoJS class
const cryptojs = new CryptoJS();

interface Props {
  UserReducer: any;
}

const UserSubscriptionHistory: React.FC<Props> = (props: Props) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState<any>(null);
  const navigate = useNavigate();

  const getSubscriptionDetail = useCallback(() => {
    //get Subscription Detail
    const { user_id, account_id, account_type, influencer_id } = props.UserReducer;

    async function getSubscriptionHistory() {
      try {
        const response = await subscriptionService.getSubscription_History({
          user_id: user_id,
          account_id: account_id,
          influencer_id: influencer_id
        });
        if (response.data.status.status) {
          setSubscriptionHistory(response.data.rows);
        } else {
          // localService.toastify(response.data.status.message, 'info', 1000);
          setSubscriptionHistory(null);
        }
      } catch (error) {
        console.log(`error Subscription History :: ${JSON.stringify(error)}`);
      }
    }

    getSubscriptionHistory();
  }, [props.UserReducer]);

  async function downloadInvoice(data: { order_id: number }) {
    try {
      let token = cryptojs.encryptMessage(JSON.stringify({order_id: data.order_id}), BASE_URL_UI)
      token = encodeURIComponent(token)
      const url = `${window.location.origin}/subscription/invoice?token=${token}`;
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  }

  useEffect(() => {
    getSubscriptionDetail();
  }, [getSubscriptionDetail]);

  return (
    <>
      <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
        <div className='list-section-content rounded'>
          <div className='row align-items-center'>
            <strong className='col'>Subscription History</strong>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col'>
            {/* Subscription Table */}
            {subscriptionHistory && (
              <TableContainer
                id='table_id'
              >
                <Table
                  stickyHeader
                  className='common-table'
                  // sx={{ minWidth: 650 }}
                  aria-label='simple table'
                >
                  <TableHead className='common-table-header'>
                    <TableRow>
                      <TableCell align='left'>#</TableCell>
                      <TableCell align='left'>Invoice</TableCell>
                      <TableCell align='left'>Plan Type</TableCell>
                      <TableCell align='left'>Status</TableCell>
                      <TableCell align='left'>Amount</TableCell>
                      <TableCell align='left'>Plan End</TableCell>
                      <TableCell align='left'>Purchased Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='common-table-body'>
                    {/* Get Data via Api */}
                    <>
                      {subscriptionHistory.map((data: any, index: number) => {
                        return (
                          <TableRow key={index} className={data.captured === "true" ? 'subscription-success' : 'subscription-failed'}>
                            <TableCell
                              className='table_cell'
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align='left'>
                              {data.captured === 'true' && (
                                <>
                                  <UseAnimations
                                    animation={download}
                                    onClick={() => {
                                      downloadInvoice(data);
                                    }}
                                    size={30}
                                    className='cursor-pointer'
                                  />
                                </>
                              )}
                            </TableCell>
                            <TableCell align='left'>
                              {data.plan_type}
                            </TableCell>
                            <TableCell align='left'>
                              {data.captured === 'true' ? 'Success' : 'Failed'}
                            </TableCell>
                            <TableCell align='left'>{data.amount}</TableCell>
                            <TableCell align='left'>
                              {dayjs(data.plan_end).format('LL')}
                            </TableCell>
                            <TableCell align='left'>
                              {dayjs(data.purchased_date).format('LL')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const connectToRedux = connect(
  mapStateToProps
)(UserSubscriptionHistory);

export { connectToRedux as UserSubscriptionHistory };
