import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  GetUploadedURL,
  DeleteUploadURL,
  AddUploadURL,
  UpdateUploadURL,
  ResponseType,
  AnalyticsBrand
} from "custom-type";

const http = new HttpService();

export class AnalyticsService {
  async getUploadedUrl(data: GetUploadedURL): Promise<ResponseType> {
    const path = BASE_URL + "/get/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async deleteUploadURL(data: DeleteUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/delete/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async addUploadURL(data: AddUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/add/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async updateUploadURL(data: UpdateUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/update/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async brandAnalytics(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/brand/analytics";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async brandAnalyticsYoutube(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/brand/analytics-yt";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async getAnalyticsLinks(campaignId: any) {
    const path = BASE_URL + `/analytics/postlinks/details?campaign_id=${campaignId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Get getAnalyticsLinks Error :: ${error}`);
    }
  }

  async analyticsRefreshInight(data: any) {
    const path = BASE_URL + '/analytics/update/insights';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsRefreshInight :: ${error}`);
    }
  }

}
