import {
    Button,
    TextField,
    Grid,
} from '@mui/material';
import React, { useEffect, useState, Fragment } from 'react';
import './../../influencer/settings/personal-info/personal_info.scss';
import SampleProfile from '../../../assets/images/avtar_male.png';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { FormValidation, Methods } from '../../../core/utility';
import {
    LocalService,
    UserService,
} from '../../../core/services';
import { Loading } from '../../../redux/action';
import { connect, useDispatch } from 'react-redux';
import { Security } from '../../common/security/security'
import { ImageCrop } from '../../../components';
import { UserLogin } from '../../../redux/action';


// Create an instance of LocalService class
const localService: LocalService = new LocalService();
// Create an instance of UserService class
const userService: UserService = new UserService();

// To use predefined methods, to calculate age and get formatted date
const platformMethod = new Methods();

interface Props {
    loading: (data: any) => any;
    userData: any;
    updateAccountImage: (data: any) => void;
}

const AgencyPersonalInfo: React.FC<Props> = (props: Props) => {
    const [disableField, setDisableField] = useState(true);
    const [buttonName, setButtonName] = useState('Edit');
    const [cameraIcon, setCameraIcon] = useState(false);
    const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
    const path = `accounts/${props.userData.account_id}/users`


    // Create new instance from FormValidation class
    const formValidation = new FormValidation();

    // Set form fields
    const [formData, setFormData] = useState<any>({
        user_id: props.userData.user_id,
        full_name: '',
        login_mobile: '',
        login_email: '',
        photo: '',
        image_path: '',
        mobile_verified: '',
        email_verified: ''
    });

    // Set form error fields
    const [formError, setFormErrors] = useState<any>({
        full_name: { status: true, message: '' },
        photo: { status: true, message: '' }
    });

    useEffect(() => {
        props.loading(true);
        getUserDetails();

    }, []);

    /* Get user Info */
    async function getUserDetails() {
        props.loading(true);
        try {
            const response = await userService.getUserDetails(`${props.userData.user_id}`);
            if (response.data.status) {
                setFormData({
                    ...formData,
                    ...response.data.rows[0]
                })
            }
        } catch (error) {
            console.log(`getUserDetails error :: ${JSON.stringify(error)}`)
        } finally {
            props.loading(false)
        }
    };

    // Submit form logic
    const handleUserInfoFormData = async (e: any) => {
        try {
            e.preventDefault();
            // Validate form
            // validating name
            const validateName = formValidation.GetNameValidate(
                formData.full_name
            );
            const validateImage = formValidation.GetNameValidate(
                formData.photo
            );
            setFormErrors({
                full_name: { ...validateName },
                photo: { ...validateImage }
            });
            if (buttonName === 'Edit') {
                setButtonName('Update');
                setDisableField(false);
                setCameraIcon(true)
            }
            else if (validateName.status) {
                try {
                    props.loading(true);

                    const res = await userService.updateUserInfo(formData);

                    if (res.data.status) {
                        // to update image in props
                        props.updateAccountImage({ ...props.userData, ...formData });
                        let localData = localService.getStorageItem('userreducer');
                        if (localData) {
                            localData = { ...JSON.parse(localData), ...formData };
                            localService.setStorageItem('userreducer', JSON.stringify(localData));
                        }
                        setButtonName('Edit');
                        setDisableField(true);
                        setCameraIcon(false);
                        localService.toastify(res.data.message, 'success', 1000);
                    }
                    else {
                        localService.toastify(res.data.message, 'error', 1000);
                    }

                    // Remove loader
                    props.loading(false);

                    return res.data.rows;
                } catch (error) {
                    // Remove loader
                    props.loading(false);
                    throw new Error(`Handle Personal Info Save Error :: ${error}`);
                }
            }
        } catch (error) {
            // Remove loader
            props.loading(false);
            throw new Error(
                `Influencer Account Update function error :: ${JSON.stringify(error)}`
            );
        }
    }

    const handleError = (event: any) => {
        const validateName = formValidation.GetNameValidate(
            formData.full_name
        );
        const validateImage = formValidation.GetNameValidate(
            formData.photo
        );
        setFormErrors({
            full_name: { ...validateName },
            photo: { ...validateImage }
        });
    }

    const handlePhoto = (photo_url: any) => {
        // set uploaded photo in formData
        setFormData({
            ...formData,
            photo: photo_url,
        })
    }

    return (
        <Fragment>
            <form onSubmit={handleUserInfoFormData} className='mb-4'>
                <div className='p-0  _influencer_setting_personal_info_main'>
                    <div className='row pb-3'>
                        <div className='col'>
                            <strong className='h3'>{props.userData?.account_name}</strong>
                        </div>

                        <div className='col-lg-auto text-end'>
                            <Button
                                size='small'
                                type='submit'
                                variant='contained'
                                className='btn btn-outline-primary'
                            >
                                {buttonName}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className='row mb-3'>
                            <div className='col-auto _influencer_setting_img'>
                                <img
                                    src={formData.photo === ''
                                        ? SampleProfile
                                        : formData.photo}
                                    alt={SampleProfile}
                                    style={{ position: "relative" }}
                                />

                                {cameraIcon && <AddAPhotoIcon className='add-photo-icon'
                                    onClick={() => { setShowUploadPopup(!showUploadPopup) }} />}

                                <div className='col-lg-3 '>
                                    {showUploadPopup && (
                                        <ImageCrop
                                            ratio={1 / 1}
                                            path={path}
                                            popup={(e) => setShowUploadPopup(e)}
                                            onCroped={(e) =>
                                                handlePhoto(e.url)
                                            }
                                        />
                                    )}
                                </div>

                            </div>
                            <div className='col'>
                                <TextField
                                    className='input-common'
                                    fullWidth
                                    label='Name of the Agency'
                                    name='full_name'
                                    variant='standard'
                                    value={props.userData?.account_name}
                                    disabled={disableField}
                                    error={!formError?.full_name?.status}
                                    helperText={
                                        formError?.full_name?.message
                                    }
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            full_name: e.target.value,
                                        })
                                    }
                                    onBlur={handleError}
                                />
                            </div>
                        </div>
                    </div>
                    <Grid container rowSpacing={2} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className='influencer_personal_info input-common'
                                size='small'
                                fullWidth
                                name='login_email'
                                type='email'
                                label='Email'
                                variant='outlined'
                                defaultValue={formData?.login_email}
                                disabled={true}
                                key={formData?.login_email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className='influencer_personal_info input-common'
                                size='small'
                                fullWidth
                                name='login_mobile'
                                type='number'
                                label='Mobile'
                                variant='outlined'
                                defaultValue={formData?.login_mobile}
                                disabled={true}
                                key={formData?.login_mobile}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className='influencer_personal_info input-common'
                                size='small'
                                fullWidth
                                name='email_verified'
                                label='Email verified'
                                variant='outlined'
                                defaultValue={formData?.email_verified}
                                disabled={true}
                                key={formData?.email_verified}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className='influencer_personal_info input-common'
                                size='small'
                                fullWidth
                                name='mobile_verified'
                                label='Mobile verified'
                                variant='outlined'
                                defaultValue={formData?.mobile_verified}
                                disabled={true}
                                key={formData?.mobile_verified}
                            />
                        </Grid>
                    </Grid>
                </div>
            </form>
        </Fragment>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
        updateAccountImage: (data: any) => dispatch(UserLogin(data))
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyPersonalInfo);
export { connectToRedux as AgencyPersonalInfo };
