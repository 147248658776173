import React, { useState } from 'react';
import CommonPopUp from './common-popup';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { Button, TextField } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { HandlesService } from '../../core/services/handles.service';
import { LocalService, PublicService, UserService } from '../../core/services';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login';
import { FACEBOOK_APP_ID } from '../../config/config';
import InstagramIcon from '../icons/instagram-icon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import verified from '../../assets/images/verified.svg';
import { UserLogin } from '../../redux/action';
import { connect } from 'react-redux';
import Loading from '../loader/loading';
import SocialConnectRestriction from '../popup/social-connect-restrict';
import { BASE_URL_UI } from '../../config/config';
import { createPortal } from 'react-dom';
import GenericSwipeableDrawer from '../generic-swipe-drawer/generic-swiper-drawer';
import GuideToConnectContent from './guide-to-connect-content';
interface ApplyRestrictionPopupProps {
    openModal: boolean;
    onClose: (closed: boolean) => void;
    applyRestrictionData: any;
    platform: string;
    UserReducer: any;
    updateUser: any;
    stateToUpdateUserReducer?: any
    stateToUpdateUserReducerfuntion?: (data: boolean)=>void;
}

const handlesService = new HandlesService();
const localService = new LocalService();
const publicService = new PublicService();
const userService = new UserService();

const ApplyRestrictionPopup: React.FC<ApplyRestrictionPopupProps> = ({ openModal, onClose, applyRestrictionData, platform, ...props }) => {

    const [login, setLogin] = React.useState(false);
    const [data, setData] = React.useState<any>({});
    const [picture, setPicture] = React.useState('');
    const [connectButton, setConnectButton] = React.useState('Connect');
    const [facebookAccounts, setFacebookAccounts] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false);
    const [username, setUserName] = React.useState('');
    const [userNameError, setUserNameError] = React.useState('');
    const [faceBookButton, setFaceBookButton] = React.useState(true);
    const [verifyUserName, setVerifyUserName] = React.useState(true);
    const [socialRestrictionPopup_YT, setSocialRestrictionPopup_YT] = React.useState(false);
    const [socialRestrictionPopup_IG, setSocialRestrictionPopup_IG] = React.useState(false);
    const [channel_details, setChannel_details] = React.useState<any>({});
    const [google_auth_response, setGoogle_auth_response] = React.useState<any>({});
    const [responseUsernameIG, setResponseUsernameIG] = React.useState<any>('');
    const [stateToUpdateUserReducer, setStateToUpdateUserReducer] = React.useState<boolean>(props.stateToUpdateUserReducer);

    const handleCloseSocialPopup_YT = () => {
        setSocialRestrictionPopup_YT(false);
    }

    const handleContinueConnect_YT = () => {
        setSocialRestrictionPopup_YT(false);
        handleContinueFuntionality_YT();
    }

    const handleCloseSocialPopup_IG = () => {
        setResponseUsernameIG('');
        setSocialRestrictionPopup_IG(false);
    }

    const handleContinueConnect_IG = () => {
        setSocialRestrictionPopup_IG(false);
        faceBookAction();
    }

    const handleContinueFuntionality_YT = async () => {
        try {
            setLoading(true);
            const res = await handlesService.handleContinueGoogle({
                google_auth_response: google_auth_response,
                channel_details: channel_details,
                influencer_id: props?.UserReducer?.influencer_id,
            });

            if (res.data.status.status) {
                handlesService
                    .getGoogleConnectedAccounts(
                        props?.UserReducer?.influencer_id
                    )
                    .then((res: any) => {
                        if (res?.data?.rows.length > 0) {
                            // const connectedAccountData =
                            //     res?.data?.rows.filter(
                            //         (filter: any) =>
                            //             filter.token_status ===
                            //             'Connected'
                            //     );
                            // setGoogleAccount(connectedAccountData);
                            // setDisconnectButtonGoogle(false);
                            // setConnectButtonGoogle('Reconnect');
                        }
                    })
                    .catch((err: any) => console.log(err));
                updateUserReducer();
            }

        } catch (error) {
            console.log('error', error);
        }finally{
            setLoading(false);
        }
    }

    const loginGoogle = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
        onSuccess: async (tokenResponse: any) => {
            const data: any = {
                code: tokenResponse.code,
                influencer_id: props?.UserReducer?.influencer_id,
            };
            try{
                setLoading(true);
            handlesService
                .googleAuth(data)
                .then((res: any) => {
                    if (res.data.status.status) {
                        if (res.data.status.message === 'Account exists') {
                            setSocialRestrictionPopup_YT(true);
                            setChannel_details(res.data.status.channel_details);
                            setGoogle_auth_response(res.data.status.google_auth_response);
                            return;
                        }
                        handlesService
                            .getGoogleConnectedAccounts(
                                props?.UserReducer?.influencer_id
                            )
                            .then((res: any) => {
                                if (res?.data?.rows.length > 0) {
                                    // const connectedAccountData =
                                    //     res?.data?.rows.filter(
                                    //         (filter: any) =>
                                    //             filter.token_status ===
                                    //             'Connected'
                                    //     );
                                    // setGoogleAccount(connectedAccountData);
                                    // setDisconnectButtonGoogle(false);
                                    // setConnectButtonGoogle('Reconnect');
                                }
                            })
                            .catch((err: any) => console.log(err));
                        updateUserReducer();
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
            }catch(err){
                console.log(err);
            }finally{
                setLoading(false);
            }
            },
        onError: (errorResponse) => console.log(errorResponse),
    });


    async function faceBookAction(e?: any) {

        try {
            setLoading(true);
            if(username != responseUsernameIG){
            const res = await handlesService.checkIgHandle({
                username: username,
                influencer_id: props?.UserReducer?.influencer_id,
            });

            if (res?.data?.status?.status) {
                if (res.data.status.message === 'Account exists') {
                    setSocialRestrictionPopup_IG(true);
                    setResponseUsernameIG(res.data.status.username);
                    return;
                }
            }}
            const getPublicData = await publicService.instagramVerify(username);
            if (getPublicData?.status === 1) {
                // setInfluencerData(getPublicData.data);
                localService.setStorageItem('influencerData', JSON.stringify(getPublicData.data))
                setFaceBookButton(false);
                setLoading(false);
                toast.success('Username Verified');
            } else {
                setLoading(false);
                toast.success('Username not verified, This is not a Business/Creator account');
            }

        } catch (err) {
            console.log('Error faceBookAction ', err);
            setLoading(false);
        }finally{
            setLoading(false);
        }
    }

    const responseFacebook = async (response: any) => {
        try {
            if (response.status === 'unknown') {
                alert('Login failed!');
                setLogin(false);
                return false;
            }
            setLoading(true);
            setData(response);
            setPicture(response.picture.data.url);

            if (response.accessToken) {
                let influencer_data = localService.getStorageItem('influencerData');
                let influencerData = JSON.parse(influencer_data || '');
                localStorage.removeItem('influencerData');
                const data = {
                    influencer_id: props?.UserReducer?.influencer_id,
                    shortLiveToken: response.accessToken,
                    account_id: props.UserReducer.account_id,
                    facebookUserId: response.id,
                    facebookName: response.name,
                    facebookEmail: response.email ? response.email : '',
                    facebookProfileImage: response?.picture?.data?.url
                        ? response?.picture?.data?.url
                        : '',
                    influencerData: influencerData
                };
                setLogin(true);
                const facebookHandlesResponse: any = await
                    handlesService.facebookHandles(data)
                if (facebookHandlesResponse?.data?.status) {
                    toast.success(facebookHandlesResponse?.data?.status.message);
                    const getConnectedAccountsResponse: any = await handlesService
                        .getConnectedAccounts(
                            props?.UserReducer?.influencer_id
                        )

                    if (getConnectedAccountsResponse?.data?.rows.length > 0) {
                        getConnectedAccountsResponse?.data?.rows.find(
                            (filter: any) => {
                                if (
                                    filter.account_type ===
                                    'Primary' && filter.token_status === 'Connected') {

                                }
                            }
                        );
                    }
                } else {
                    setLoading(false);
                    toast.error(facebookHandlesResponse.data?.rows?.message)
                }
                updateUserReducer();
            } else {
                setLogin(false);
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            console.log('Error responseFacebook', error);
        }

    }


    const handleStateUpdate = (value: any) => {
        setUserName(value);
        setVerifyUserName(!value);
    };

    const handlePaste = (e: any) => {
        const pastedText = e.clipboardData.getData('Text');
        handleStateUpdate(pastedText); // Or handle the pasted text as needed
    };
    const handleInput = (e: any) => {
        const inputValue = e.target.value;
        handleStateUpdate(inputValue); // Update the state or handle input
        setUserName(inputValue); // Set the username
        inputValue ? setVerifyUserName(false) : setVerifyUserName(true); // Enable/disable button
    };
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );
    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleGuideDrawer = () => {
        setDrawerOpen(true);
    };

    async function updateUserReducer() {
        let latestUserReducerData = await userService.getUpdatedUserReducer(props.UserReducer);
        if (![undefined, null, ''].includes(latestUserReducerData)) {
            props.updateUser(latestUserReducerData)
        }
        if (props.stateToUpdateUserReducerfuntion) {
            props.stateToUpdateUserReducerfuntion(!stateToUpdateUserReducer);
        }
    }

    return (
        <>
        <CommonPopUp
            modalTitle="Requirements to apply on a campaign"
            modalContent=""
            openModal={openModal}
            onClose={() => onClose(false)}
            className="modal-medium mx-auto"
        >
            <div className='mb-4'>
                <ol className='guide-to-connect-step'>
                    <li>
                        <h6>1. Followers</h6>
                        <p className='position-relative'>You need to have at least 1k followers to be eligible to apply.{applyRestrictionData.followers.status && <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                                <img src={verified} alt="verified" width="24" height="24" />
                            </IconButton>
                        </Tooltip>}</p>
                    </li>
                    <li>
                            <h6>2. Connect Social Account <button className='btn btn-text-link fs-12'
                                onClick={handleGuideDrawer}>
                                Guide to connect</button></h6>
                        <p> You need to connect your social media account on
                            {platform === 'Instagram' ? (
                                <span style={{ margin: '0 5px', display: 'inline-flex' }}>
                                    <InstagramIcon /> Instagram
                                </span>
                            ) : (
                                <span style={{ margin: '0 5px' }}>
                                    <YouTubeIcon /> Youtube
                                </span>
                            )}
                            as per campaign's requirement.
                            {applyRestrictionData.social_connect.status && <Tooltip className="icon-verified" title="verified" arrow>
                                <IconButton size="small" className="p-0">
                                    <img src={verified} alt="verified" width="24" height="24" />
                                </IconButton>
                            </Tooltip>}

                            {!applyRestrictionData.social_connect.status && (
                                <>
                                    {platform === 'Instagram' && (
                                        <div className='connected-account-buttons'>
                                            {facebookAccounts.length === 0 &&
                                                <div className='connected-account-input d-flex mb-2'>
                                                    <TextField
                                                        // required={true}
                                                        autoComplete='off'
                                                        className='influencer_personal_info'
                                                        size='small'
                                                        fullWidth
                                                        name='username'
                                                        type='text'
                                                        label='Instagram username'
                                                        placeholder='eg. virat.kohli'
                                                        variant='outlined'
                                                        onPaste={handlePaste}
                                                        onChange={(e: any) => { setUserName(e.target.value); username ? setVerifyUserName(false) : setVerifyUserName(true) }}
                                                        onInput={handleInput}
                                                    />
                                                    <span style={{ color: 'red' }}>{userNameError}</span>
                                                    <Button
                                                        variant='outlined'
                                                        className='btn ms-2'
                                                        // color='error'
                                                        onClick={
                                                            faceBookAction
                                                        }
                                                        disabled={verifyUserName}
                                                    // onClick={() => handleFacebookLoginClick(null)}
                                                    >
                                                        verify
                                                    </Button>
                                                </div>
                                            }
                                            <FacebookLogin
                                                appId={FACEBOOK_APP_ID}
                                                autoLoad={false}
                                                fields='name,email,picture'
                                                callback={responseFacebook}
                                                textButton={connectButton}
                                                isDisabled={faceBookButton}
                                                cssClass='btn btn-outline-primary'
                                                scope='email, pages_show_list, public_profile, instagram_basic, pages_read_engagement, instagram_manage_insights, user_gender, user_birthday, user_location'
                                                redirectUri={`${BASE_URL_UI}/influencer/settings/socialconnect`}
                                            />
                                        </div>
                                    )}
                                    {platform === 'Youtube' && (
                                        <div className='connected-account-buttons'>
                                            <Button
                                                variant='outlined'
                                                className='btn btn-outline-primary'
                                                onClick={() => loginGoogle()}
                                            >
                                                Connect
                                            </Button>
                                        </div>
                                    )}

                                    <>
                                        {loading ? (
                                           <Loading width='100' height='100'/>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                </>
                            )}

                        </p>

                    </li>
                    <li>
                        <h6>
                            3. Upgrade to Premium
                            {
                                applyRestrictionData.subscription.status && 
                                <Tooltip className="icon-verified" title="verified" arrow>
                                    <IconButton size="small" className="p-0">
                                        {/* <MaterialSymbol iconName="verified" fontweight="200" /> */}
                                        <img src={verified} alt="verified" width="24" height="24" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </h6>
                        {!applyRestrictionData.subscription.status && <Link className='btn btn-outline-primary btn-sm' to="/influencer/settings/subscription">Premium</Link>}

                    </li>
                </ol>
            </div>
        </CommonPopUp>
      
                {socialRestrictionPopup_YT &&
                    <SocialConnectRestriction open={socialRestrictionPopup_YT} onClose={handleCloseSocialPopup_YT} continueConnect={handleContinueConnect_YT} />
                }

                {socialRestrictionPopup_IG &&
                    <SocialConnectRestriction open={socialRestrictionPopup_IG} onClose={handleCloseSocialPopup_IG} continueConnect={handleContinueConnect_IG} />
                }
            {createPortal(
                <GenericSwipeableDrawer
                    className='guide-to-connect apply-restriction-popup'
                    drawerAnchor={drawerAnchor}
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    onOpen={handleDrawerOpen}
                    title='Guide to Connect'
                    content={<GuideToConnectContent />}
                />, document.body)} 
        </>
    );
    
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUser: (data: any) => dispatch(UserLogin(data)),
    }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ApplyRestrictionPopup);

export { connectToRedux as ApplyRestrictionPopup };

