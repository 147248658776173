import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';
import {
    useLocation,
    NavLink,
  } from "react-router-dom";
  
import React, { useEffect, useState} from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormValidation } from '../../core/utility/formValidation';
// import { WhatsApp } from '@mui/icons-material';
import { LoginService, LocalService, UserService } from '../../core/services';
import { connect } from 'react-redux';
import {
    Loading,
    UserLogin,
    LoginUserId,
    SwitchLayer1,
} from '../../redux/action';
import { Link, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import useBodyClass from '../../hooks/useBodyClass';
import HeadingStyle from '../../components/heading-style/heading-style';
import './login.scss';
import Footer from '../../components/footer/footer';

import {MuiOtpInput} from 'mui-one-time-password-input'
const formValidation = new FormValidation();
const localService = new LocalService();
const userService = new UserService();

interface Props {
    IsUserLogged: (data: any) => void;
    switchLayer1: (data: any) => void;
    userID: (data: any) => void;
    loading: (data: any) => void;
}

const initializeShowButtonsState = {
    showPinOption: false,
    showPinBox: false,
};
const initializeDisableState = {
    login_id: false,
};

const Login: React.FC<Props> = (props: Props) => {
    const [login, setLogin] = useState({ logIn: '', password: '', pin: '' });
    const [remember, setRemember] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [disable] = useState(initializeDisableState);
    const [show, setShow] = useState(initializeShowButtonsState);
    const [loading, setLoading] = useState(false);
    // const dispatch = useDispatch<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [otpValue, setOtpValue] = React.useState<string>('')
    const [userType, setUserType] = useState('');
    const [campaignId,setCampaignId]=useState<string>('');
    const location = useLocation();

    const navigate = useNavigate();
    // const [checked, setChecked] = useState(initializeChecked);

    //validation
    const [handleSendError, setHandleSendError] = useState({
        message: 'Please enter valid Email/Mobile.',
        status: false,
    });
    
    let inputField = document.getElementById('id_password');
    inputField?.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('id_login_btn')?.click();
        }
    });

    useEffect(() => {
        if (localService.getStorageItem('DeviceId')) {
            setLogin({ ...login, ...JSON.parse(`${localService.getStorageItem('DeviceId')}`) });
            setRemember(true);
        } else if (localService.getStorageItem('userreducer') !== null){
            navigate('/switch/account');
        }
    }, []);

    useEffect(() => {
        if (localService.getStorageItem('DeviceId') && remember) {
            handleLogin(login.logIn, login.password, login.pin);
        }
    }, [remember]);

    useEffect(() => {
        if (localService.getStorageItem('login') && localService.getStorageItem('password')) {
            const _Login = localService.getStorageItem('login') || '';
            const _Pass = localService.getStorageItem('password') || '';
            handleLogin(_Login, _Pass, '');
        }
        let campaign_id = sessionStorage.getItem("campaignId") || ""
        setCampaignId(campaign_id);
    }, [])



    //Login Button Actions
    const handleLogin = (login: string, password: string, pin: string) => {
        try {
            //login
            // localService.logout()
            sessionStorage.clear();
            localStorage.clear();

            if (login.length > 0 && (password.length > 0 || pin.length > 0)) {
                setLoading(true);
                LoginService.login({
                    logIn: login.replace(/"/g, ''),
                    password: password.replace(/"/g, ''),
                    pin: pin.replace(/"/g, ''),
                })
                    .then((res: any) => {
                        if (res.data.status.isLogin) {
                            props.IsUserLogged(res.data.status.isLogin);
                            props.switchLayer1(res.data.rows);
                            props.loading(true);
                            sessionStorage.setItem("campaignId",campaignId)

                            navigate('/switch/account');
                            localService.toastify(
                                res.data.status.message,
                                'success',
                                1000
                            );

                            // Save Remmber me
                            handleremember();

                        } else {
                            localService.toastify(
                                res.data.status.message,
                                'info',
                                1000
                            );
                        }

                        setLoading(false);
                    })
                    .catch((error) => {
                        throw new Error(
                            'handleOTP in Login :: Failed :: ' + error
                        );
                    });
            } else {
                localService.toastify(
                    'Valid Email or  Valid Mobile Number is required',
                    'error',
                    1000
                );
            }
        } catch (error) {
            throw new Error(`handleLogin ::: ${error}`);
        }
    };

    const handleremember = () => {
        if (remember) {
            // localService.setStorageItem('remember', 'true');
            localService.setStorageItem('DeviceId', JSON.stringify(login));
        }

    };
    const handleInput = (e: any) => {
        const {
            target: { value },
        } = e;
        setLogin({ ...login, logIn: value });
        if (
            formValidation.GetEmailControlIsValid(value).status ||
            formValidation.GetMobileControlIsValid(value).status
        ) {
            setHandleSendError({ message: '', status: true });
        } else {
            setHandleSendError({
                message: 'Please enter valid Email/Mobile.',
                status: false,
            });
        }
    };

    //Send Otp
    const sendOtpButton = () => {
        if (handleSendError.status) {
            LoginService.userValidationOTP({ logIn: login.logIn }).then(
                (res) => {
                    if (res?.data?.rows?.status) {
                        localService.toastify(
                            res?.data?.rows?.message,
                            'success',
                            1000
                        );
                        setShow({
                            ...show,
                            showPinBox: res?.data?.rows?.status,
                        });
                    } else {
                        localService.toastify(
                            res?.data?.rows?.message,
                            'error',
                            1000
                        );
                    }
                }
            );
        } else {
            localService.toastify(handleSendError.message, 'error', 1000);
        }
    };

    const handleOtpButton = (e: any, c: any) => {
        setShow({ ...show, showPinOption: c });
        if (!c) {
            setLogin({ ...login, pin: '' });
        }
        setShow({ ...show, showPinBox: c, showPinOption: c });
    };

    const sendPinPassword = (login: any) => {
        setIsDisabled(true);

        if (login.length > 0) {
            userService.forgetPasswordPin({ login: login }).then((res) => {
                if (res?.data?.rows?.status) {
                    localService.toastify(
                        res?.data?.rows?.message,
                        'success',
                        1000
                    );

                    setTimeout(() => {
                        setIsDisabled(false);
                    }, 2 * 60 * 1000);
                } else {
                    localService.toastify(
                        res?.data?.rows?.message,
                        'error',
                        1000
                    );
                }
            });
        } else {
            setIsDisabled(false);

            localService.toastify(
                'Valid Email or  Valid Mobile Number is required',
                'error',
                1000
            );
        }
    };

    //Login with Pin
    const handlePinField = (pin: any) => {
        login.pin = pin;
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    // For Body Class Name
    useBodyClass('common-signup-page');
    useBodyClass('login-page');
    
    const handleChange = (newValue: string) => {
        const filteredValue = newValue.replace(/\D/g, '');
        setOtpValue(filteredValue)
    }
    // Function to check if a value is numeric
    const matchIsNumeric = (text: any) => {
        return !isNaN(Number(text));
    };

    // Validation function for MuiOtpInput
    const validateChar = (value: string) => {
        // return matchIsNumeric(value);
        return /^\d*$/.test(value);
    };
    
  
  
    return (
        <>
        <div className='__login_container common-bg'>
            <div className='row __login_row'>
                <div className='col-12 col-sm-8 col-lg-4 __login_box'>
                    <HeadingStyle title='Login' className=' mb-4' />
                    <div className='position-relative'>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-3">
                            <FormControl fullWidth size="small" className="input-style input-common">
                                <InputLabel id="demo-simple-select-label">
                                    I am
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="i_am_id"
                                    value={userType}
                                    label="I am"
                                    name="I am"
                                    onChange={(e) => setUserType(e.target.value as string)}
                                    // MenuProps={MenuPropsSelect}
                                >
                                    <MenuItem value="Influencer">Influencer</MenuItem>
                                    <MenuItem value="Brand">Brand</MenuItem>
                                    <MenuItem value="Talent Partner">Talent Partner</MenuItem>
                                    <MenuItem value="Agency">Agency</MenuItem>
                                </Select>
                            </FormControl>
                    </div>

                        <TextField
                            className='w-100 mb-3 input-common'
                            onChange={handleInput}
                            size='small'
                            id='login_id'
                            disabled={disable.login_id}
                            autoFocus
                            label='Email/Mobile'
                            variant='outlined'
                        />

                        <FormControl
                            variant='outlined'
                            className='w-100 input-common'
                            size='small'
                        >
                            <InputLabel htmlFor='password_id'>
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id='id_password'
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                onChange={(e) =>
                                    setLogin({
                                        ...login,
                                        password: e.target.value,
                                    })
                                }
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge='end'
                                        >
                                            {showPassword ? (

                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='Password'
                            />
                        </FormControl>
                        <div className="col d-flex justify-content-end pt-1">
                            <label className='small-size text-right text-black-link'>

                                <span
                                    onClick={() => sendPinPassword(login.logIn)}
                                    className={
                                        isDisabled ? 'disabled-link' : 'show-cursor'
                                    }
                                >
                                    Forgot Password?
                                </span>
                            </label>
                        </div>
                    </div>
                    <Divider className="w-100 text-center py-3 fs-14">or PIN</Divider>

                    <span className='__login_auth_span d-none'>
                        {' '}
                        Login via PIN
                        <Switch
                            style={{ color: `white` }}
                            onChange={handleOtpButton}
                            inputProps={{ 'aria-label': 'Switch demo' }}
                        />
                    </span>
                    {/* <OtpField handleOtp={handlePinField} /> */}
                    <MuiOtpInput
                        value={otpValue}
                        onChange={handleChange}
                        onComplete={handlePinField}
                        length={4}
                        
                        TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                        validateChar={validateChar} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleLogin(login.logIn, login.password, login.pin);
                            }
                        }}
                        />


                    <div className="col d-flex justify-content-end pt-1">
                        <label className='small-size text-right text-black-link'>
                            <span
                                onClick={() => sendPinPassword(login.logIn)}
                                className={isDisabled ? 'disabled-link' : 'show-cursor'
                                }
                            >
                                Forgot PIN?
                            </span>
                        </label>
                    </div>
                    <div className='login_remember'>
                        <label className='user-select-none'>
                            <FormControlLabel
                                className='custom-checkbox m-0'
                                control={<Checkbox
                                    className=' p-0 m-0'
                                    inputProps={{ 'aria-label': 'Checkbox' }}
                                    onChange={(event, value) => setRemember(value)}
                                    name='terms'
                                    size='small'
                                />}
                                label=""
                            />
                            <span className='small-size ps-1'>Remember me</span>
                        </label>
                    </div>

                    <div className='login_buttons pb-2'>
                        <LoadingButton
                            id='id_login_btn'
                            loading={loading}
                            loadingPosition='start'
                            className='btn btn-primary w-100'
                            variant='contained'
                            onClick={() =>
                                handleLogin(
                                    login.logIn,
                                    login.password,
                                    login.pin
                                )
                            }
                        >
                            Login
                        </LoadingButton>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <label className='small-size text-center'>
                            Not registered yet? &nbsp;
                            <Link to="/"
                                className={
                                    isDisabled ? 'disabled-link' : 'show-cursor'
                                }
                            >
                                Signup
                            </Link>
                        </label>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
        </>

    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
        switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
        IsUserLogged: (data: any) => dispatch(UserLogin(data)),
        userID: (data: any) => dispatch(LoginUserId(data)),
    };
};

const reduxConnect = connect(null, mapDispatchToProps)(Login);

export { reduxConnect as Login };
