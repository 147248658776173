import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import { CloseRounded } from '@mui/icons-material';
import { Slide } from '@mui/material';

interface Props {
  modalTitle?: string;
  modalContent?: string;
  children?: any;
  openModal: boolean;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  modalId?: any;
  className?: any;
  onClose: () => void;
  popupHeaderPadding?: any;
  modalSize?: any;
  hasCustomFooter?: boolean; // Receive onClose callback as a prop
}

const CommonPopUp: React.FC<Props> = ({
  modalTitle,
  modalContent,
  children,
  openModal,
  onClose,
  popupMinWidth,
  popupMaxWidth,
  modalId, className,
  popupHeaderPadding,
  modalSize,
  hasCustomFooter

}) => {
  return (

    <Modal
      aria-hidden="true"
      id={modalId}
      open={openModal}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={openModal ? `MuiModal-open ${className} ${modalSize}` : `${className} ${modalSize} MuiModal-hidden`}
    >
      <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
        <div className='popup_container' style={{ minWidth: popupMinWidth, maxWidth: popupMaxWidth }}>
          <div className='popup_container-inner'>
            <div className={`${!popupHeaderPadding ? "popup-header" : "popup-header"}`}>
              {modalTitle && (
                <strong>{modalTitle}</strong>
              )}
              <span className='popup-close'>
                <IconButton
                  edge='end'
                  color='inherit'
                  onClick={onClose} // Use the onClose callback to handle closing
                  aria-label='close'
                >
                  <CloseRounded />
                </IconButton>
              </span>
            </div>
            <div className='popup_container_content'>
              <Box className='popup_content_container'>
                {modalContent && (
                  <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                    {modalContent}
                  </Typography>
                )}
                {children}
              </Box>
            </div>
            {hasCustomFooter && (
              <div className='custom-modal-footer'>
                {/* Add your custom footer content here */}

              </div>
            )}
          </div>
        </div>
      </Slide>
    </Modal >

  );
};

export default CommonPopUp;