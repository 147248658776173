import React, { useEffect, useState } from 'react';
import { TableComponent, SortingColumnsType } from '../../../../../../components/table-component/table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './../verification.scss';
import { NavLink } from 'react-router-dom';
import { Button, TextField } from '@mui/material';

import { FormValidation } from '../../../../../../core/utility';
// importing admin dashboard and local services
import { AdminDashboardService } from '../../../../../../core/services/admin-dashboard.service';
import { LocalService } from '../../../../../../core/services';

// important common popup component
import CommonPopUp from '../../../../../../components/popup/common-popup';

// To connect to redux
import { Loading } from '../../../../../../redux/action';
import { connect, useDispatch } from 'react-redux';
import dayjs from "dayjs";
import HeadingStyle from '../../../../../../components/heading-style/heading-style';

// Create an instances of AdminDashboardServices, LocalServices and formValidations
const adminDashboardService = new AdminDashboardService();
const localService = new LocalService();
const formValidation = new FormValidation();

const columnDisplayName = {
  name: 'Influencer Name',
  username: 'Influencer IG Handle',
  title: 'Influencer YT Handle',
  account_name: 'Talent Partner',
  email_primary: 'Email',
  mobile_whatsapp: 'Phone',
  submitted_on: 'Submitted On',
  action: 'Action',
}

const sortingColumnType: SortingColumnsType[] = [
  {
    name: 'name',
    columnSize: '180px',
  },
  {
    name: 'username',
    columnSize: '160px',
  },
  {
    name: 'title',
    columnSize: '180px',
  },
  {
    name: 'account_name',
    columnSize: '180px',
  },
  {
    name: 'email_primary',
    columnSize: '180px',
  },
  {
    name: 'mobile_whatsapp',
    columnSize: '120px',
  },
  {
    name: 'submitted_on',
    columnSize: '200px',
  },
  {
    name: 'action',
    columnSize: '250px',
  }
]

interface Props {
  loading: (data: any) => any;
  userData: any;
}

const TalentVerification: React.FC<Props> = (props: Props) => {

  const [renderData, setRenderData] = useState<any>([])

  // talent influencer data
  const [talentInfluencers, setTalentInfluencers] = useState<any>([]);

  // Modal form data
  const [formData, setFormData] = useState({
    reviewed_by: props.userData.user_id,
    remark: '',
    influencer_id: '',
    status: '',
    influencer_name: '',
    talent_partner_name: '',
    reviewed_on: '',
    login_email: '',
  });

  // Set form error fields
  const [formError, setFormErrors] = useState({
    remark: { status: true, message: '' },
  });

  const handleError = (event: any) => {
    // Validate form
    // validating name
    const validateRemark = formValidation.GetNameValidate(formData.remark);
    setFormErrors({
      ...formError,
      remark: validateRemark,
    });
  };

  // set Modal variables and usestate
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  function handleCloseModal() {
    setOpenModal(false);
    setFormData({
      ...formData,
      remark: '',
      influencer_id: '',
      status: '',
      influencer_name: '',
      talent_partner_name: '',
      reviewed_on: '',
    });
  }

  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [reviewed_influencer, setInfluencerReviewed] = useState(false);

  const handleButtonClick = (event: any, buttonId: any) => {
    switch (buttonId) {
      case 'activateButton':
        // setInfluencerStatus('Activate');
        setInfluencerReviewed(false)
        setModalTitle('Please Confirm');
        setModalContent(
          `Click 'Activate' to activate the influencer '${event.target.dataset.influencer_name}', added By talent partner: ${event.target.dataset.talent_partner_name}.`
        );
        setFormData({
          ...formData,
          reviewed_by: props.userData.user_id,
          influencer_id: event.target.dataset.influencer_id,
          status: 'Activate',
          influencer_name:event.target.dataset.influencer_name
        });
        break;
      case 'deactivateButton':
        // setInfluencerStatus('Deactivate');
        setInfluencerReviewed(false)
        setModalTitle('Please Confirm');
        setModalContent(
          `Click 'Deactivate' to deactivate the influencer '${event.target.dataset.influencer_name}', added By talent partner: ${event.target.dataset.talent_partner_name}.`
        );
        setFormData({
          ...formData,
          reviewed_by: props.userData.user_id,
          influencer_id: event.target.dataset.influencer_id,
          status: 'Deactivate',
          influencer_name:event.target.dataset.influencer_name
        });
        break;
      default:
        setInfluencerReviewed(true)
        setModalTitle('Reviewed Influencer Details');
        setModalContent('')
        const dataset = event.target.dataset;
        setFormData({
          ...formData,
          login_email: dataset.login_email,
          influencer_id: dataset.influencer_id,
          status: dataset.status,
          remark: dataset.remark,
          influencer_name: dataset.influencer_name,
          talent_partner_name: dataset.talent_partner_name,
          reviewed_on: dataset.reviewed_on,
        });
    }
    // Open the modal
    handleOpenModal();
  };
  // end of modal

  // Submit form logic
  const handleConfirmation = async (e: any) => {
    try {
      e.preventDefault();
      // Validate form
      // validating name
      const validateRemark = formValidation.GetNameValidate(formData.remark);
      setFormErrors({
        ...formError,
        remark: validateRemark,
      });
      if (validateRemark.status) {
        try {
          const response = await adminDashboardService.updateReviewInfluencer(
            formData
          );
          if (response.data.status) {
            localService.toastify(response.data.message, 'success', 1000);
            getTalentInfluencers();
          } else {
            localService.toastify(response.data.message, 'error', 1000);
          }
        } catch (error) {
          console.log(
            'Update Review Campaign Error :: ',
            JSON.stringify(error)
          );
        }
        // Close the modal after handling form submission
        setOpenModal(false);
      }
    } catch (error) {
      console.log('Update Review Campaign Error :: ', JSON.stringify(error));
      // Close the modal after handling form submission
      setOpenModal(false);
    }
  };

  // Separate function to get review Influencers to call it multiple times on form submit;
  async function getTalentInfluencers() {
    try {
      props.loading(true);
      const res = await adminDashboardService.getTalentInfluencers();
      setTalentInfluencers(res?.data?.rows || []);
    } catch (error) {
      console.error('Error fetching talent-influencers:', error);
      setTalentInfluencers([]);
    } finally {
      props.loading(false);
    }
  }

  useEffect(() => {
    getTalentInfluencers();
  }, []);

  return (
    <div className='talent-verification-main'>
      {openModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={openModal}
          onClose={() => handleCloseModal()}
          className="modal-medium mx-auto"
        >
          {reviewed_influencer
            ?
            (
              <div>
                <ul>
                  <li><strong>Influencer Status: </strong>{formData.status}</li>
                  <li><strong>Influencer Name: </strong>{formData.influencer_name}</li>
                  <li><strong>Talent Partner Name: </strong>{formData.talent_partner_name}</li>
                  <li><strong>Reviewed By: </strong>{formData.login_email}</li>
                  <li><strong>Reviewed On: </strong>{formData.reviewed_on ? dayjs(formData.reviewed_on).format('LL') : ''}</li>
                  <li><strong>Remarks: </strong>{formData.remark}</li>
                </ul>
              </div>
            )
            :
            (<form onSubmit={handleConfirmation}>
              <TextField
                fullWidth
                label='Remark'
                name='remark'
                variant='standard'
                value={formData?.remark}
                error={!formError?.remark?.status}
                helperText={formError?.remark?.message}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    remark: e.target.value,
                  })
                }
                onBlur={handleError}
              />
              <div className='col-lg-auto text-end mt-3'>
                <Button
                  size='small'
                  type='submit'
                  variant='outlined'
                  className={
                    formData.status == 'Activate'
                      ? 'btn btn-outline-primary'
                      : 'btn btn-outline-error'
                  }
                >
                  {formData.status}
                </Button>
              </div>
            </form>)
          }
        </CommonPopUp>
      )}
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className='col-auto nav sub-tabs mb-3'>

        <ul>
          <li className=''>
            <NavLink
              className='nav-link campaign-verification-tab'
              to={'/brand/admin-dashboard/campaign-verification'}
            >
              Campaign verification
            </NavLink>
          </li>
          <li className=''>
            <NavLink
              className='nav-link talent-verification-tab'
              to={'/brand/admin-dashboard/talent-verification'}
            >
              Talent verification
            </NavLink>
          </li>
        </ul>
      </div>
      <TableComponent
        pagination={true}
        columnDisplayName={columnDisplayName}
        inputDataSource={talentInfluencers}
        orderByColumnName='influencer_name'
        sortingColumnType={sortingColumnType}
        renderData={renderData}
        setRenderData={e => setRenderData(e)}>
        {
          renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} hover role='checkbox'>

                <TableCell>{data.name}</TableCell>
                <TableCell>{data.username}</TableCell>
                <TableCell>{data.title}</TableCell>
                <TableCell>{data.account_name}</TableCell>
                <TableCell>{data.email_primary}</TableCell>
                <TableCell>{data.mobile_whatsapp}</TableCell>
                <TableCell>{data.created_at ? dayjs(data.created_at).format('LL') : ''}</TableCell>
                <TableCell>
                  <div className='d-flex'>
                    {
                      (data.status === 'Under Review')
                        ?
                        <div>
                          <Button
                            className='btn btn-primary btn-sm'
                            data-influencer_id={data.influencer_id}
                            data-talent_partner_name={data.account_name}
                            data-influencer_name={data.name}
                            onClick={(e) =>
                              handleButtonClick(e, 'activateButton')
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            className='btn btn-outline-error btn-sm'
                            style={{ marginLeft: '10px' }}
                            data-influencer_id={data.influencer_id}
                            data-talent_partner_name={data.account_name}
                            data-influencer_name={data.name}
                            onClick={(e) =>
                              handleButtonClick(e, 'deactivateButton')
                            }
                          >
                            Reject
                          </Button>
                        </div>
                        :
                        <div>
                          <Button
                            className='btn btn-primary btn-sm'
                            data-influencer_id={data.influencer_id}
                            data-talent_partner_name={data.account_name}
                            data-influencer_name={data.name}
                            data-remark={data.remark}
                            data-reviewed_on={data.reviewed_on}
                            data-status={data.status}
                            data-login_email={data.login_email}
                            onClick={(e) =>
                              handleButtonClick(e, 'statusButton')
                            }
                          >
                            {data.status + 'd on: ' + (data.reviewed_on ? dayjs(data.reviewed_on).format('LL') : '')}
                          </Button>
                        </div>
                    }
                  </div>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableComponent>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TalentVerification);
export { connectToRedux as TalentVerification };

