import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';

const http = new HttpService();

export class LoginService {
    static async userValidationOTP(data: any): Promise<any> {
        try {
            const path = BASE_URL + '/validateuser/otp'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`userValidation OTP :: ${error}`)
        }
    };

    static async login(data: any): Promise<any> {
        try {
            const path = BASE_URL + '/signin'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    };

    // static async getInfluencerId(data: any): Promise<any> {
    //     try {
    //         const path = BASE_URL + '/get/influencerid'
    //         const response = await http.postRequest(path, data, null);
    //         return response;
    //     } catch (error) {
    //         throw new Error('login Service Error:: ' + error)
    //     }
    // }


    async getInfluencer(data: { user_id: number, account_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/influencer'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    }

    async getInfluencerAccounts(data: { user_id: number, account_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/influencer/influencerids'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    }

    async getBrandIds(data: { user_id: number, account_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/brand/brandids'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    }

    async getBrand(data: { user_id: number, account_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/brand'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    }

    async getBrandManagers(data: { agency_account_id: number, brand_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/agency/manageusers'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('Manage Users Error:: ' + error)
        }
    }

    async getTalentInfluencer(data: { user_id: number, account_id: number }): Promise<any> {
        try {
            const path = BASE_URL + '/get/talent/influencer'
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error('login Service Error:: ' + error)
        }
    }


}
