import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Delete,
  Edit,
  YouTube,
  PersonAddAlt1,
  FormatListBulleted,
  Instagram,
} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { Button, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  CreateCampaign as CampaignService,
  ListService,
  LocalService,
  DiscoverService,
} from '../../../../core/services';
import { Loading } from '../../../../redux/action';
import HeadingStyle from '../../../../components/heading-style/heading-style';
import './influencers-list.scss';
import InstagramIcon from '../../../../components/icons/instagram-icon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormValidation } from '../../../../core/utility';
// important upgrade brand popup component
import UpgradeBrandPopUp from '../../../../components/popup/upgrade-brand-popup';
import CommonPopUp from '../../../../components/popup/common-popup';
import ConfirmPopup from '../../../../components/confirm-popup/confirm-popup';
import { createPortal } from 'react-dom';
import { CommonTableComponent } from '../../../../components/common-table-component/common-table-component';
import InstagramIcon1 from '../../../../components/icons/instagram-icon1';
import IconYouTube from '../../../../components/icons/icon-youtube';
import ProfileViewRestriction from "../../../../components/popup/profile-view-restriction";
import { Select, Radio, RadioGroup, FormControl, FormControlLabel, MenuItem, InputLabel } from "@mui/material";
import { createCampaignInitialState } from "../../../../redux/reducer/reducer";
import { MenuPropsSelect } from '../../../../core/utility/style';
import { CreateCampaign } from "../../../../redux/action";

interface InfluencerProps {
  UserReducer: any;
  loading: (data: any) => void;
  getSubscriptionDetail: any;
  CreateCampaignReducer?: (data: any) => void;
}

const initformState = {
  gender: "any",
  costing_module: "individual",
  category: [],
  location_id: [],
  influencer_list_ids: [],
  listInfluencers: {},
  influencer_selector: [], // it will be change for new layout
};

const listService: ListService = new ListService();
const localService: LocalService = new LocalService();
const validations: FormValidation = new FormValidation();
const discoverService: DiscoverService = new DiscoverService();

const InfluencerList: React.FC<InfluencerProps> = (props: InfluencerProps) => {
  const [selectInfluencerList, setSelectInfluencerList] = useState<any>(null);
  const [influencerList, setInfluencerList] = useState<any>([]);
  const [selectList, setSelectList] = useState<any>(null);
  const [activePlatform, setActivePlatform] = useState('Instagram');
  const [showPopUp, setShowPopUp] = useState(false);
  const [popListName, setPopListName] = useState('');
  const [renameList, setRenameList] = useState('');
  const [enableRename, setEnableRename] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noList, setNoList] = useState('');
  const [selectedInfluencer, setSelectedInfluencer] = useState<any>([]);
  const getData = useLocation();
  const [addInfluencerDefaultSelectList, setAddInfluencerDefaultSelectList] =
    useState<any>(null);
  const [popupInputValidation, setPopupInputValidation] = useState({
    status: true,
    message: '',
  });
  const [renameInputValidation, setRenameInputValidation] = useState({
    status: true,
    message: '',
  });
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const navigate = useNavigate();
  const [profileViewCount, setProfileViewCount] = useState(0);
  const [profileViewLimit, setProfileViewLimit] = useState(0);
  const [selectedSmId, setSelectedSmId] = useState(0);
  const [profileViewLimitPopup, setProfileViewLimitPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [createSelectedPlan, setCreateSelectedPlan] = useState("");
  const [planList, setPlanList] = useState<any>([]);
  const [radiovalue, setRadioValue] = useState(1);
  const [showPlanNeed, setShowPlanNeed] = useState(false);
  const [formData, setFormData] = useState<any>({
    brand_id: props.UserReducer.brand_id,
    user_id: props.UserReducer.user_id,
    // campaign_id: props?.campaignReducer?.createCampaign?.campaign_id,
    ...initformState,
    // ...props.campaignReducer.influencer,
    // influencer_selector: props.campaignReducer.influencer
  });

  // const [createdCampaignId, setCreatedCampaignId] = useState(0);

  // set upgrade brand Modal variables and usestate
  const [openUpgradeBrandModal, setopenUpgradeBrandModal] = useState(false);

  const handleOpenUpgradeBrandModal = () => setopenUpgradeBrandModal(true);
  function handleCloseUpradeBrandModal() {
    setopenUpgradeBrandModal(false);
  }

  const onCloseSelectedPlan = () => {
    setShowPopup(!showPopup)
    setCreateSelectedPlan(planList[0]?.plan_id);
  }

  useEffect(() => {
    _InfluencerList("");
  }, [props.UserReducer.user_id, props.UserReducer.brand_id]);

  useEffect(() => {
    if (getData.state) {
      setAddInfluencerDefaultSelectList(getData.state);
      _InfluencerList("");
    }
  }, []);

  useEffect(() => {
    props.loading(true);
    try {
      listService.planList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      })
        .then((res: any) => {
          props.loading(false);
          try {
            if (res.data.fetchedRows > 0) {
              setPlanList(res.data.rows);
              setRadioValue(2);
              setCreateSelectedPlan(res.data.rows[0].plan_id);
            } else {
            }
          } catch (err) {
            throw new Error(`Data Align Error :: ${err}`);
          }
        })
        .catch((e) => {
          props.loading(false);
          throw new Error(`PlanService planList function error :: ${e}`);
        });
    } catch (error) {
      throw new Error(`Plan list error :: ${JSON.stringify(error)}`);
    } finally {
    }
  }, [props.UserReducer.brand_id, props.UserReducer.user_id]);

  useEffect(() => {
    try{
    setLoadingData(true);
    setSelectInfluencerList(null);
    setSelectedInfluencer([]);
    if (selectList) {
      setRenameList(selectList.list_name);
      CampaignService.influencerTable({
        brand_id: props.UserReducer.brand_id,
        user_id: props.UserReducer.user_id,
        list_id: selectList.list_id,
        page: page
      }).then((res: any) => {
        if (res.data.status.status) {
          setSelectInfluencerList(res.data.rows);
          if (res.data.rows.length === 0) {
          }
        } else {
          // localService.toastify(res.data.status.message, 'info', 1000);
        }
      });
    }}catch(error){
      console.log('influencerTable error',error);
    }finally {
      setLoadingData(false);
    }
  }, [selectList, props.UserReducer.user_id, props.UserReducer.brand_id, page]);

  useEffect(() => {
    setPage(1);
  }, [selectList])

  const handleNavigate = (id: number | string, influencerId?: string | number) => {
    if (selectList?.list_type.toLowerCase() === "instagram") {
      window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    } 
    if (selectList?.list_type.toLowerCase() === "youtube") {
      window.open(`/brand/profile-insight-yt?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    }
    if (selectList?.list_type.toLowerCase() === "curatedInstagram"){
      window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    }
  };


  const handleInfluencerList = () => {
    
    let influencer_ids: { [key: string]: any } = {};
    let sm_ids: { [key: string]: any } = [];
    let selectListArray: any = [];

    // If selectList is an object, you can convert it to an array
    selectListArray = Array.isArray(selectList) ? selectList : [selectList];

    if (selectListArray.length > 0) {
        for (let index = 0; index < selectListArray.length; index++) {
            const getInfluencerId: any = influencerList.find(
                (res: any) => res.list_id === selectListArray[index].list_id
            );

            if (getInfluencerId) {
                // Influencer ids
                influencer_ids[`${selectListArray[index].list_id}`] = getInfluencerId.influencer_id.split(",");
                // sm_ids
                sm_ids[`${selectListArray[index].list_id}`] = getInfluencerId.sm_id.split(",");
            }
        }
    }

    setFormData({
        ...formData,
        influencer_list_ids: [selectList.list_id],
        listInfluencers: influencer_ids,
        listInfluencer_sm_ids: sm_ids
    });
};


async function handleProfileView(sm_id: number, influencerId?: number) {
    try {
    //   props.loading(true);
      setSelectedSmId(sm_id);

      const response = await discoverService.canViewProfile({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        sm_id: sm_id
      })
      if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
        handleNavigate(sm_id, influencerId);
      }
      else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_profile_limit_popup) {
        setProfileViewCount(response?.data?.profile_view_count);
        setProfileViewLimit(response?.data?.profile_view_limit);
        setProfileViewLimitPopup(true);
      }
      // else if (response.data.status && response.data.basic_user ){
      //   setProfileViewLimitPopup(true);
      // }
      else if (!response.data.status && response.data.message.includes('upgrade')) {
        // localService.toastify(response.data.message, 'info', 1000);
        handleOpenUpgradeBrandModal();
      }
      else if (!response.data.status) {
        // localService.toastify(response.data.message, 'error', 1000);
        console.error(`error:: ${response.data.message}`)
      }
    } catch (error) {
      console.error(`handleProfileView error :: ${JSON.stringify(error)}`);
    } finally {
    //   props.loading(false);
    }
  };

  async function handleFocProfileView() {
    try {
      // props.loading(true);
      setProfileViewLimitPopup(false);
      handleNavigate(selectedSmId);
    } catch (error) {
      console.error(`handleFocProfileView error :: ${JSON.stringify(error)}`);
    } finally {
      // props.loading(false);
    }
  };

  function _InfluencerList(info:string) {
    try {
      CampaignService.influencerList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      }).then((res: any) => {
        if (res.data.status === 1) {
          setInfluencerList(res.data.rows);
          if (getData.state) {
            if(res.data.rows.length === 0){
              setSelectList(null);
              setNoList('You have not created any list yet ');     
            }else{
              setNoList('');
              if(info === "create-list" || info === "delete-list" || info === "edit-list"){
                setSelectList(res.data.rows[0]);
              }else{
                const selectedList = res.data.rows.find((data:any) => data.list_id === getData.state.list_id);
                setSelectList(selectedList || res.data.rows[0]);
              }
            }
          } else {
            if(res.data.rows.length === 0){
              setSelectList(null);
              setNoList('You have not created any list yet ');
            }else{
              setNoList('');
              setSelectList(res.data.rows[0]);
            }
          }
        } else {
          // localService.toastify(res.data.status.message, 'info', 1000);
          setInfluencerList([]);
          setSelectList(null);
        }
      });
    } catch (error) {
      console.log('_InfluencerList error', error)
    } 
  }

  async function createInfluencerList() {
    setLoading(true);
    try {
      let inputValidation = validations.GetNameValidate(popListName);
      setPopupInputValidation(inputValidation);
      if (inputValidation.status) {
        let response: any = await listService.createInfluencerList({
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          brand_id: props.UserReducer.brand_id,
          influencer_id: props.UserReducer.influencer_id,
          list_name: popListName,
          platform_type: activePlatform,
        });

        if (response.data.status.status) {
          // localService.toastify(response.data.status.message, 'success', 1000);
          setShowPopUp(false);
          setOpenModal(false);
          _InfluencerList("create-list");
        } else if (!response.data.status.status && response.data.status.message.includes('upgrade')) {
          setShowPopUp(false);
          setOpenModal(false);
          handleOpenUpgradeBrandModal();
        } else if (!response.data.status.status) {
          localService.toastify(response.data.status.message, 'error', 1000);
        }
      }
    } catch (error) {
      throw new Error(`createInfluencerList Error :: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  async function deleteInfluencerList(list_id: number) {
    try {
      const response: any = await listService.deleteInfluencerList({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        list_id: list_id,
      });

      if (response.data.status.status) {
        // localService.toastify(response.data.status.message, 'success', 1000);
        _InfluencerList("delete-list");
        setOpen(false);
      } else {
        localService.toastify(response.data.status.message, 'error', 1000);
      }
    } catch (error) {
      throw new Error(`deleteInfluencerList :: ${error}`);
    }
  }

  async function editListName() {
    try {
      setRenameInputValidation(validations.GetNameValidate(renameList));
      if (renameInputValidation.status) {
        const response: any = await listService.renameList({
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          brand_id: props.UserReducer.brand_id,
          list_id: selectList.list_id,
          list_name: renameList,
        });

        if (response.data.status.status) {
          // localService.toastify(response.data.status.message, 'success', 1000);
          _InfluencerList("edit-list");
        } else {
          localService.toastify(response.data.status.message, 'error', 1000);
        }
        setEnableRename(false);
      }
    } catch (error) {
      throw new Error(`editListName error :: ${error}`);
    }
  }

  function addInfluencer() {
    try {
      navigate(`/brand/discover`, { state: selectList });
    } catch (error) {
      console.log(
        `addInfluencer in Influencer List :: ${JSON.stringify(error)}`
      );
    }
  }

  async function deleteInfluencerInList() {
    setLoading(true);
    try {
      let temp_sm_Ids: number[] = [];
      const currentPlatformList: any = {
        youtube: 'yt_id',
        instagram: 'ig_id',
      };
      for (let data of selectedInfluencer) {
        const influencerId: number =
          data[
          currentPlatformList[(selectList.list_type + '').toLocaleLowerCase()]
          ];
        temp_sm_Ids.push(influencerId);
      }

      const response = await listService.deleteInfluencerInList({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        list_id: selectList.list_id,
        sm_ids: temp_sm_Ids,
      });

      setSelectedInfluencer([]);

      const checkStatus = response.data.status.status ? 'success' : 'error';
      if (!response.data.status.status) {
        localService.toastify(response.data.status.message, checkStatus, 1000);
      }
      CampaignService.influencerTable({
        brand_id: props.UserReducer.brand_id,
        user_id: props.UserReducer.user_id,
        list_id: selectList.list_id,
        page: 1
      }).then((res: any) => {
        if (res.data.status.status) {
          setSelectInfluencerList(res.data.rows);
          if (res.data.rows.length === 0) {
          }
        } else {
          // localService.toastify(res.data.status.message, 'info', 1000);
          setSelectInfluencerList(null);
        }
      });
    } catch (error) {
      console.log(
        `deleteInfluencerInList error in influencer-list.tsx :: ${JSON.stringify(
          error
        )}`
      );
    } finally {
      setLoading(false);
    }
  }

  const handleListName = (e: any) => {
    const {
      target: { value },
    } = e;
    setPopupInputValidation(validations.GetNameValidate(value));
    setPopListName(value);
  };

  const handleRenameListName = (e: any) => {
    const {
      target: { value },
    } = e;
    setRenameList(value);
    setRenameInputValidation(validations.GetNameValidate(value));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  };

  const handleClose = () => {
    setOpen(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleOpenModal = () => {
    setOpenModal(prev => !prev);

  }
  function handleCloseModal() {
    setOpenModal(false);

  }

  const handleCheckbox = (checked: boolean, data: any) => {
    let tempStoreData: any[] = [...selectedInfluencer];
    if (checked) {
      tempStoreData.push(data);
    } else {
      const index = tempStoreData.findIndex(
        (value) => JSON.stringify(value) === JSON.stringify(data)
      );

      if (index !== -1) {
        tempStoreData.splice(index, 1);
      }
    }
    setSelectedInfluencer(tempStoreData);
  };

  const handlePlanNeed = () => {
    setShowPlanNeed(prev => !prev)
  }

  const handleContinue = () => {
    if (radiovalue === 2) {
      //get Plan details
      if ((createSelectedPlan + "").length > 0) {
        handleAddCampaign(createSelectedPlan);
      } else {
        // toast.info("Please Select Plan.");
        localService.toastify("Please select plan", "info", 1000);
      }
    } else {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({...createCampaignInitialState,
           influencer: formData,
           activeTab: 2,
           createCampaign : {campaign_platform : selectList.list_type}
        });
      }
      navigate("/brand/campaign/createplan");
    }
  };

  const handleAddCampaign = (plan_id: any) => {
    listService.add_Campaign({
      brand_id: props.UserReducer.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: plan_id,
    }).then((res: any) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_id: res?.data?.status.campaign_id,
            campaign_platform : selectList.list_type
          },
          createPlan: res?.data?.rows[0],
          navigation: {
            ...createCampaignInitialState.navigation,
            campaign_create: true,
          },
          completeStep: {
            ...createCampaignInitialState.completeStep,
            createPlan: true,
          },
          influencer: formData,
          activeTab: 2,
        });

        navigate("/brand/campaign/createcampaign");
      }
    });
  };

  const clickSendBrief = () => {
     handleInfluencerList();
     setShowPopup(!showPopup)
  }

  return (
    <div className='influencer-list-main-container'>
      <div className='container'>
        <div className="agreement-heading my-3">
          <HeadingStyle title="Lists" />
        </div>
        <div className='row'>
          <div className='col'>
            {openModal && (
              <CommonPopUp
                modalTitle="Create New List"
                modalContent={modalContent}
                openModal={openModal}
                className="popup popup-sm popup-add-infl-list"
                onClose={() => handleCloseModal()}

              >
                <div className='col mb-3'>
                  <TextField
                    size='small'
                    error={!popupInputValidation.status}
                    helperText={popupInputValidation.message}
                    onChange={handleListName}
                    className='w-100'
                    id='outlined-basic'
                    label='List Name'
                    placeholder='For eg: Diwali list, Holi List, New year list'
                    variant='outlined'
                  />
                </div>
                <div className='col pb-2'>Choose Platform</div>
                <div className='d-flex flex-wrap platform_type input-checkbox-box-container mb-3 justify-content-between'>
                  <span
                    onClick={() => setActivePlatform('Instagram')}
                    className={`col ${activePlatform === 'Instagram' ? ' active input-checkbox-box' : 'input-checkbox-box'
                      }`}
                  >
                    <InstagramIcon1 width={25} />
                    <span className='inbox-checkbox-box-label'>Instagram</span>
                  </span>
                  <span
                    onClick={() => setActivePlatform('Youtube')}
                    className={`col ${activePlatform === 'Youtube' ? ' active input-checkbox-box' : 'input-checkbox-box'
                      }`}
                  >
                    <IconYouTube width={25} />
                    <span className='inbox-checkbox-box-label'>YouTube</span>
                  </span>
                </div>

                <div className='popup-footer'>
                  <div className='row'>
                    <div className='col text-end'>
                      <LoadingButton
                        loading={loading}
                        loadingPosition='start'
                        // startIcon={<SaveIcon />}
                        className='btn btn-primary btn-mui'
                        variant='outlined'
                        onClick={createInfluencerList}
                      >
                        Save
                      </LoadingButton>
                    </div>
                  </div>
                </div>

              </CommonPopUp>
            )}

            {openUpgradeBrandModal && (
              <UpgradeBrandPopUp
                openUpgradeBrandModal={openUpgradeBrandModal}
                onClose={() => handleCloseUpradeBrandModal()}
                popupMinWidth='630px'
              >
              </UpgradeBrandPopUp>
            )}
             
            {profileViewLimitPopup && (
              <ProfileViewRestriction
                openModal={profileViewLimitPopup}
                onClose={() => setProfileViewLimitPopup(false)}
                profileViewCount={profileViewCount}
                profileViewLimit={profileViewLimit}
                handleFocProfileView={() => handleFocProfileView()}
              />
            )}

      {showPopup && (
           createPortal(<CommonPopUp
            modalTitle="Create campaign"
            modalContent=""
            openModal={showPopup}
            onClose={() => onCloseSelectedPlan()}
            className="popup-select-plan modal-medium">
            <div className="create_new_campaign_popup">
              <div className="row">
                <div className="col">
                  <span className="select_plan_campaign fw-600">
                    Select a plan for the Campaign
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <FormControl size="small">
                    <RadioGroup
                      onChange={(e: any) => setRadioValue(+e.target.value)}
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={radiovalue}
                      name="radio-buttons-group"
                    >
                      <div className='d-flex flex-wrap'>
                        <FormControlLabel
                          value={1}
                          checked={radiovalue === 1}

                          control={<Radio />}
                          label="New Plan"
                        />
                        <span className='btn btn-text-link align-self-center fs-12' onClick={handlePlanNeed} style={{ cursor: 'pointer' }}>Why is a plan needed?</span>
                        {showPlanNeed && (
                          <p className='fs-14'>Brands can create plans for their diverse products/services, each aiming to achieve different objectives such as driving awareness, performance, or engagement.</p>
                        )}
                      </div>

                      <FormControlLabel
                        value={2}
                        disabled={planList.length < 1}
                        control={<Radio />}
                        defaultChecked={planList.length > 0 && radiovalue === 2}
                        checked={planList.length > 0 && radiovalue === 2}
                        label="Existing Plan"
                      />

                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {(planList.length > 0 && (radiovalue === 2)) &&
                <div className="row mb-3">
                  <div className="col">
                    <FormControl
                      // disabled={(radiovalue !== 2 && (planList.length < 1)) ? true : false}
                      fullWidth
                      size="small"
                      className="pln_popup_select input-common input-style-select"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createSelectedPlan}
                        label="Select Plan"
                        onChange={(e) => setCreateSelectedPlan(e.target.value)}
                        inputProps={{
                          MenuProps: {
                            disableScrollLock: true,
                          },
                        }}
                        MenuProps={MenuPropsSelect}
                      >
                        {planList.length > 0 ? (
                          planList.map((res: any, index: number) => {
                            return (
                              <MenuItem key={index} value={res.plan_id}>
                                {res.name}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem key={1} value={1}>
                            {"No Data"}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
              <div className="col text-end plan_camp_btn_group popup-footer">
                <Button
                  className="btn btn-outline-primary btn-mui"
                  onClick={() => onCloseSelectedPlan()}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary btn-mui ms-2"
                  onClick={handleContinue}
                  variant="contained"
                >
                  Continue
                </Button>
              </div>
            </div>
          </CommonPopUp>, document.body)
      )}
          </div>
        </div>
        <div className='row'>
          {influencerList.length !== 0 && (
            <div className='col-lg-3 list-section-sidebar'>
              {selectedInfluencer.length === 0 && (
                <>
                  <Button
                    variant='contained'
                    className='btn btn-outline-primary w-100 mb-3 ms-0'
                    onClick={handleOpenModal}
                  >
                    <Tooltip
                      className='me-md-1 d-md-none'
                      title='Create a New List'
                      arrow
                    >
                      <FormatListBulleted />
                    </Tooltip>
                    <span className='d-none d-md-flex'>Create a New List</span>
                  </Button>
                </>
              )}
              <ul>
                {influencerList.map((res: any, index: number) => {
                  return (
                    <>
                      <li
                        className={`${res.list_id === selectList?.list_id ? ' active ' : ''
                          }`}
                        onClick={() => {
                          setSelectList(res);
                        }}
                        key={index}
                      >
                        <span className={`list-section-sidebar-link ${res.list_id === selectList?.list_id ? ' active ' : ''
                          }`}>
                          <span className='list-section-icon'>
                            {res.list_type === 'Instagram' ? (
                              <InstagramIcon className='influencer_table_instagram_icon' />
                            ) : (
                              <YouTube
                                style={{
                                  color: 'red',
                                }}
                              />
                            )}
                          </span>
                          <span className='list-section-name'>
                            {res.list_name}
                          </span>
                          <span className='influencer_table_del_icon material-symbols-rounded' onClick={handleClickOpen}
                          >
                            delete
                          </span>
                        </span>
                      </li>
                    </>
                  );
                })}
                {/* {noList === '' &&
                  <Button
                    variant='contained'
                    className='btn btn-outline-primary w-100 mt-3'
                    onClick={addInfluencer}
                  >
                    <Tooltip
                      className='me-md-1 d-md-none'
                      title='Create a New List'
                      arrow
                    >
                      <PersonAddAlt1 />
                    </Tooltip>
                    <span className='d-none d-md-flex'>Add influencer</span>
                  </Button>
                } */}
                
              </ul>
              {/* // Dialog Box */}
              {selectList && selectList.list_id ? (
                <>
                  {createPortal(<ConfirmPopup confirmPopupInnerText={`Are you sure want to delete the list  ${selectList.list_name}?`} openPopup={open} confirmClosePopup={handleClose}
                    confirmClickAction={() => deleteInfluencerList(selectList.list_id)} drawerTitle="" />, document.body)}
                </>
              ) : null}
            </div>
          )}
          <div className= {`${influencerList.length === 0 ? 'col-lg-12 table-section' : 'col-lg-9 table-section'}`}>
            <div className='table-section-title-sec'>
              <div className='table-section-title'>
                {selectList && (
                  <>
                    {selectList.list_type === 'Instagram' ? (
                      <InstagramIcon className='icon icon-instagram' />
                    ) : (
                      <YouTube className='icon' />
                    )}
                    {!enableRename ? (
                      <HeadingStyle title={renameList} />
                    ) : (
                      <TextField
                        size='small'
                        onChange={handleRenameListName}
                        error={!renameInputValidation.status}
                        helperText={
                          !renameInputValidation.status ? 'Enter valid name' : ''
                        }
                        id='outlined-basic'
                        value={renameList}
                        label=''
                        placeholder='Input List Name'
                        variant='outlined'
                      />
                    )}
                    {!enableRename ? (
                      // <Edit
                      //   className='edit-icons'

                        // />
                        <span onClick={() => setEnableRename(true)} className='material-symbols-rounded edit-icons'>edit</span>
                      ) : (
                        <LoadingButton
                          loading={loading}
                          loadingPosition='start'
                          // startIcon={<SaveIcon />}
                          className='me-2 btn btn-outline-primary btn-mui'
                          variant='outlined'
                          onClick={editListName}
                        >
                          Update
                        </LoadingButton>
                      )}
                    </>
                  )}
                </div>
                <div className='table-section-title-right'>
                  {/* //always allow */}
                  {/* <Button variant='contained' className='btn btn-primary'>
                    <Tooltip className='me-md-1 d-md-none' title='Send Brief' arrow>
                      <Send />
                    </Tooltip>
                    <span className='d-none d-md-flex'>Send Brief</span>
                  </Button> */}
                
                {noList === '' &&
                  <Button
                    variant='contained'
                    className='btn btn-outline-primary ms-0'
                    onClick={addInfluencer}
                  >
                    <Tooltip
                      className='me-md-1 d-md-none'
                      title='Add Influencer'
                      arrow
                    >
                      <PersonAddAlt1 />
                    </Tooltip>
                    <span className='d-none d-md-flex'>Add influencer</span>
                  </Button>
                }

                {noList === '' &&
                  <Button
                    variant='contained'
                    className='btn btn-outline-primary ms-1'
                    onClick={clickSendBrief}
                  >
                    <Tooltip
                      className='me-md-1 d-md-none'
                      title='Send Brief'
                      arrow
                    >
                      <SendIcon/>
                    </Tooltip>
                    <span className='d-none d-md-flex'>Send Brief</span>
                  </Button>
                }

                {selectedInfluencer.length > 0 && (
                  <LoadingButton
                    className='btn btn-outline-primary'
                    loading={loading}
                    loadingPosition='start'
                    variant='contained'
                    onClick={() => deleteInfluencerInList()}
                  >
                    <Tooltip
                      className='me-md-1 d-md-none'
                      title='Delete Influencers'
                      arrow
                    >
                      <Delete />
                    </Tooltip>
                    <span className='d-none d-md-flex'>Delete Influencers</span>
                  </LoadingButton>
                )}
              </div>
            </div>
            {noList !== ''  && 
            <div className='no-list-comment'><p>{noList}<a className='link' onClick={handleOpenModal}>create a new list.</a></p></div>
            }
            {selectList && (
              <CommonTableComponent
                platformType={selectList.list_type.toLowerCase()}
                data={selectInfluencerList}
                loading={loadingData}
                currentPage={page - 1}
                handleCheckbox={handleCheckbox}
                total_records={selectInfluencerList && selectInfluencerList.length > 0 ? selectInfluencerList[0].total_records : 0}
                setCurrentPage={(pageNumber) => setPage(pageNumber + 1)}
                setRowPerPage={() => {}}
                handleProfileView={handleProfileView}
                getSubscriptionDetail={props.getSubscriptionDetail}
              />
            )}
          </div>
        </div>
        <div className='d-none'>
          {influencerList.length !== 0 &&
            influencerList.map((res: any, index: number) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id={selectList?.list_id}
                    className={`${res.list_id === selectList?.list_id ? ' active ' : ''
                      }`}
                    onClick={() => {
                      setSelectList(res);
                    }}
                    key={index}
                  >
                    <span className='list-section-icon'>
                      {res.list_type === 'Instagram' ? (
                        <InstagramIcon className='influencer_table_instagram_icon' />
                      ) : (
                        <YouTube
                          style={{
                            color: 'red',
                          }}
                        />
                      )}
                    </span>
                    <span className='list-section-name'>{res.list_name}</span>
                    <Delete
                      onClick={() => deleteInfluencerList(res.list_id)}
                      className='influencer_table_del_icon'
                    />
                  </AccordionSummary>
                  {selectList && (
                    <AccordionDetails>
                      {selectList.list_type === 'Instagram' ? (
                        <InstagramIcon className='icon icon-instagram' />
                      ) : (
                        <YouTube className='icon' />
                      )}
                      {!enableRename ? (
                        <HeadingStyle title={renameList} />
                      ) : (
                        <TextField
                          size='small'
                          onChange={(e) => setRenameList(e.target.value)}
                          id='outlined-basic'
                          value={renameList}
                          label=''
                          placeholder='Input List Name'
                          variant='outlined'
                        />
                      )}{' '}
                      {!enableRename ? (
                        <Edit
                          className='edit-icons'
                          onClick={() => setEnableRename(true)}
                        />
                      ) : (
                        <LoadingButton
                          loading={loading}
                          loadingPosition='start'
                          // startIcon={<SaveIcon />}
                          className='me-2 btn btn-outline-primary'
                          variant='outlined'
                          onClick={editListName}
                        >
                          Update
                        </LoadingButton>
                      )}
                      {/* <InfluencerTable
                                            getTableSelectInfluencer={(e) =>
                                                setSelectedTableRow(e)
                                            }
                                            influencerData={
                                                selectInfluencerList
                                            }
                                            // selectedList={selectedList}
                                        /> */}
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    getSubscriptionDetail: state.Subscription.subscription_detail,
    campaignReducer: state.CreateCampaignReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerList);

export { connectToRedux as InfluencerList };
