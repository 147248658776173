import Box from "@mui/material/Box";
import { Tab, TableCell, TableRow, tabsClasses } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./crud.scss";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Brand } from "../../../../signup/brand/Brand";
import { Agency } from "../../../../signup/agency/Agency";
import useBodyClass from "../../../../../hooks/useBodyClass";
import { connect } from 'react-redux';
import { LocalService } from "../../../../../core/services";
import { RolesService } from "../../../../../core/services/roles.service";

import { useNavigate } from 'react-router-dom';
import HeadingStyle from "../../../../../components/heading-style/heading-style";
import { AdminDashboardService } from "../../../../../core/services/admin-dashboard.service";
import { SortingColumnsType, TableComponent } from "../../../../../components/table-component/table";

const rolesService = new RolesService();
const localService: LocalService = new LocalService();
const adminDashboardService = new AdminDashboardService();


interface Props {
  UserReducer: any;
}
const Crud: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState("1");
  const [brandAgency, setBrandAgency] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [inputValue, setInputValue] = useState('');
  const [searchList, setSearchList] = useState<any>([]);
  const [renderData, setRenderData] = useState<any>([]);
  const [accountType, setAccountType] = useState('')

  const navigate = useNavigate();

  const onClickFunction = (value: string) => {
    setBrandAgency(value);
  };
  useBodyClass('crud-page');
  useEffect(() => {
    // CHECK IF USER ALLOWED  ACCESS 
    checkAccessFunction();
  }, []);

  const checkAccessFunction = async () => {
    try {
      const res = await rolesService.checkAccess({ access_roles: ["view_admin_dashboard", "create_user", "delete_user"], user_role_id: props?.UserReducer?.user_role_id })
      if (res.data.rows.length > 0) {
        // Function call if required
      }
      else {
        localService.toastify("You are not allowed to access", "error", 1000);
        navigate(`/brand/dashboard`)
      }
    }
    catch (error) {
      console.error('Error fetching getAdminDashData:', error);

    }
  }

  const columnDisplayName = {
    full_name: "Name",
    login_mobile: "Mobile",
    login_email: "Email",
    acc_type: "Account Type",
    username: "Instagram Handle Name",
    title: "Youtube Channel Name",
    action: "Action"
  };

  const sortingColumnType: SortingColumnsType[] = [
    {
      name: "full_name",
      columnSize: "200px",
    },
    {
      name: "login_mobile",
      columnSize: "140px",
    },
    {
      name: "login_email",
      columnSize: "200px",
    },
    {
      name: "acc_type",
      columnSize: "100px",
    },
    {
      name: "username",
      columnSize: "200px",
    },
    {
      name: "title",
      columnSize: "200px",
    },
    {
      name: "action",
      columnSize: "100px",
    },

  ];

  //Search the user function
  const handleSearchUser = async (loginEmailMobile: string) => {
    try {
      const response = await adminDashboardService.getUserDetail(loginEmailMobile);
      if (response.data.status) {
        setSearchList(response.data.rows);
      }
    } catch (error) {
      console.log(`handleSearchUser error :: ${JSON.stringify(error)}`);
    }
  };

  //Delete the user function
  const deleteUser = async (loginEmailMobile: string) => {
    let response;
    try {
      if (accountType === 'Influencer') {
        response = await adminDashboardService.deleteInfluencer({ login: loginEmailMobile });
        if (response.data.status) {
          localService.toastify(response.data.message)
        } else {
          localService.toastify(response.data.message)

        }
      }
      if (accountType === 'Brand') {
        response = await adminDashboardService.deleteBrand({ login: loginEmailMobile });
        if (response.data.status) {
          localService.toastify(response.data.message)
        } else {
          localService.toastify(response.data.message)
        }
      }
      if (accountType === 'Agency') {
        response = await adminDashboardService.deleteAgency({ login: loginEmailMobile });
        if (response.data.status) {
          localService.toastify(response.data.message)
        } else {
          localService.toastify(response.data.message)

        }
      }
      if (accountType === 'Talent Partner') {
        response = await adminDashboardService.deleteTalentPartner({ login: loginEmailMobile });
        if (response.data.status) {
          localService.toastify("Agency Deleted Successfully", 'success')
        } else {
          localService.toastify("Agency Not Deleted", 'error')

        }
      }
    } catch (error) {
      console.log(`deleteUser error :: ${JSON.stringify(error)}`);
    }
  }



  return (
    <div className="adminDashboardCrudMostOutterDiv">
      <div className="adminDashboardCrudContentOutterDiv px-0">
        <div className="adminDashboardCrudContentDiv">
          <HeadingStyle
            title='Admin Dashboard'
            className='d-block'
          />
          <div className="adminDashboardCrudToggleButtonsDiv">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <TabList variant="scrollable"
                  onChange={handleChange}
                  scrollButtons
                  allowScrollButtonsMobile
                  className="nav sub-tabs"
                  TabIndicatorProps={{ hidden: true }}
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.MuiTabScrollButton-root': {
                        borderRadius: '20px',
                        height: '24px',
                        border: '1px solid #000',
                        width: '24px',
                        transition: 'box-shadow 0.3s, transform cubic-bezier(0.2,0,0,1)',
                        position: 'relative',
                        zIndex: 1,
                        '&:hover': {
                          boxShadow: '0 0 5px #ddd',
                          transform: 'scale(1.04)'
                        },
                      },
                    },
                  }}
                >
                  <Tab
                    label="Create User"
                    value="1"
                    className={value === "1" ? 'nav-link active' : "nav-link"}
                    onClick={() => {
                      setBrandAgency("");
                    }}
                  />

                  {(() => {
                    if (props.UserReducer?.delete_user == "True") {
                      return (
                        <Tab
                          label="Delete User"
                          value="2"
                          className={value === "2" ? 'nav-link active' : "nav-link"}
                          style={{ textTransform: "none", fontSize: "16px" }}
                        />
                      )
                    }
                  })()}
                </TabList>

                <TabPanel value="1" style={{ padding: "0" }}>
                  <div className="createUserDiv">
                    {brandAgency.length === 0 ? (
                      <span className="buttonsSpan">
                        <Button
                          className="btn btn-outline-primary btn-mui me-2 "
                          onClick={() => {
                            onClickFunction("brand");
                          }}
                        >
                          Brand
                        </Button>
                        <Button
                          className="btn btn-outline-primary btn-mui"
                          onClick={() => {
                            onClickFunction("agency");
                          }}
                        >
                          Agency
                        </Button>
                      </span>
                    ) : (
                      <div style={{ marginTop: "300px" }}>
                        {brandAgency === "brand" ? <Brand /> : <Agency />}
                      </div>
                    )}
                  </div>
                </TabPanel>
                {/* 
                {(() => {
                  if (props.UserReducer?.create_user == "True") {
                    return (
                      <Tab
                        className='tab-underline-menu'
                        label='Crud'
                        value='crud'
                      />)
                  }
                })()} */}
                <TabPanel value="2" style={{ padding: "24px 0" }}>
                  <HeadingStyle
                    title='Delete User'
                    className='py-3 d-block'
                  />
                  <TextField
                    id="outlined-basic"
                    label="Enter Email Id / Phone Number"
                    variant="outlined"
                    size="small"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Button
                    variant='contained'
                    className='btn btn-primary ms-3'
                    onClick={() => handleSearchUser(inputValue)}
                  >
                    Search
                  </Button>
                </TabPanel>


              </TabContext>
              <div className="row col-12 mt-4">
                <TableComponent
                  pagination={false}
                  columnDisplayName={columnDisplayName}
                  sortingColumnType={sortingColumnType}
                  inputDataSource={searchList}
                  orderByColumnName=""
                  renderData={renderData}
                  setRenderData={setRenderData}
                >
                  {renderData.map((data: any, index: number) => {
                    return (
                      <TableRow key={index} >
                        <TableCell>{data.full_name}</TableCell>
                        <TableCell>{data.login_mobile}</TableCell>
                        <TableCell>{data.login_email}</TableCell>
                        <TableCell>{data.acc_type}</TableCell>
                        <TableCell>{data.username}</TableCell>
                        <TableCell>{data.title}</TableCell>
                        {/* <TableCell><Button onClick={() => [data.account_id, data.acc_type]}>Delete</Button></TableCell> */}
                        <TableCell>
                          <Button
                            variant='contained'
                            className='btn btn-primary'
                            onClick={() => {
                              deleteUser(data.login_mobile);
                              setAccountType(data.acc_type);
                            }}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableComponent>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const connectToRedux = connect(
  mapStateToProps
)(Crud);

export { connectToRedux as Crud };
